import { Component, Input, OnInit } from '@angular/core';
import { createNumberMask } from 'text-mask-addons';

@Component({
  selector: 'dollink-update-price-modal',
  template: `
    <ng-template #updatePriceModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Actualizar precio de servicio
        </h4>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        ></button>
      </div>
      <div class="modal-body">
        <p>Se actualizará el precio del servicio de {{ this.assignedPrice }}</p>
        <div class="input-group mb-3">
          <span class="input-group-text">$</span>
          <input
            type="text"
            class="form-control"
            id="updateServicePriceInput"
            placeholder="Ingresa el costo del viaje"
            aria-label="Service price amount"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-outline"
          (click)="modal.close('add')"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="onUpdateServicePrice()"
        >
          Actualizar precio
        </button>
      </div>
    </ng-template>
  `,
  styleUrls: ['./update-price-modal.component.scss'],
})

export class UpdatePriceModalComponent implements OnInit {
  @Input() public assignedPrice: string | undefined;
  public numberMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    decimalSymbol: '.',
    thousandsSeparatorSymbol: ',',
  });
  constructor() {}

  ngOnInit(): void {}

  public onUpdateServicePrice(): void {}
}
