import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { NewPasswordComponent } from "./pages/new-password/new-password.component";
import { RecoverPasswordComponent } from "./pages/recover-password/recover-password.component";
import { RegisterComponent } from "./pages/register/register.component";

const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full'},
  { path: 'sign-in', component: LoginComponent },
  { path: 'sign-in/:token', component: LoginComponent },
  { path: 'signup', component: RegisterComponent },
  { path: 'recover-password', component: RecoverPasswordComponent},
  { path: 'new-password/:name/:token', component: NewPasswordComponent }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuthRoutingModule {}