import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';
import { IMapAddress } from 'src/app/core/interfaces/map.interface';
import { IProjectDropoff, IProjectPickup } from 'src/app/core/interfaces/projects.interface';
import { IStates } from 'src/app/core/interfaces/states.interface';
import { CountriesService } from 'src/app/core/services/countries/countries.service';
import { ProjectsService } from 'src/app/core/services/requestor/projects/projects.service';
import { TIME_MASK } from 'src/app/shared/masks/masks';
import * as dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { RequestsService } from 'src/app/core/services/trucking/requests/requests.service';
import { RequestTypes } from 'src/app/core/enums/request.enum';

@Component({
  selector: 'dollink-request-dropoff',
  templateUrl: './request-dropoff.component.html',
  styleUrl: './request-dropoff.component.scss'
})
export class RequestDropoffComponent {

  @Input() projectId: string = "";
  @Input() pickupLocation!: IProjectPickup;
  @Input() dropoffLocation: IProjectDropoff | undefined = undefined;
  @Input() type: RequestTypes | undefined = undefined;

  public dropoffLocationForm!: FormGroup;
  public dueDateLimitYearOrigin = dayjs().year();
  public dueDateLimitMonthOrigin = dayjs().month() + 1;
  public dueDateLimitDayOrigin = dayjs().get('date');
  public states: IStates[] = [];
  public submitted = false;
  public timeMask = TIME_MASK;
  public isSaving = false;
  public updateButtonText = "Guardar";
  public showAlertWarningPickupDate = false;

  constructor(
    private fb: FormBuilder,
    private statesService: CountriesService,
    private projectService: ProjectsService,
    private modal: NgbActiveModal,
    private requestTruckingService: RequestsService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getAllStates();

    if(this.dropoffLocation) {
      this.enableForm();
      this.dropoffLocationForm.patchValue(this.dropoffLocation);
      const dropoffDate = dayjs(this.dropoffLocation.date);
      this.dropoffLocationForm.controls['date'].setValue({year: dropoffDate.get('year'), month: dropoffDate.get('month') + 1, day: dropoffDate.get('date')});
    }
    const pickupDate = dayjs(this.pickupLocation.date);
    this.dueDateLimitDayOrigin = pickupDate.get('date');
  }

  public getAddress(address: IMapAddress): void {
    if(address) {
      this.dropoffLocationForm.patchValue(address);
      this.enableForm()
    }
  }

  public onClose(): void {
    this.modal.close();
  }

  public onValidDates(): void {
    if(this.pickupLocation.date <= this.dropoffLocationForm.value.date) {
      this.showAlertWarningPickupDate = true;
    }
  }

  public onSave(): void {
    this.submitted = true;
    if(this.dropoffLocationForm.valid) {
      
      this.isSaving = true;
      this.updateButtonText = "Guardando";
      const date = this.dropoffLocationForm.controls['date'].value;
      const projectDropffDate = dayjs(`${date.year}-${date.month}-${date.day}`).format('YYYY-MM-DD');
      
      const dropoffLocationData = {
        ...this.dropoffLocationForm.value,
        date: projectDropffDate
      }

      if(this.type === RequestTypes.PROJECT) {
        this.createProjectRequest(dropoffLocationData);
      } 

      if(this.type === RequestTypes.SELF_MANAGED) {
        this.createSelfManagedDropoffService(dropoffLocationData)
      } 

    } else {
      this.dropoffLocationForm.markAllAsTouched();
    }
  }

  private createProjectRequest(dropoff: any): void {
    this.projectService.setDropoffLocationProject(this.projectId, dropoff)
      .pipe(
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if(response) {
          this.modal.close(response);
        }
      })
  }

  private createSelfManagedDropoffService(dropoff: any): void {
    this.requestTruckingService.updateServiceRequestDropoff(this.projectId, dropoff)
      .pipe(
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if(response) {
          this.modal.close(response);
        }
      })
  }

  private enableForm(): void {
    this.dropoffLocationForm.get('address')?.enable();
    this.dropoffLocationForm.get('suiteNumber')?.enable();
    this.dropoffLocationForm.get('streetNumber')?.enable();
    this.dropoffLocationForm.get('zipcode')?.enable();
    this.dropoffLocationForm.get('state')?.enable();
    this.dropoffLocationForm.get('city')?.enable();
    this.dropoffLocationForm.get('country')?.enable();
    this.dropoffLocationForm.get('time')?.enable();
    this.dropoffLocationForm.get('date')?.enable();
  }

  private createForm(): void { 
    this.dropoffLocationForm = this.fb.group({
      place: ['', Validators.required],
      address: [{value: '', disabled: true}, Validators.required],
      streetNumber: [{value: '', disabled: true}, Validators.required],
      suiteNumber: [{value: '', disabled: true}],
      zipcode: [{value: '', disabled: true}, Validators.required],
      state: [{value: '', disabled: true}, Validators.required],
      city: [{value: '', disabled: true}, Validators.required],
      country: [{value: '', disabled: true}, Validators.required],
      date: [{value: '', disabled: true}, Validators.required],
      time: [{value: '', disabled: true}],
      latitude: [null, Validators.required],
      longitude: [null, Validators.required],
      isMain: [true, Validators.required],
    })
  }

  private getAllStates(): void{
    this.statesService.getStates()
      .pipe(
        catchError((error) => {
          Swal.fire({
            title: error.title,
            text: error.description,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor:  "#509760",
          });
          return of(undefined);
        })
      )
      .subscribe((states) => {
        if(states) {
          this.states = states;
        }
      })
  }
}
