import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './features/auth/auth.module';
import { AuthService } from './core/services/auth/auth.service';
import { StorageService } from './core/services/storage/storage.service';
import { RequestTokenInterceptor } from './core/interceptors/request-token.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebSocketShareService } from './core/services/socket/webSocketShareService';
import { SharedModule } from './shared/shared.module';
import { RefreshTokenInterceptor } from './core/interceptors/refresh-token.interceptor';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule,
    NgbModule, 
    SharedModule,
  ],
  providers: [
    AuthService,
    StorageService,
    WebSocketShareService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestTokenInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true},
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd/MM/yyyy'}},
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN'},
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
