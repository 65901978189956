import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IAvailableCargoUnitsByService, ICargoUnit, ICargoUnitByRequest, IMainUnitPlateValidation } from 'src/app/core/interfaces/cargo-unit.interface';
import { environment as env } from 'src/environments/environment';
import { IPaginationResponse } from '../../interfaces/pagination.interface';
@Injectable({
  providedIn: 'root'
})
export class TrucksService {

  private readonly api: string = env.api;
  private endpoints = {
    findAvailableCargoUnits: '/trucking/request/{uuid}/cargo-unit/available',
    validatePlate: '/cargo-unit/validate',
  }

  constructor(
    private http: HttpClient,
  ) { }

  getAll(page: number, size: number): Observable<Array<ICargoUnit>> {
    return this.http.get<Array<ICargoUnit>>(`${this.api}/cargo-unit?page=${page}&size=${size}`);
  }

  getById(id: string): Observable<ICargoUnit> {
    return this.http.get<ICargoUnit>(`${this.api}/cargo-unit/${id}`);
  }

  getAvailableUnitsByRequest(id: string, types: string[] = []): Observable<IAvailableCargoUnitsByService[]> {
    let endpoint = this.endpoints.findAvailableCargoUnits.replace('{uuid}', id);
    if(types.length > 0 ) endpoint = `${endpoint}?types=${types.join(',')}` 
    return this.http.get<IAvailableCargoUnitsByService[]>(`${this.api}${endpoint}`);
  }

  validatePlateNumber(mainUnitPlate: string): Observable<IMainUnitPlateValidation> {
    return this.http.get<IMainUnitPlateValidation>(`${this.api}${this.endpoints.validatePlate}/${mainUnitPlate}`);
  }

  searchByPlateNumber(mainUnitPlate: string): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}/cargo-unit?mainUnitPlate=${mainUnitPlate}`);
  }
 
  deleteImage(cargoUnitId: string, image: any): Observable<any> {
    const data = {image: image.name};
    return this.http.patch<any>(`${this.api}/cargo-unit/${cargoUnitId}/image`, data);
  }

  create(truckData: FormData): Observable<ICargoUnit> {
    return this.http.post<ICargoUnit>(`${this.api}/cargo-unit`, truckData)
  }

  update(truckData: FormData, cargoUnitId: string): Observable<HttpClient> {
    return this.http.put<HttpClient>(`${this.api}/cargo-unit/${cargoUnitId}`, truckData);
  }

  delete(cargoUnitId: string): Observable<HttpClient>  {
    return this.http.delete<HttpClient>(`${this.api}/cargo-unit/${cargoUnitId}`);
  }
}
