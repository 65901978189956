import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tabs } from './tabs.interface';

@Component({
  selector: 'dollink-ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  public currentTab: string = "";

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute
  ) { }

  @Input() config!: Tabs;

  ngOnInit(): void {
    this.currentTab = './' + this.route.url.split('/').slice(-1).toString();
  }

  public linkTo(link: string): void {
    this.route.navigate([link], {relativeTo: this.activeRoute});
  }

  public setActiveTab(tab:any, index: number):void{
    const tabs = document.querySelectorAll('li a');
    tabs.forEach((item)=> {
      item.classList.remove('active');
    })
    const tabElement = document.querySelector(`#tab-${index} a`);
    tabElement?.classList.add('active');
  }
}
