import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PackageTypes } from '../../interfaces/package-type.interface';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackageTypeService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<Array<PackageTypes>> {
    return this.http.get<Array<PackageTypes>>(`${env.api}/package-type`);
  }
}
