import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';
import { ProjectsService } from 'src/app/core/services/requestor/projects/projects.service';

@Component({
  selector: 'dollink-request-type-modal',
  templateUrl: './request-type-modal.component.html',
  styleUrls: ['./request-type-modal.component.scss']
})

export class RequestTypeModalComponent implements OnInit {

  @Input() projectId = "";
  public submitted = false;

  constructor(
    private router: Router,
    private projectService: ProjectsService,
    private modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {}

  public onCreateProject(requestType: boolean): void {
    this.submitted = true;   
    this.projectService.completeProject(this.projectId, requestType)
      .pipe(
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if(response) {
          setTimeout(() => {
            this.modal.close();
            this.router.navigate(['/requestor/projects/negotiation', this.projectId]);
          }, 2000);
        }
      })
  }

}
