
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {States} from "src/app/core/catalogues/states"
import { IStates } from '../../interfaces/states.interface';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
	constructor( ) { }

	getStates(): Observable<IStates[]>{
		return of(States);
	}


}