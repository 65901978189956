import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[dollinkInvalidFormScroll]'
})
export class InvalidFormScrollDirective {

  constructor(
    private el: ElementRef,
    private formGroupDir: FormGroupDirective
  ) {}

  @HostListener("ngSubmit") onSubmit() {
    if (this.formGroupDir.control.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      ".ng-invalid"
    );
    firstInvalidControl.scrollIntoView({behavior: 'smooth', block: 'center'})
    firstInvalidControl.focus();
  }

}
