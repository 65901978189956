<section class="map">
  <div class="route-details mb-2">
    <div class="route-details-pickup">
      <div class="route-icon pickup">
        <img src="/assets/svg/pickup-location-icon.svg" alt="">
      </div>
      <div class="route-info">
        <p class="route-title">Punto de recolección</p>
        <p class="route-address">
          <span *ngIf="configuration.pickup.place">{{configuration.pickup.place}}</span>
          {{configuration.pickup.address}} 
          {{configuration.pickup.city}},
          {{configuration.pickup.zipcode}},
          {{configuration.pickup.state}}
        </p>
        <p class="text-muted route-date">
          <span class="material-symbols-outlined">calendar_month</span>
          {{configuration.pickup.date | date:'dd/MM/yyyy'}} 
          a las {{configuration.pickup.time}}hrs
        </p>
      </div>
    </div>

    <div class="route-details-dropoff">
      <div class="route-icon dropoff">
        <img src="/assets/svg/dropoff-location-icon.svg" alt="">
      </div>
      <div class="route-info">
        <p class="route-title">Punto de entrega</p>
        <p class="route-address">
          <span *ngIf="configuration.dropoff.place">{{configuration.dropoff.place}}</span>
          {{configuration.dropoff.address}} 
          {{configuration.dropoff.city}},
          {{configuration.dropoff.zipcode}},
          {{configuration.dropoff.state}}
        </p>
        <p class="text-muted route-date">
          <span class="material-symbols-outlined">calendar_month</span> 
          {{configuration.dropoff.date | date:'dd/MM/yyyy'}} 
          a las {{configuration.dropoff.time}}hrs
        </p>
      </div>
    </div>
  </div>
  <div class="route-map">
    <div id="map" class="map" [style.height]="mapHeight + 'px'"></div>
  </div>
</section>