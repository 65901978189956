import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment as env} from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { BehaviorSubject } from 'rxjs';

import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
interface INotifications {
  id: number;
  title: string;
  message: string;
}

interface IMessage {
  companySenderId: number | null;
  companySenderName: string | null;
  message: string | null;
  messageId: number | null;
  requestId: number | null;
  requestUUID: string;
  userSenderName: string | null;
}
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public notificationsSocket: any;
  public messagesSocket: any;  
  public isMessageViewed = new BehaviorSubject<boolean>(false);
  
  messageViewedStatus = this.isMessageViewed.asObservable();
  private readonly server: string = env.socket;
  
  constructor(
    private authService: AuthService,
    private storageService: StorageService,
  ) { }

  /**
   * Connect Notifications and Messages Channels 
  **/
  public connect(): void {
    //this.notificationsSocket.debug = null;
    this.messagesChannel();
    this.notificationsChannel();
  }

  public connectChannelChat(requestId: string): void {
    this.newMessagesChannel(requestId);
  }

  /**
   * Disconnect Notifications and Messages Channels 
  **/
  public disconnect(): void {
    if(this.notificationsSocket !== undefined) {
      this.notificationsSocket.disconnect();
    }
    if(this.messagesSocket !== undefined) {
      this.messagesSocket.disconnect();
    }
  }

  /**
   * Send Message
   * @param data {requestorId:number, truckingId: number, requestId: number, message: string}
  **/
  public send(data: {requestorId:number, truckingId: number, requestId: number, message: string}): void {
    const headers = this.getAuthorizationHeader();
    this.messagesSocket.send('/dollink/chat', headers, JSON.stringify({
      "companyRequestorId" : data.requestorId,
      "companyTruckingId" : data.truckingId,
      "requestId": data.requestId,
      "content" : data.message
    }))
  }


  /**
   * Messages Channel Connection 
  **/
  private messages = new BehaviorSubject<IMessage>({companySenderId: null, companySenderName: null, message: null, messageId: null, requestId: null, userSenderName: null, requestUUID: ''});
  currentMessage = this.messages.asObservable();

  private messagesChannel(): void {
    const ws = new SockJS(this.server);
    const companyId: number = this.storageService.get('dollink_company_information').uuid;
    const headers = this.getAuthorizationHeader()
    this.messagesSocket = Stomp.over(ws);
    this.messagesSocket.connect(headers, () => {
      this.messagesSocket.subscribe(`/queue/messages/${companyId}`, (message:any) => {
        this.messages.next(JSON.parse(message.body));
      })
    });
  }

  /**
   * 
   * 
   */

  private newMessage = new BehaviorSubject<IMessage>({companySenderId: null, companySenderName: null, message: null, messageId: null, requestId: null, userSenderName: null, requestUUID: ''});
  newSentMessage = this.newMessage.asObservable();

  private newMessagesChannel(requestUUID: string): void {
    const ws = new SockJS(this.server);
    const companyId: number = this.storageService.get('dollink_company_information').uuid;
    const headers = this.getAuthorizationHeader()
    this.messagesSocket = Stomp.over(ws);
    this.messagesSocket.connect(headers, () => {
      this.messagesSocket.subscribe(`/queue/messages/${companyId}/request/${requestUUID}`, (message:any) => {
        this.newMessage.next(JSON.parse(message.body));
      })
    });
  }

  /**
   * Notifications Channel
  **/
  private notifications = new BehaviorSubject<INotifications>({id: 0, title: '', message: ''});
  currentNotification = this.notifications.asObservable();

  private notificationsChannel(): void {
    const ws = new SockJS(this.server);
    let companyId: number = this.storageService.get('dollink_user_company_id');
    this.notificationsSocket = Stomp.over(ws);
    const headers = this.getAuthorizationHeader()
    this.notificationsSocket.connect(headers, () => {
      this.notificationsSocket.subscribe(`/company/${companyId}/notifications`, (sdkEvent: any) => {
        this.notifications.next(sdkEvent);
      })
    })
  }

  /**
   * Authorization Headers
   * @returns 
  **/
  private getAuthorizationHeader(): Object {
    const token = this.authService.getToken();
    return {
      "Authorization" : token,
      "Access-Control-Allow-Credentials": "*",
      "withCredentials": true,
    }
  }
}
