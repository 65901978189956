import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IPageContent {
  class: string;
}

@Injectable({
  providedIn: 'root'
})
export class PageContentService {

  private pageContentClass = new BehaviorSubject<IPageContent | undefined>(undefined);
  currentPageContentClass = this.pageContentClass.asObservable();

  constructor() { }

  public updatePageContentclass(pageContentClass: IPageContent) {
    this.pageContentClass.next(pageContentClass);
  }
}
