<header class="dollink-header {{class}}">
  <div class="container-fluid container-fluid d-flex align-items-center">
    <div class="breadcrumbs">
      <dollink-breadcrumbs></dollink-breadcrumbs>
    </div>
    <div class="chat-notifications me-4">
      <dollink-chat-notifications></dollink-chat-notifications>
    </div>
    <div class="notifications me-3">
      <dollink-notifications></dollink-notifications>
    </div>
    <div class="profile">
      <dollink-profile-badge></dollink-profile-badge>
    </div>
  </div>
</header>