import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';
import { CARGO_UNIT_TYPES } from 'src/app/core/enums/truck.types.enums';
import { IAvailableCargoUnitsByService } from 'src/app/core/interfaces/cargo-unit.interface';
import { TrucksService } from 'src/app/core/services/trucks/trucks.service';

@Component({
  selector: 'dollink-multiple-unit-assignation-modal',
  templateUrl: './multiple-unit-assignation-modal.component.html',
  styleUrl: './multiple-unit-assignation-modal.component.scss'
})

export class MultipleUnitAssignationModalComponent implements OnInit {

  @Input() currentCargoUnit: IAvailableCargoUnitsByService | undefined;
  @Input() serviceId = "";


  public trucks: IAvailableCargoUnitsByService[] = [];
  public cargoUnitTypes = CARGO_UNIT_TYPES;
  public truckInfo: IAvailableCargoUnitsByService | undefined;
  public submitted = false;
  public isSecondCargoUnitSelected = false;
  
  constructor(
    private modal: NgbActiveModal,
    private unitsService: TrucksService,
  ) { }

  ngOnInit(): void {
    this.getCargoUnits();
  }


  public onCloseModal(): void {
    this.modal.close();
  }

  public onSelectSecondUnit(e: any): void {
    const unitId = Number(e.target.value);
    this.truckInfo = this.trucks.find((item) => item.cargoUnit.id === unitId);
    if(this.truckInfo) {
      this.isSecondCargoUnitSelected = true;
    } else {
      this.isSecondCargoUnitSelected = false;
    }
  }

  public onAssignUnits(e: Event): void {
    this.submitted = true;
    if(this.truckInfo && this.currentCargoUnit) {
      this.modal.close({cargoUnit1: this.currentCargoUnit, cargoUnit2: this.truckInfo});
    } else {
      this.isSecondCargoUnitSelected = false;
    }
  }

  private getCargoUnits(): void {
    const currentType = this.currentCargoUnit?.cargoUnit.trailerType.type;
    let cargoUnitType = currentType === CARGO_UNIT_TYPES.TRAILER ? CARGO_UNIT_TYPES.TRUCK : CARGO_UNIT_TYPES.TRAILER;
    this.unitsService.getAvailableUnitsByRequest(this.serviceId, [cargoUnitType])
      .pipe(
        catchError((error: any) => {
          return of(undefined)
        })
      )
      .subscribe((trucks) => {
        if(trucks) {
          this.trucks = trucks;
        }
      })
  }
}
