import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { catchError, of } from 'rxjs';
import { IMapAddress } from 'src/app/core/interfaces/map.interface';
import { IProjectDropoff, IProjectPickup } from 'src/app/core/interfaces/projects.interface';
import { IStates } from 'src/app/core/interfaces/states.interface';
import { CountriesService } from 'src/app/core/services/countries/countries.service';
import { TruckingRequestsService } from 'src/app/core/services/ui/trucking-requests/trucking-requests.service';
import { TIME_MASK } from 'src/app/shared/masks/masks';
import Swal from 'sweetalert2';

interface IRequestDatesErrors {
  message: string;
  type: string;
}

@Component({
  selector: 'dollink-request-route',
  templateUrl: './route.component.html',
  styleUrl: './route.component.scss'
})

export class RequestRouteComponent implements OnInit {

  @Output() route = new EventEmitter<{pickup: IProjectPickup, dropoff: IProjectDropoff}>();

  public timeMask = TIME_MASK;
  public dueDateLimitYearOrigin = dayjs().year();
  public dueDateLimitMonthOrigin = dayjs().month() + 1;
  public dueDateLimitDayOrigin = dayjs().get('date');
  public dueDateLimitYearOriginDropoff = 0;
  public dueDateLimitMonthOriginDropoff = 0;
  public dueDateLimitDayOriginDropoff = 0;
  public states: IStates[] = [];
  public errors: IRequestDatesErrors[] = []
  
  // pickup form
  public pickupLocationForm!: FormGroup;
  public submittedPickupForm = false;

  // dropoff form
  public dropoffLocationForm!: FormGroup;
  public submittedDropoffForm = false;

  constructor(
    private fb: FormBuilder,
    private statesService: CountriesService,
    private truckingRequestService: TruckingRequestsService,
  ) {}

  ngOnInit(): void {
    this.createDropoffForm();
    this.createPickupForm();
    this.getStates();

    // set pickup if is already filled
    this.truckingRequestService.getPickup().subscribe((value) => {
      if(value) {
        this.pickupLocationForm.patchValue(value, { emitEvent: false });
        this.enablePickupForm();
      }
    })

    // set dropoff if is already filled
    this.truckingRequestService.getDropoff().subscribe((value) => {
      if(value) {
        this.dropoffLocationForm.patchValue(value, { emitEvent: false });
        this.enableDropoffForm();
      }
    })
  }

  public getPickupAddress(pickup: IMapAddress): void {
    if(pickup) {
      this.pickupLocationForm.patchValue(pickup, { emitEvent: false });
      this.submittedPickupForm = true;
      this.enablePickupForm();
    }
  }

  public getDropoffAddress(dropoff: IMapAddress): void {
    if(dropoff) {
      this.dropoffLocationForm.patchValue(dropoff, { emitEvent: false });
      this.submittedDropoffForm = true;
      this.enableDropoffForm();
    }
  }

  public onRedirectToCargos(): void {
    this.submittedDropoffForm = true;
    this.submittedPickupForm = true;
    if(this.pickupLocationForm.valid && this.dropoffLocationForm.valid) {
      this.route.emit({pickup: this.pickupLocationForm.value, dropoff: this.dropoffLocationForm.value});
    } else {
      this.pickupLocationForm.markAllAsTouched();
      this.dropoffLocationForm.markAllAsTouched();
    }  
  }

  private enablePickupForm(): void {
    this.pickupLocationForm.get('address')?.enable();
    this.pickupLocationForm.get('suiteNumber')?.enable();
    this.pickupLocationForm.get('streetNumber')?.enable();
    this.pickupLocationForm.get('zipcode')?.enable();
    this.pickupLocationForm.get('state')?.enable();
    this.pickupLocationForm.get('city')?.enable();
    this.pickupLocationForm.get('country')?.enable();
    this.pickupLocationForm.get('time')?.enable();
    this.pickupLocationForm.get('date')?.enable();
  }

  private enableDropoffForm(): void {
    this.dropoffLocationForm.get('address')?.enable();
    this.dropoffLocationForm.get('suiteNumber')?.enable();
    this.dropoffLocationForm.get('streetNumber')?.enable();
    this.dropoffLocationForm.get('zipcode')?.enable();
    this.dropoffLocationForm.get('state')?.enable();
    this.dropoffLocationForm.get('city')?.enable();
    this.dropoffLocationForm.get('country')?.enable();
    this.dropoffLocationForm.get('time')?.enable();
    this.dropoffLocationForm.get('date')?.enable();
  }

  private createPickupForm(): void {
    this.pickupLocationForm = this.fb.group({
      place: ['', Validators.required],
      address: [{value: '', disabled: true}, Validators.required],
      streetNumber: [{value: '', disabled: true}, Validators.required],
      suiteNumber: [{value: '', disabled: true}],
      zipcode: [{value: '', disabled: true}, Validators.required],
      state: [{value: '', disabled: true}, Validators.required],
      city: [{value: '', disabled: true}, Validators.required],
      country: [{value: 'México', disabled: true}, Validators.required],
      date: [{value: '', disabled: true}, Validators.required],
      time: [{value: '', disabled: true}],
      latitude: [null, Validators.required],
      longitude: [null, Validators.required],
      isMain: [true, Validators.required],
    });

    // Update address input
    this.pickupLocationForm.controls['address'].valueChanges.subscribe((value: string) => {
      // if(value !== '') this.emitRoute();
    })
    
    // Update streetNumber input
    this.pickupLocationForm.controls['streetNumber'].valueChanges.subscribe((value: string) => {
      // if(value !== '') this.emitRoute();

    })

    // Update suiteNumber input
    this.pickupLocationForm.controls['suiteNumber'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();
    })
    
    // Update zipcode input
    this.pickupLocationForm.controls['zipcode'].valueChanges.subscribe((value: string) => {
      // if(value !== '') this.emitRoute();
    })

    // Update state input
    this.pickupLocationForm.controls['state'].valueChanges.subscribe((value: string) => {
      // if(value !== '') this.emitRoute();
    })

    // Update city input
    this.pickupLocationForm.controls['city'].valueChanges.subscribe((value: string) => {
      // if(value !== '') this.emitRoute();
    })

    // Update date input
    this.pickupLocationForm.controls['date'].valueChanges.subscribe((value: NgbDateStruct) => {
      if(value) {
        this.dueDateLimitYearOriginDropoff = value.year;
        this.dueDateLimitMonthOriginDropoff = value.month;
        this.dueDateLimitDayOriginDropoff = value.day;
      }
    })

    // Update time input
    this.pickupLocationForm.controls['time'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();
    })
  }

  private createDropoffForm(): void {
    this.dropoffLocationForm = this.fb.group({
      place: ['', Validators.required],
      address: [{value: '', disabled: true}, Validators.required],
      streetNumber: [{value: '', disabled: true}, Validators.required],
      suiteNumber: [{value: '', disabled: true}],
      zipcode: [{value: '', disabled: true}, Validators.required],
      state: [{value: '', disabled: true}, Validators.required],
      city: [{value: '', disabled: true}, Validators.required],
      country: [{value: 'México', disabled: true}, Validators.required],
      date: [{value: '', disabled: true}, Validators.required],
      time: [{value: '', disabled: true}],
      latitude: [null, Validators.required],
      longitude: [null, Validators.required],
      isMain: [true, Validators.required],
    });

    // Update address input
    this.dropoffLocationForm.controls['address'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();

    })
    
    // Update streetNumber input
    this.dropoffLocationForm.controls['streetNumber'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();

    })

    // Update suiteNumber input
    this.dropoffLocationForm.controls['suiteNumber'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();
    })
    
    // Update zipcode input
    this.dropoffLocationForm.controls['zipcode'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();
    })

    // Update state input
    this.dropoffLocationForm.controls['state'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();
    })

    // Update city input
    this.dropoffLocationForm.controls['city'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();
    })

    // Update date input
    this.dropoffLocationForm.controls['date'].valueChanges.subscribe((value: NgbDateStruct ) => {
      if(value) {
        const pDate = this.pickupLocationForm.value.date;
        const dDate = value;
    
        const pickupDate = dayjs(`${pDate.year}-${pDate.month}-${pDate.day}`);
        const dropoffDate = dayjs(`${dDate.year}-${dDate.month}-${dDate.day}`);
        
        // validate if pickupDate === dropoffDate
        if(pickupDate.get('date') === dropoffDate.get('date') ) {
          this.errors.push({
            type: 'alert-warning',
            message: 'La fecha de entrega es igual a la fecha de recolección, revisa que este dato sea correcto.'
          });
        } 
        
        // validate if dropoffDate < pickupDate
        if(dropoffDate.get('date') < pickupDate.get('date')) {
          this.errors.push({
            type: 'alert-danger',
            message: 'La fecha de entrega es igual a la fecha de recolección, revisa que este dato sea correcto.'
          })
          this.dropoffLocationForm.markAsDirty();
        }
      }
    })

    // Update time input
    this.dropoffLocationForm.controls['time'].valueChanges.subscribe((value: string) => {
      //if(value !== '') this.emitRoute();
    })
  }

  private getStates(): void{
    this.statesService.getStates()
      .pipe(
        catchError((error) => {
          Swal.fire({
            title: error.title,
            text: error.description,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor:  "#509760",
          });
          return of(undefined);
        })
      )
      .subscribe((states) => {
        if(states) {
          this.states = states;
        }
      })
  }

}
