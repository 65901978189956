import { Component, OnInit } from '@angular/core';
import { PageContentService } from 'src/app/core/services/ui/page-content/page-content.service';

@Component({
  selector: 'dollink-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public class = "";
  constructor(
    private pageContentService: PageContentService,
  ) { }

  ngOnInit(): void {
    this.pageContentService.currentPageContentClass.subscribe((response) => {
      if(response) {
        this.class = response.class;
      }
    })
  }

}
