import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPaginationResponse } from 'src/app/core/interfaces/pagination.interface';
import { IProject, IProjectCargos, IProjectDropoff, IProjectPickup, IProjectRequestAdditionalInfo } from 'src/app/core/interfaces/projects.interface';
import { IRequestAvailableCargoUnits, IRequestAvailableDrivers } from 'src/app/core/interfaces/request.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  private readonly api = environment.api;
  private readonly endpoints = {
    pickup: '/trucking/request',
    updatePickup: '/trucking/request/{uuid}/pickup',
    updateDropoff: '/trucking/request/{uuid}/dropoff',
    incompleteRequests: '/trucking/request/incomplete',
    cargo: '/trucking/request/{uuid}/cargo',
    startRequest: '/trucking/request/{uuid}/start',
    additionalInfo: '/trucking/request/{uuid}/additional-info',
    findAvailableDrivers: '/driver/request',
    findAvailableCargoUnits: '/trucking/request/cargo-unit',
    cancelRequest: '/trucking/request/cancel',
  }

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * 
   * @param pickup 
   * @returns 
  **/
  createServiceRequestPickup(pickup: IProjectPickup): Observable<{id: number, uuid: string}> {
    return this.http.post<{id: number, uuid: string}>(`${this.api}${this.endpoints.pickup}`, pickup);
  }

  /**
   * 
   * @param requestId 
   * @param pickupLocation 
   * @returns 
  **/
  updateServiceRequestPickup(requestId: string, pickupLocation: IProjectPickup): Observable<HttpResponse<object>> {
    const endpoint = this.endpoints.updatePickup.replace('{uuid}', requestId);
    return this.http.post<HttpResponse<object>>(`${this.api}${endpoint}`, pickupLocation, { observe: 'response' });
  }
  
  /**
   * 
   * @param requestId 
   * @param dropoff 
   * @returns 
  **/
  updateServiceRequestDropoff(requestId: string, dropoff: IProjectDropoff): Observable<HttpResponse<object>> {
    const endpoint = this.endpoints.updateDropoff.replace('{uuid}', requestId);
    return this.http.post<HttpResponse<object>>(`${this.api}${endpoint}`, dropoff, { observe: 'response'} );
  }

  /**
   * Add cargas to the requests
   * @param requestId 
   * @param projectCarga 
   * @returns 
  **/
  setProjectCarga(requestId: string, projectCarga: IProjectCargos): Observable<HttpResponse<object>> {
    const endpoint = this.endpoints.cargo.replace('{uuid}', requestId);
    return this.http.post<HttpResponse<object>>(`${this.api}${endpoint}`, projectCarga);
  }

  /**
   * Get Available Drivers for Trucking Request
   * @param pickupDate 
   * @returns a list of available drivers for an incomple trucking request 
  **/
  getAvailableDrivers(pickupDate: string): Observable<IRequestAvailableDrivers[]> {
    return this.http.get<IRequestAvailableDrivers[]>(`${this.api}${this.endpoints.findAvailableDrivers}?pickupDate=${pickupDate}`);
  }

  /**
   * Get Available Drivers for Trucking Request
   * @param pickupDate 
   * @returns a list of available drivers for an incomplete trucking request
  **/
  getAvailableCargoUnits(pickupDate: string): Observable<IRequestAvailableCargoUnits[]> {
    return this.http.get<IRequestAvailableCargoUnits[]>(`${this.api}${this.endpoints.findAvailableCargoUnits}?pickupDate=${pickupDate}`);
  } 

  /**
   * Update request additional info
   * @param requestId 
   * @param additional 
   * @returns 
  **/
  updateServiceAdditionalInfo(requestId: string, additional: IProjectRequestAdditionalInfo): Observable<HttpResponse<object>> {
    const endpoint = this.endpoints.additionalInfo.replace('{uuid}', requestId);
    return this.http.post<HttpResponse<object>>(`${this.api}${endpoint}`, additional, { observe: 'response'});
  }

  /**
   * Get Request By Id
   * @param requestId 
   * @returns 
  **/
  getRequestById(requestId: string): Observable<IProject> {
    return this.http.get<IProject>(`${this.api}${this.endpoints.incompleteRequests}/${requestId}`);
  }

  /**
   * Get incomplete services
   * @returns a list of incomplete requests
  **/
  getIncompleteRequests(): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}${this.endpoints.incompleteRequests}`)
  }

  /**
   * Start new request as assigned
   * @param requestId 
   * @returns and http object
  **/
  createRequest(requestId: string): Observable<HttpResponse<object>> {
    const endpoint = this.endpoints.startRequest.replace('{uuid}', requestId);
    return this.http.post<HttpResponse<object>>(`${this.api}${endpoint}`, {}, { observe: 'response' });
  }

  /**
   * Cancel trucking request
   * @param requestId 
   * @param reason 
   * @returns 
  **/
  cancelRequest(requestId: string, reason: string): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}${this.endpoints.cancelRequest}/${requestId}`, {comment: reason}, { observe: 'response' });
  }
} 
