import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, catchError, of } from 'rxjs';
import { passwordMatcher } from 'src/app/core/helpers/passwordConfirmationMiddleware';
import { UserService } from 'src/app/core/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'gfapp-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['../../auth.style.scss']
})
export class NewPasswordComponent implements OnInit, OnDestroy {

  public newPasswordForm!: FormGroup;
  public subscription!: Subscription;
  public token: string = "";
  public name: string = "";
  public isSamePassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private userService: UserService, 
    private route: Router,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.subscription = this.activeRoute.params.subscribe(params => {
      this.token = params['token'];
      this.name = params['name'];
      if(!this.token) {
        this.route.navigate(['/auth']);
      }
    })
  }

  ngOnDestroy(): void {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  passwordMatcher(): void {
    const password = this.newPasswordForm.get('password')!.value;
    const confirmPassword = this.newPasswordForm.get('confirmPassword')!.value;
    if(password === confirmPassword){
      this.isSamePassword = true;
    } else {
      this.isSamePassword = false;
    }
  }

  updatePassword(e: Event, form: FormGroup): void {
    if(form.valid){
      this.userService.updatePassword(form.value.password, this.token)
        .pipe(
          catchError((error) => {
            Swal.fire({
              title: error.title,
              text: error.description,
              allowEscapeKey: false,
              showConfirmButton: true,
              confirmButtonColor:  "#509760",
            });
            return of(undefined);
          })
        )
        .subscribe((response) => {
          if(response) {
            this.createForm();
            Swal.fire({
              title: 'Excelente 🥳',
              html: 'Tu contraseña se ha modificado de manera exitosa.',
              icon: 'success',
              backdrop: true,
              allowEscapeKey: false,
              confirmButtonText: 'Regresar al login',
              confirmButtonColor: '#509760'
            }).then(() => {
              this.route.navigate(['/auth']);
            })
          }
        })
    }
  }

  createForm(): void {
    this.newPasswordForm = this.fb.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    })
  }
}
