<div class="unit-assignation">
  <div class="unit-info d-flex align-items-center mb-2">
    <figure class="avatar me-3">
      <img *ngIf="truckInfo && truckInfo.cargoUnit!.photo; else noUnitPhoto" [src]="truckInfo.cargoUnit.photo" alt="Driver Image">
      <ng-template #noUnitPhoto>
        <img src="/assets/images/avatars/img-unidad-avatar@1x.jpg" alt="Cargo Unit Image">
      </ng-template>
    </figure>
    <div class="unit-info-block">
      <span class="text-muted">Unidad</span>
      <div class="form-group">
        <select class="form-select" (change)="selectUnit($event)">
          <option value="" [defaultSelected]="true">Selecciona una unidad</option>
          <option *ngFor="let truck of trucks" value="{{truck.cargoUnit.id}}" [selected]="truckInfo  && truck.cargoUnit.id">
            {{truck.cargoUnit.brand}} {{truck.cargoUnit.model}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="unit-info">
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="unit-info-block">
          <span class="text-muted">Unidad</span>
          <p>{{truckInfo?.cargoUnit?.trailerType?.name || 'N/A'}}</p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="unit-info-block">
          <span class="text-muted">Capacidad de carga</span>
          <p *ngIf="truckInfo?.cargoUnit; else noTruckInfoWeightCapacity">{{truckInfo?.cargoUnit?.weightCapacity + 'KG'}}</p>
          <ng-template #noTruckInfoWeightCapacity>
            <p>N/A</p>
          </ng-template>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="unit-info-block">
          <span class="text-muted">Placas</span>
          <p>{{truckInfo?.cargoUnit?.mainUnitPlate || 'N/A'}}</p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="secondCargoUnit">
      <div class="col-12 col-md-4">
        <div class="unit-info-block">
          <span class="text-muted">Unidad</span>
          <p>{{secondCargoUnit.cargoUnit.trailerType.name || 'N/A'}}</p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="unit-info-block">
          <span class="text-muted">Capacidad de carga</span>
          <p *ngIf="secondCargoUnit.cargoUnit.weightCapacity; else noTruckInfoWeightCapacity">{{secondCargoUnit.cargoUnit.weightCapacity + 'KG'}}</p>
          <ng-template #noTruckInfoWeightCapacity>
            <p>N/A</p>
          </ng-template>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="unit-info-block">
          <span class="text-muted">Placas</span>
          <p>{{secondCargoUnit.cargoUnit.mainUnitPlate || 'N/A'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>