import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';
import { INotificationItem } from 'src/app/core/interfaces/notification.interface';
import { NotificationService } from 'src/app/core/services/notifications/notification.service';

@Component({
  selector: 'dollink-notifications-center',
  templateUrl: './notifications-center.component.html',
  styleUrls: ['./notifications-center.component.scss']
})

export class NotificationsCenterComponent implements OnInit {

  public notifications: INotificationItem[] = [];

  constructor(
    private notificationService: NotificationService,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getAllNotifications();
  }

  public onCloseModal(): void {
    this.modal.dismissAll();
  }

  public redirectTo(notification: INotificationItem): void {
    this.notificationService.markAsRead(notification.id)
      .pipe(
        catchError((error) => {
          console.error(error);
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if(response && response.timestamp) {
          //redirects to
        }
      })
  }

  private getAllNotifications(): void {
    this.notificationService.getAllNotifications()
      .pipe(
        catchError((error) => {
          console.error(error);
          return of(undefined);
        })
      )
      .subscribe((notifications) => {
        if(notifications) {
          this.notifications = notifications.content;
        }
      })
  }
}
