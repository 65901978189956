<div class="user" *ngIf="company">
  <div class="d-flex align-items-center" [routerLink]="[accountURL]">
    <img *ngIf="company.image !== null; else noCompanyImageTemplate" [src]="company.image" alt="" width="40" height="40" class="rounded-circle me-2">
    <ng-template #noCompanyImageTemplate>
      <div class="user-initials">{{userNameInitials}}</div>
    </ng-template>
    <div class="user-info me-2">
      {{user.firstName}} {{user.lastName}}
      <span class="text-muted">{{company.name}}</span>
    </div>
  </div>
</div>