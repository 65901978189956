import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface IDimensions {
  length: number;
  width: number;
  height: number;
}

@Component({
  selector: 'dollink-cargo-dimensions-modal',
  templateUrl: './cargo-dimensions-modal.component.html',
  styleUrls: ['./cargo-dimensions-modal.component.scss']
})
export class CargoDimensionsModalComponent implements OnInit {

  @Input() dimensions: IDimensions = {length: 0, width: 0, height: 0};

  constructor(
    private modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  public onClose(): void {
    this.modal.close();
  }
}
