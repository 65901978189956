<div class="notifications" ngbDropdown (openChange)="onNotificationsDropdownChange($event)" display="dynamic" placement="bottom-end" container="body" style="right: 5px;">
  <div class="notifications-toggle" ngbDropdownToggle>
    <figure *ngIf="notifications.totalUnviewedNotifications > 0;">
      <img src="/assets/svg/notifications.fill.svg" alt="Notifications Fill Icon">
    </figure>
    <figure *ngIf="notifications.totalUnviewedNotifications === 0;">
      <img src="/assets/svg/notifications.empty.svg" alt="Notifications Empty Icon">
    </figure>
    <span *ngIf="notifications.totalUnviewedNotifications > 0" class="notifications-badge" [ngStyle]="{'right': notifications.totalUnviewedNotifications > 9 ? '-19px' : '-12px' }">
      {{notifications.totalUnviewedNotifications}}
    </span>
  </div>
  <div #notificationsMenu ngbDropdownMenu style="width: 400px !important; box-shadow: 0 2px 8px 0 rgba(0,0,0,0.12); border: none;" class="mt-3">
    <div class="notifications-header d-flex align-items-center justify-content-between">
      <span>
        Notificaciones
      </span>
      <span class="material-symbols-outlined">
        notifications
      </span>
    </div>
    <div class="notifications-menu" *ngIf="notifications.items.length > 0; else noNotificationsTemplate">
      <ul class="notifications-list">
        <li *ngFor="let notification of notifications.items" [class.unviewed]="!notification.viewed" [class.unread]="!notification.read" (click)="onMarkNotificationAsRead($event, notification)">
          <p class="message">{{notification.message}}</p>
          <p class="created-date">
            Creada {{notification.createdDate | humanizeDate}}
          </p>
          <span class="material-symbols-outlined icon-unread">
            fiber_manual_record
          </span>
        </li>
      </ul>
      <button class="notifications-action" (click)="onOpenNotificationCenter()" *ngIf="notifications.totalNotifications > 5">
        Todas las notificaciones
      </button>
    </div>
    <ng-template #noNotificationsTemplate>
      <div class="notifications-empty">
        Estas al día, no tienes notificaciones 🎉
      </div>
    </ng-template>
  </div>
</div>