<section class="auth" *ngIf="isAccountSelected">
  <div class="auth-form">
    <figure>
      <img src="assets/images/logos/svg-logo-dollink.svg" alt="Dollink Logo" width="120">
    </figure>
    <h1 class="mb-5">
      Hola {{accountTypeDescription}}, <br> Registrate a
      <strong>Dollink App.</strong>
    </h1>
    <form
      [formGroup]="registerForm"
      (ngSubmit)="onRegister()"
      class="w-100"
      dollinkInvalidFormScroll
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="nameInput" class="form-label">Nombre</label>
            <div class="input-group has-validation">
              <input type="text" minlength="2" class="form-control" id="nameInput" formControlName="name" [class.is-invalid]="submitted && registerForm.controls['name'].errors">
              <div 
                *ngIf="submitted"
                class="invalid-feedback">
                <p *ngIf="registerForm.controls['name'].hasError('required')">El nombre es requerido</p>
                <p *ngIf="registerForm.controls['name'].hasError('minLength')">El nombre deberá contener al menos 2 carácteres</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="lastNameInput" class="form-label">Apellido</label>
            <div class="input-group has-validation">
              <input type="text" minlength="3" class="form-control" id="lastNameInput" formControlName="lastName" [class.is-invalid]="submitted && registerForm.controls['lastName'].errors">
              <div 
                *ngIf="submitted"
                class="invalid-feedback">
                <p *ngIf="registerForm.controls['lastName'].hasError('required')">El apellido es requerido</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="emailInput" class="form-label">Correo electrónico</label>
            <div class="input-group has-validation">
              <input type="email" class="form-control" id="emailInput" formControlName="email" [class.is-invalid]="submitted && registerForm.controls['email'].errors">
              <div 
                *ngIf="submitted"
                class="invalid-feedback">
                <p *ngIf="registerForm.controls['email'].hasError('required')">El correo electrónico es requerido</p>
                <p *ngIf="registerForm.controls['email'].hasError('pattern')">El correo electrónico tiene un formato incorrecto</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="passwordInput" class="form-label">Contraseña</label>
            <div class="input-group has-validation">
              <input type="password" class="form-control" id="passwordInput" formControlName="password" [class.is-invalid]="submitted && registerForm.controls['password'].errors">
              <div 
                *ngIf="submitted"
                class="invalid-feedback">
                <p *ngIf="registerForm.controls['password'].hasError('required')">La contraseña es requerida</p>
                <p *ngIf="registerForm.controls['password'].hasError('minLength')">La contraseña debe de contener al menos 6 carácteres</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="comercialNameInput" class="form-label">Nombre comercial</label>
            <div class="input-group has-validation">
              <input type="text" class="form-control" id="comercialNameInput" formControlName="comercial_name" [class.is-invalid]="submitted && registerForm.controls['comercial_name'].errors">
              <div 
                *ngIf="submitted"
                class="invalid-feedback">
                <p *ngIf="registerForm.controls['comercial_name'].hasError('required')">El nombre comercial es requerido</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showErrorMessage" class="mb-3">
        <div class="alert alert-danger d-flex align-items-center" role="alert">
          <i class="bi bi-exclamation-triangle-fill me-2"></i>
          <div>
            {{errorMessage}}
          </div>
        </div>
      </div>
      <div class="mb-5 d-grid">
        <button type="submit" class="btn btn-primary btn-block" [disabled]="isLoading">
          <span class="spinner-border spinner-border-sm me-2" aria-hidden="true" *ngIf="isLoading"></span>
          <span role="status">{{registerButtonText}}</span>
        </button>
      </div>
    </form>
    <div class="w-100 d-flex flex-column align-items-center">
      <span class="text-center mb-2">
        Ya tienes cuenta? <a [routerLink]="['/auth/sign-in']">Inicia Sesión</a>
      </span>
      <span class="text-center text-muted">
        ®{{year}} DOLLINK. Todos los derechos reservados.
      </span>
    </div>
  </div>
  <div class="auth-banner register">
    <img src="assets/images/dollink_for_requestor.png" alt="">
  </div>
</section>