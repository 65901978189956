<div class="onboarding" ngbAutofocus>
  <div class="modal-body">
    <div class="row d-flex align-items-center">
      <div class="col-xs-12 col-md-6">
        <figure class="onboarding-image">
          <img src="{{modalData.image}}" alt="">
        </figure>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="onboarding-title mb-5">
          <h2>{{modalData.title}}</h2>
          <h2>Bienvenido a <span class="text-brand-color ">Dollink</span> 🎉</h2>
          <p>{{modalData.subtitle}}</p>
        </div>
        <ul class="onboarding-steps">
          <li *ngFor="let step of modalData.steps" (click)="onBoardingRedirect(step.route)">
            {{step.description}}
            <span *ngIf="step.isCompleted; else unCompletedTask" class="material-symbols-outlined completed">task_alt</span>
            <ng-template #unCompletedTask><span class="material-symbols-outlined">do_not_disturb_on</span></ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" (click)="onCloseOnboarding()">Saltar intro</button>
  </div>
</div>