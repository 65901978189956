import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class WebSocketShareService implements OnDestroy {
  
  private messageDataSubject = new BehaviorSubject<string>('');
  
  constructor() {}
  
  ngOnDestroy(): void {
    this.messageDataSubject.unsubscribe();
  }

  onNewValueReceive(msg: string) {
    this.messageDataSubject.next(msg);
  }
  
  getNewValue(): Observable<string> {
    return this.messageDataSubject.asObservable();
  }
}
