import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { HttpHeaders } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class LegalService {
  private readonly API_URL: string = env.api;

  constructor(private _http: HttpClient, private authService: AuthService) {

  }
  
  getDocuments(){
    return this._http.get<Array<any>>(`${this.API_URL}/company/document`);
  }

  saveDocument(document: FormData): Observable<any> {
    return this._http.post(`${this.API_URL}/company/document`, document,{headers: {},reportProgress: true, responseType: 'json',});
  }

  deleteDocument(documentId: number): Observable<any>  { 
    const headers = new HttpHeaders()
    .set("Accept", "application/json");
    return this._http.delete<any>(`${this.API_URL}/company/document/${documentId}`,{headers: headers});
  }

  downloadById(path: string): Observable<any>{
    const token = this.authService.getToken();
    return this._http.get<any>(`https://api-dev.dollink.app${path}/download?token=${token}`,{responseType: 'blob' as 'json'});
  }

}
