import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbActiveOffcanvas, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '../../../../core/services/requests/request.service';
import { catchError, of } from 'rxjs';
import { REQUEST_STATUS } from '../../../../core/enums/request.status.enums';
import { CargoDimensionsModalComponent } from '../../../modals/cargo-dimensions-modal/cargo-dimensions-modal.component';
import Swal from 'sweetalert2';

export interface cargoInformation {
  id?: number;
  status?: string;
  isRequestor?: boolean;
}

export interface ICargoList {
  cargoList: any;
  requestStatus?: any;
}

@Component({
  selector: 'dollink-cargo-details',
  templateUrl: './cargo-details.component.html',
  styleUrls: ['./cargo-details.component.scss'],
})

export class CargoDetailsComponent implements OnInit {
  
  public cargos!: Array<any>;
  public requestStatusAbbr = '';
  public information!: cargoInformation;
  public user: any;
  public requestId = 0;
  public REQUEST_STATUS = REQUEST_STATUS;

  @ViewChild('cargoListSideBar') cargoListSideBar!: ElementRef;

  @Input() set configuration(data: ICargoList) {
    this.cargos = data.cargoList;
    this.requestStatusAbbr = data.requestStatus;
  }

  @Output() onCargoDeleted = new EventEmitter<boolean>();

  constructor(
    private offCanvasService: NgbOffcanvas,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private requestService: RequestService,
    private router: Router,
    private modal: NgbModal,
    private activeOffCanvas: NgbActiveOffcanvas
  ) {}

  ngOnInit(): void {
    const user = this.authService.getDecodedToken();
    const companyUserType = user.companyType;
    this.user =
      companyUserType.toLowerCase() === 'transportistas'
        ? 'TRUCKING'
        : 'REQUESTOR';
    this.requestId = Number(this.activatedRoute.snapshot.paramMap.get('requestId'));
  }

  public onOpenDimensionsModal(length: number, width: number, height: number): void {
    const modalRef = this.modal.open(CargoDimensionsModalComponent, {
      centered: true
    })
    const dimensions = {
      length: length, 
      width: width, 
      height: height
    }
    modalRef.componentInstance.dimensions = dimensions;
  }

  public onClose(): void {
    this.activeOffCanvas.close();
  }

  public onDeleteCargo(cargo: any): void {
    if (this.cargos.length > 1) {
      Swal.fire({
        title: `¿Deseas eliminar la carga con número de referencia ${cargo.number}?`,
        text: `Una vez eliminada esta carga no podrás recuperarla`,
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonColor: '#EB5857',
        confirmButtonText: `Eliminar`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        customClass: {
          cancelButton: 'btn btn-link',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.offCanvasService.dismiss();
          Swal.fire({
            title:
              'Espera un momento por favor, estamos eliminando tu carga...',
            icon: 'info',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
          });
          this.requestService
            .deleteCargoFromRequest(this.requestId, cargo.id)
            .pipe(
              catchError((error) => {
                Swal.fire({
                  title: `Error... [${error.message}]`,
                  text: 'Algo salio mal, porfavor intenta más tarde',
                  icon: 'error',
                  confirmButtonColor: '#EB5857',
                });
                return of(undefined);
              })
            )
            .subscribe((response) => {
              if (response) {
                setTimeout(() => {
                  Swal.close();
                  Swal.fire({
                    title: 'Tu carga se elimino con éxito...',
                    icon: 'success',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    confirmButtonColor: '#509760',
                  });
                  this.onCargoDeleted.emit(true);
                }, 2000);
              }
            });
        }
      });
    } else {
      this.cancelRequest();
    }
  }

  private cancelRequest(): void {
    Swal.fire({
      title: `Oops...`,
      text: `Esta carga no puede ser eliminada, para que sea una solicitud valida debe de tener al menos una carga registrada. ¿Deseas cancelar la solicitud completa?`,
      icon: 'warning',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonColor: '#EB5857',
      confirmButtonText: `Cancelar solicitud`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      customClass: {
        cancelButton: 'btn btn-link',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.offCanvasService.dismiss();
        this.requestService
          .cancelRequest(this.requestId)
          .pipe(
            catchError((error) => {
              Swal.fire({
                title: `Error... [${error.message}]`,
                text: 'Algo salio mal, porfavor intenta más tarde',
                icon: 'error',
                confirmButtonColor: '#EB5857',
              });
              return of(undefined);
            })
          )
          .subscribe((response) => {
            if(response) {
              Swal.fire({
                title: `Tu solicitud se ha cancelado con éxito!`,
                icon: 'success',
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#509760',
              }).then(() => {
                this.router.navigate([
                  '/requestor/requests/in-negotiation',
                ]);
              });
            }
          });
      }
    });
  }
}
