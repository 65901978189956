<section class="chat" ngbAutoFocus>
  <div class="offcanvas-header">
    <div class="recipient-image me-2">
      <figure class="avatar">
        <img *ngIf="chatConfig.recipientInfo.image; else noRecipientImage" [src]="chatConfig.recipientInfo.image" alt="{{chatConfig.recipientInfo.name}} Image">
        <ng-template #noRecipientImage>
          <img src="/assets/images/avatars/img-company-logo@1x.jpg" alt="{{chatConfig.recipientInfo.name}} Image">
        </ng-template>
      </figure>
    </div>
    <h4>
      <span>Estas chateando con</span>
      {{chatConfig.recipientInfo.name}}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCloseCanvas()"></button>
  </div>
  <div class="offcanvas-body" id="offcanvas-body">
    
    <div class="chat-list" id="chat-list" *ngIf="chats.length > 0; else emptyStateMessages">
      @for (chat of chats; track chat.messageId) {
        <div class="chat-list-message" [class.is-mine]="chat.companySender.id === companySenderId">
          <div class="message-text">
            <span class="message-content">{{chat.content}}</span>
            <div class="message-hour">
              {{chat.createdDate | date:'shortTime'}}
            </div>
          </div>
        </div>
      }
    </div>

    <ng-template #emptyStateMessages>
      <div class="no-messages">
        <figure>
          <img src="/assets/images/empty-states/chat-empty-state.svg" alt="">
        </figure>
        <div class="d-flex align-items-center flex-column text-center" *ngIf="readonly">
          <p  class="no-messages-title">
            Sin mensajes.
          </p>
          <p class="text-muted">
            Esta solicitud no tuvo ningún mensaje durante todo el proceso.
          </p>
        </div>
        <div class="d-flex align-items-center flex-column text-center" *ngIf="!readonly">
          <p  class="no-messages-title">
            No tienes ningún mensaje aún.
          </p>
          <p class="text-muted">
            Envía tu primer mensaje y mantente conectado durante todo el proceso del viaje.
          </p>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="chat-box" *ngIf="!readonly">
    <form [formGroup]="chatForm" #messageInput (submit)="send()">
      <div class="input-group">
        <textarea 
          class="form-control" 
          placeholder="Escribe tu mensaje..." 
          aria-label="Escribe tu mensaje..." 
          aria-describedby="sendMessageButton" 
          formControlName="message"
          (keydown.enter)="onEnterKeyPress($event)"
        ></textarea>
        <button class="btn btn-primary btn-outline" type="submit" id="sendMessageButton">
          <i class="bi bi-send-fill"></i>
          Enviar
        </button>
      </div>
    </form>
  </div>
</section>