import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, Settings } from 'luxon';

@Pipe({
  name: 'humanizeDate'
})
export class HumanizeDatePipe implements PipeTransform {

  transform(date: string) {
    Settings.defaultLocale = 'es-ES'
    const relativeDateString = DateTime.fromISO(date).toRelativeCalendar();
    const now = DateTime.now().toLocal();
    const past = DateTime.fromISO(date).toLocal();
    const diffMin = now.diff(past, 'minutes');

    // created yesterday
    if(relativeDateString === 'ayer') {
      return 'Ayer'
    }

    // created today, but more than an hour ago "10:45 am, today"
    if( relativeDateString === 'hoy' && diffMin.minutes > 60) {
      const time = past.toFormat('t');
      return `${time.toLocaleLowerCase()}, hoy`;
    }

    // created within an hour "25 minutes ago";
    if( relativeDateString === 'hoy' && diffMin.minutes < 60 ) {
      if(diffMin.minutes < 1 ) {
        return `hace un momento`;
      }
      return `hace ${Math.floor(diffMin.minutes)} minutos`;
    } 

    // created before yesterday 
    return relativeDateString;
  }

}
