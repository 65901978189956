import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  get(key: string) {
    const data: string | null = sessionStorage.getItem(key);
    if(data !== null) {
      return JSON.parse(data).value;
    }
    return null;
  }

  set(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify({value}));
  }

  remove(key: string): void {
    sessionStorage.removeItem(key);
  }

  removeAll(): void {
    sessionStorage.clear();
  }
}
