import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { environment as env } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LoginResponse, SignUpInfo } from '../../interfaces/auth.interface';
import jwt from 'jwt-decode';
import { ACCOUNT_TYPE } from '../../enums/account.types.enum';
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly api = env.api;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) { }

  register(accountInfo: SignUpInfo): Observable<any> {
    return this.http.post(`${this.api}/auth/signup`, accountInfo);
  }

  login(email: string, password: string): Observable<HttpResponse<LoginResponse>> {
    return this.http.post<LoginResponse>(`${this.api}/auth/login`, {email, password}, {observe: 'response'});
  }

  refreshToken(): Observable<any> {
    const data = { refreshToken: this.storage.get('dollink_user_refresh_token')}
    return this.http.post(`${this.api}/auth/refresh-token`, data);
  }

  saveAuthTokens(response: {accessToken: string; refreshToken: string; tokenType: string;}): void {
    this.storage.set('dollink_user_refresh_token', response.refreshToken);
    this.storage.set('dollink_user_token', response.accessToken);
  }

  getToken(): string | null | undefined {
    return this.storage.get('dollink_user_token');
  }

  getDecodedToken() {
    const token = this.getToken();
    if(token) {
      const decodedToken: any = jwt(token);
      return JSON.parse(decodedToken.sub);
    }
  }

  getRole(): string {
    return this.storage.get('dollink_user_role');
  }

  getUserCompanyType(): string {
    const user = this.getDecodedToken();
    const userTypeCompany = user.companyType;
    return userTypeCompany.toLowerCase() === 'transportistas' ? ACCOUNT_TYPE.GROUND_FREIGHT : ACCOUNT_TYPE.REQUESTOR;
  }

  isAuthenticated(): boolean {
    const token: string | undefined | null = this.getToken();
    if(token){
      return true;
    }
    return false;
  }

  logout(): void {
    this.storage.removeAll();
  }
}
