import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IHeaderTitle } from 'src/app/core/interfaces/header-service.interface';
import { HeaderService } from 'src/app/core/services/ui/header/header.service';

@Component({
  selector: 'dollink-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {


  public headerTitle: IHeaderTitle | undefined = undefined;
  public headerServiceSuscription!: Subscription;

  constructor(
    private headerService: HeaderService,
  ) { }

  ngOnInit(): void {
    this.headerServiceSuscription = this.headerService.currentHeaderTitle.subscribe((headerTitle) => {
      this.headerTitle = headerTitle;
    })
  }

  ngOnDestroy(): void {
    if( this.headerServiceSuscription ) this.headerServiceSuscription.unsubscribe();
  }

}
