import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../storage/storage.service';

interface IDocument {
  id: number;
  path: string;
  createdDate: string;
}

@Injectable({
  providedIn: 'root'
})

export class DocumentsService {

  private readonly api = environment.api;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
  ) { }

  getDocumentByAbbr(abbr: string): Observable<IDocument> {
    return this.http.get<IDocument>(`${this.api}/company/documents?list=${abbr}`)
  }

  // General request services
  uploadFiles(files: File[], requestId: number): Observable<any> {
    let formData = new FormData();
    Array.from(files).forEach((file: File) => {
      formData.append('files', file);
    })
    return this.http.post(`${this.api}/request/files/${requestId}`, formData);
  }

  getDocumentsByRequest(requestId: number): Observable<any> {
    return this.http.get<any>(`${this.api}/request/file/${requestId}`);
  }

  deleteDocument(documentId: number): Observable<any> {
    return this.http.delete<any>(`${this.api}/request/file/${documentId}`);
  }

  downloadDocument(item: any): void {
    const fileName = this.extractS3Path(item.document.path);
    const link = document.createElement('a');
    link.href = item.document.path;
    link.target = '_blank';
    link.download = fileName;
    link.click();
  }

  private extractS3Path(url: string): string {
    const pattern = /\/\/.*\/(.*?)\?/;
    const matcher = pattern.exec(url);
    if (matcher) {
      return matcher[1];
    } else {
      return "No se encontró el patrón en la URL";
    }
  }
}
