import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of, Subscription } from 'rxjs';
import { parseStringToNumber } from 'src/app/core/helpers/global.helper';
import { IProjectAdditionalInfo } from 'src/app/core/interfaces/projects.interface';
import { IRequestAvailableCargoUnits, IRequestAvailableDrivers } from 'src/app/core/interfaces/request.interface';
import { TrailerTypes } from 'src/app/core/interfaces/trailer-types.interface';
import { RequestsService } from 'src/app/core/services/trucking/requests/requests.service';
import { PHONE_MASK, PRICE_MASK } from 'src/app/shared/masks/masks';

@Component({
  selector: 'dollink-request-trucking-additionals',
  templateUrl: './request-trucking-additionals.component.html',
  styleUrl: './request-trucking-additionals.component.scss'
})
export class RequestTruckingAdditionalsComponent implements OnInit, OnDestroy {

  @Input() projectId: string = "";
  @Input() additionalInfo: IProjectAdditionalInfo | undefined = undefined;
  @Input() pickupDate = "";

  public requestExtrasForm!: FormGroup;
  public trailerTypes: TrailerTypes[] = [];
  public submitted = false;
  public isSaving = false;
  public priceMask = PRICE_MASK;
  public phoneMask = PHONE_MASK;
  public cargoUnits: IRequestAvailableCargoUnits[] = [];
  public drivers: IRequestAvailableDrivers[] = [];
  public updateButtonText = "Guardar";
  
  private getRequestDataSubscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    private requestsService: RequestsService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getCargoUnits();
    this.getDrivers();
    
    if(this.additionalInfo) {
      this.requestExtrasForm.controls['clientName'].setValue(this.additionalInfo.client?.name);
      this.requestExtrasForm.controls['clientContactName'].setValue(this.additionalInfo.client?.contactName);
      this.requestExtrasForm.controls['clientContactPhone'].setValue(this.additionalInfo.client?.contactPhone);
      this.requestExtrasForm.controls['clientContactEmail'].setValue(this.additionalInfo.client?.contactEmail);
      this.requestExtrasForm.controls['price'].setValue(this.additionalInfo.price?.toString());
      this.requestExtrasForm.controls['paymentConditions'].setValue(this.additionalInfo.paymentConditions);
      this.requestExtrasForm.controls['alias'].setValue(this.additionalInfo.alias);
      this.requestExtrasForm.controls['comments'].setValue(this.additionalInfo.comments);

      if(this.additionalInfo.cargoUnits && this.additionalInfo.cargoUnits.length > 0) this.requestExtrasForm.controls['cargoUnits'].setValue([this.additionalInfo.cargoUnits[0].uuid])
      if(this.additionalInfo.drivers && this.additionalInfo.drivers.length > 0) this.requestExtrasForm.controls['drivers'].setValue([this.additionalInfo.drivers[0].id])
    }
  }

  ngOnDestroy(): void {
    if(this.getRequestDataSubscription) this.getRequestDataSubscription.unsubscribe();
  }

  public onClose(): void {
    this.modal.close();
  }

  public onSave(): void {
    this.submitted = true;
    if(this.requestExtrasForm.valid) {
      this.isSaving = true;
      this.updateButtonText = 'Guardando';
      const form = this.requestExtrasForm.value;
      let data = {
        comments: form.comments,
        price: null,
        alias: form.alias,
        paymentConditions: form.paymentConditions,
        client: {
          name: form.clientName,
          contactName: form.clientContactName,
          contactPhone: form.clientContactPhone,
          contactEmail: form.clientContactEmail
        },
        drivers: form.drivers || [],
        cargoUnits: form.cargoUnits
      }
      if(form.price) {
        data['price'] = typeof form.price === 'number' ? form.price : parseStringToNumber(form.price);
      }
      this.requestsService.updateServiceAdditionalInfo(this.projectId, data)
        .pipe(
          catchError(() => {
            return of(undefined);
          })
        )
        .subscribe((response) => {
          if(response) {
            this.modal.close(response);
          }
        })
    } else {
      this.requestExtrasForm.markAllAsTouched();
    }
  }

  public onSelectCargoUnit(e: any): void {
    const cargoUnitId = e.target.value;
    this.requestExtrasForm.controls['cargoUnits'].setValue([cargoUnitId]);
  }

  public onSelectDrivers(driverId: string): void {
    const drivers = driverId !== '' ? [driverId] : [];
    this.requestExtrasForm.controls['drivers'].setValue(drivers);
  }

  private getCargoUnits(): void {
    this.requestsService.getAvailableCargoUnits(this.pickupDate)
      .pipe(
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((cargoUnits) => {
        if(cargoUnits) {
          this.cargoUnits = cargoUnits;
        }
      })
  }

  private getDrivers(): void {
    this.requestsService.getAvailableDrivers(this.pickupDate)
      .pipe(
        catchError(() => {
          return of(undefined);
        })
      )
      .subscribe((drivers) => {
        if(drivers) {
          this.drivers = drivers;
        }
      })
  }

  private createForm(): void {
    this.requestExtrasForm = this.fb.group({
      comments: [''],
      price: [''],
      alias: [''],
      paymentConditions: [''],
      clientName: ['', Validators.required],
      clientContactName: ['', Validators.required],
      clientContactPhone: ['', Validators.required],
      clientContactEmail: ['', Validators.required],
      cargoUnits: [null, Validators.required],
      drivers: [null],
    })
  }
}
