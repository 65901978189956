import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbTooltipModule, NgbPaginationModule, NgbDropdown, NgbOffcanvasModule, NgbActiveOffcanvas, NgbCollapseModule, NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ScrollingModule } from '@angular/cdk/scrolling'
import { IMaskModule } from "angular-imask";

import {
  AutocompleteAddressComponent,
  CargoDetailsComponent,
  ChatComponent,
  DocumentUploaderComponent,
  DriverAssignationComponent,
  ImageUploaderComponent,
  NavigationComponent,
  MapComponent,
  MapDraggableMarkerComponent,
  TabsComponent,
  UnitAssignationComponent,
  NotificationsComponent,
  ChatNotificationsComponent,
  ProfileBadgeComponent,
  HeaderComponent,
  BreadcrumbsComponent,
  RequestRouteComponent,
  RequestPickupComponent,
  RequestDropoffComponent,
  RequestDetailsComponent,
  RequestTruckingAdditionalsComponent
} from './components';

import { 
  OnboardingComponent,
  RequestDocumentsModalComponent,
  UpdatePriceModalComponent,
  RequestTypeModalComponent,
  NotificationsCenterComponent,
  CargoDimensionsModalComponent,
  MultipleUnitAssignationModalComponent,
} from './modals';

import { 
  DragAndDropDirective,
  InvalidFormScrollDirective,
  InputTextUppercaseDirective,
} from './directives';

import {
  HumanizeDatePipe
} from './pipes';

const COMPONENTS = [
  ImageUploaderComponent,
  TabsComponent,
  NavigationComponent,
  ChatComponent,
  MapComponent,
  DriverAssignationComponent,
  UnitAssignationComponent,
  DocumentUploaderComponent,
  AutocompleteAddressComponent,
  CargoDetailsComponent,
  MapDraggableMarkerComponent,
  NotificationsCenterComponent,
  CargoDimensionsModalComponent,
  BreadcrumbsComponent,
  NotificationsComponent,
  ChatNotificationsComponent,
  ProfileBadgeComponent,
  HeaderComponent,
  RequestRouteComponent,
  RequestPickupComponent,
  RequestDropoffComponent,
  RequestDetailsComponent,
  RequestTruckingAdditionalsComponent,
  RequestTypeModalComponent,
];

const MODALS = [
  OnboardingComponent,
  UpdatePriceModalComponent,
  RequestDocumentsModalComponent,
  MultipleUnitAssignationModalComponent,
];

const DIRECTIVES = [
  DragAndDropDirective,
  InvalidFormScrollDirective,
  InputTextUppercaseDirective,
]

const PIPES = [
  HumanizeDatePipe
]

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgbDatepickerModule,
  NgSelectModule,
  NgbTooltipModule,
  NgbDropdownModule,
  NgbNavModule,
  ScrollingModule,
  NgbPaginationModule,
  NgbOffcanvasModule,
  NgbCollapseModule,
  NgbAccordionModule,
  IMaskModule,
];

@NgModule({
  declarations: [COMPONENTS, MODALS, DIRECTIVES, PIPES],
  imports: [MODULES],
  exports: [COMPONENTS, DIRECTIVES, PIPES, MODULES],
  providers: [NgbDropdown, NgbActiveOffcanvas],
})

export class SharedModule {}