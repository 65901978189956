<div class="onboarding" ngbAutofocus>
  <div class="modal-header">
    <ng-container *ngIf="!readonly">
      <h4 class="modal-title" id="modal-basic-title">Subir Documentación</h4>
    </ng-container>
    <ng-container *ngIf="readonly">
      <h4 class="modal-title" id="modal-basic-title">Documentos</h4> 
    </ng-container>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onClose()"
    ></button>
  </div>
  <div class="modal-body">
    <p class="text-muted" *ngIf="!readonly">Archivos con extensión .pdf, .xlsx, .xls, .docx, .doc, .jpg, .jpeg, .png, .txt</p>
    <div class="file-manager mb-5" dollinkDragAndDrop (fileDropped)="onDroppedFile($event)" *ngIf="!readonly">
      <input type="file" id="file-input" multiple (change)="onSelectFiles($event)">
      <img src="assets/images/icons/ico-svg-new_file@1x.svg" alt="New File Icon">
      <p>
        <label class="text-brand-color" for="file-input">Click aquí para seleccionar archivos</label>
        o arrastra y suelta tus archivos.
      </p>
      <p class="text-muted">Máximo 5MB por archivo</p>
    </div>
    <div class="file-manager-list">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        
        <li [ngbNavItem]="1">
          <a ngbNavLink>Mis Documentos</a>
          <ng-template ngbNavContent>
            <div class="alert alert-secondary" role="alert" *ngIf="ownedDocuments.length === 0 && files.length === 0">
              No has subido archivos aún, selecciona o arrastra tus archivos para compartirlos.
            </div>
            
            <!-- Current files -->
            <div class="file-manager-document" *ngFor="let document of files">
              <div class="document-type-icon">
                <img src="assets/images/icons/ico-svg-file@1x.svg" alt="File Icon">
              </div>
              <div class="document-info w-100">
                <p>{{document.file.name}} {{document.fileSize}}</p>
                <p class="text-muted">Subido {{ document.uploadedAt | humanizeDate }}</p>
              </div>
              <div class="document-actions" *ngIf="!document.isUploading && document.notification !== '' ">
                <span class="material-symbols-outlined delete">
                  delete_forever
                </span>
              </div>
              <div class="document-error" *ngIf="document.notification">
                <p>{{document.notification}}</p>
              </div>
            </div>

             <!-- Uploaded files -->
             <div class="file-manager-document" *ngFor="let document of ownedDocuments">
              <div class="document-type-icon">
                <img src="assets/images/icons/ico-svg-file@1x.svg" alt="File Icon">
              </div>
              <div class="document-info w-100">
                <p>{{document.name}}</p>
                <p class="text-muted">Subido {{document.createdDate | humanizeDate}}</p>
              </div>
              <div class="document-actions" *ngIf="!document.isUploading && document.notification !== '' ">
                <span class="material-symbols-outlined delete" (click)="onDeleteFile(document)">
                  delete_forever
                </span>
              </div>
              <div class="document-error" *ngIf="document.notification">
                <p>{{document.notification}}</p>
              </div>
            </div>
          </ng-template>
        </li>
        
        <li [ngbNavItem]="2">
          <a ngbNavLink>Documentos compartidos conmigo</a>
          <ng-template ngbNavContent>
            <div class="alert alert-secondary" role="alert" *ngIf="sharedDocuments.length === 0">
              No se han compartido archivos contigo.
            </div>

            <!-- Shared files -->
            <div class="file-manager-document" *ngFor="let document of sharedDocuments">
              <div class="document-type-icon">
                <img src="assets/images/icons/ico-svg-file@1x.svg" alt="File Icon">
              </div>
              <div class="document-info w-100">
                <p>{{document.name}}</p>
                <p class="text-muted">Subido {{document.createdDate | humanizeDate}}</p>
              </div>
              <div class="document-actions" *ngIf="!document.isUploading && document.notification !== '' ">
                <span class="material-symbols-outlined me-2" (click)="onDownloadFile(document)">
                  cloud_download
                </span>
              </div>
              <div class="document-error" *ngIf="document.notification">
                <p>{{document.notification}}</p>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2 pt-3 pb-3"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="onSubmitFiles($event)" [disabled]="isLoadingDocuments || files.length === 0" *ngIf="!readonly">
      <span *ngIf="isLoadingDocuments">Subiendo documentos...</span>
      <span *ngIf="!isLoadingDocuments">Subir documentos</span>
    </button>
  </div>
</div>