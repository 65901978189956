import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, catchError, of, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.authService.refreshToken().pipe(
            switchMap((res: any) => {
              this.authService.saveAuthTokens(res);
              const authToken = this.authService.getToken();
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${authToken}`
                }
              });
              return next.handle(request);
            }),
            catchError((error: any) => {
              if(error.status === 403) {
                this.authService.logout();
                this.router.navigate(['/auth/sign-in'], {state: {refreshTokenInvalid: true}});
              } else {
                return throwError(() => error);
              }
              return new Observable<HttpEvent<any>>;
            })
          );
        }
        return throwError(() => error);
      })
    );
  }
}
