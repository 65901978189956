export const States = [
  {value: 'aguascalientes', name: 'Aguascalientes', abbr: 'AGS'},
  {value: 'baja california', name: 'Baja California', abbr: 'BC'},
  {value: 'baja california', name: 'Baja California Sur', abbr: 'BCS'},
  {value: 'campeche', name: 'Campeche', abbr: 'CAMP'},
  {value: 'coahuila', name: 'Coahuila', abbr: 'COAH'},
  {value: 'chihuahua', name: 'Chihuahua', abbr: 'CHIH'},
  {value: 'ciudad de méxico', name: 'Ciudad de México', abbr: 'CDMX'},
  {value: 'durango', name: 'Durango', abbr: 'DGO'},
  {value: 'guanajuato', name: 'Guanajuato', abbr: 'GTO'},
  {value: 'guerrero', name: 'Guerrero', abbr: 'GRO'},
  {value: 'hidalgo', name: 'Hidalgo', abbr: 'HGO'},
  {value: 'jalisco', name: 'Jalisco', abbr: 'JAL'},
  {value: 'mexico', name: 'México', abbr: 'MEX'},
  {value: 'michoacan', name: 'Michoacán', abbr: 'MICH'},
  {value: 'morelos', name: 'Morelos', abbr: 'MOR'},
  {value: 'nayarit', name: 'Nayarit', abbr: 'NAY'},
  {value: 'nuevo leon', name: 'Nuevo León', abbr: 'NL'},
  {value: 'oaxaca', name: 'Oaxaca', abbr: 'OAX'},
  {value: 'puebla', name: 'Puebla', abbr: 'PUE'},
  {value: 'querétaro', name: 'Querétaro', abbr: 'QRO'},
  {value: 'quintana roo', name: 'Quintana Roo', abbr: 'QR'},
  {value: 'san luis', name: 'San Luis Potosí', abbr: 'SLP'},
  {value: 'sinaloa', name: 'Sinaloa', abbr: 'SIN'},
  {value: 'sonora', name: 'Sonora', abbr: 'SON'},
  {value: 'tabasco', name: 'Tabasco', abbr: 'TAB'},
  {value: 'tamaulipas', name: 'Tamaulipas', abbr: 'TAMS'},
  {value: 'tlaxcala', name: 'Tlaxcala', abbr: 'TLAX'},
  {value: 'veracruz', name: 'Veracruz', abbr: 'VER'},
  {value: 'yucatan', name: 'Yucatán', abbr: 'YUC'},
  {value: 'zacatecas', name: 'Zacatecas', abbr: 'ZAC'},
];