import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';
import { NotificationActionEnum } from 'src/app/core/enums/notifications-actions.enums';
import { INotification, INotificationItem } from 'src/app/core/interfaces/notification.interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationService } from 'src/app/core/services/notifications/notification.service';
import { SocketService } from 'src/app/core/services/socket/socket.service';
import { NotificationsCenterComponent } from 'src/app/shared/modals/notifications-center/notifications-center.component';

@Component({
  selector: 'dollink-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @ViewChild(NgbDropdown) private notificationsMenu: NgbDropdown | undefined;

  public userType = "";
  public notifications: INotification = {totalUnreadNotifications: 0, totalUnviewedNotifications: 0, items: [], totalNotifications: 0};

  constructor(
    private socketService: SocketService,
    private notificationService: NotificationService,
    private router: Router,
    private modal: NgbModal,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    const user = this.authService.getDecodedToken();
    const companyUserType = user.companyType;
    this.userType = companyUserType.toLowerCase() === 'transportistas' ? 'Transportista' : 'Intermediario';
    this.socketService.currentNotification.subscribe((notification) =>{
      if(notification) {
        this.getNotifications();
      }
    })
    this.getNotifications();
  }

  public onOpenNotificationCenter(): void {
    this.notificationsMenu?.close();
    this.modal.open(NotificationsCenterComponent, {
      size: 'xl',
      centered: true,
    })
  }

  public onNotificationsDropdownChange(toggle: boolean) : void {
    if(!toggle) {
      const unviewedNotificationsIds: number[] = [];
      this.notifications.items.forEach((notification) => { if(!notification.viewed) unviewedNotificationsIds.push(notification.id)});
      if(unviewedNotificationsIds.length > 0 ){
        this.notificationService.markAsViewed(unviewedNotificationsIds)
          .pipe(
            catchError((error) => {
              console.error(error);
              return of(undefined);
            })
          )
          .subscribe((response) => {
            if(response && response.timestamp) {
              this.getNotifications()
            }
          })
      }
    }
  }

  public onMarkNotificationAsRead(e: Event, notification: INotificationItem): void {
    e.preventDefault();
    this.notificationService.markAsRead(notification.id)
      .pipe(
        catchError((error) => {
          console.error(error);
          return of(undefined)
        })
      )
      .subscribe((response) => {
        if(response && response.timestamp) {
          this.notificationsMenu?.close();
          this.getNotifications();
          let url = "";

          if(notification.action === NotificationActionEnum.assignation) {
            url = this.userType === 'Transportista' ? `/trucking/services/assigned/${notification.requestUUID}` : `/requestor/services/assigned/${notification.requestUUID}`;
            this.router.navigate([url]);
          }

          if(notification.action === NotificationActionEnum.negotiation) {
            url = this.userType === 'Transportista' ? `/trucking/services/negotiation/${notification.requestUUID}` : `/requestor/projects/negotiation/${notification.requestUUID}`;
            this.router.navigate([url])
          }

          if(notification.action === NotificationActionEnum.transiting) {
            url = this.userType === 'Transportista' ? `/trucking/services/transit/${notification.requestUUID}` : `/requestor/services/transit/${notification.requestUUID}`;
            this.router.navigate([url])
          }

          if(notification.action === NotificationActionEnum.completed) {
            url = this.userType === 'Transportista' ? `/trucking/services/completed/${notification.requestUUID}` : `/requestor/services/completed/${notification.requestUUID}`;
            this.router.navigate([url])
          }
        }
      })
  }

  private getNotifications(): void {
    this.notificationService.getNotifications()
    .pipe(
      catchError((error: Error) => {
        console.error(error);
        return of(undefined);
      })
    )
    .subscribe((notifications) => {
      if(notifications) {
        if(notifications.totalUnviewedNotifications > 99) {
          notifications.totalUnviewedNotifications = 99;
        }
        this.notifications = notifications;
      }
    })
  }

}
