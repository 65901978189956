import { Component, OnInit, Output, EventEmitter, Input, input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import Swal from 'sweetalert2';

@Component({
  selector: 'dollink-map-draggable-marker',
  templateUrl: './map-draggable-marker.component.html',
  styleUrls: ['./map-draggable-marker.component.scss'],
})
export class MapDraggableMarkerComponent implements OnInit {
  @Input() set value(coordinates: any) {
    this.latitude = coordinates.lat; //: 19.43137826076873;
    this.longitude = coordinates.lng; //: -99.127152503125;
    this.initializerMap();
  }
  @Input() dragabble: boolean = true;

  @Output() setAddress = new EventEmitter<any>();

  public mapObject!: google.maps.Map;

  private googleMapApiKey = environment.googleMapApiKey;
  private loaderMap!: Loader;
  private geoCoder!: google.maps.Geocoder;
  private zoom: number;
  private latitude!: number;
  private longitude!: number;

  constructor() {
    this.zoom = 15;
    this.loaderMap = new Loader({
      apiKey: this.googleMapApiKey,
      version: 'weekly',
      libraries: ['places'],
    });
  }

  ngOnInit(): void {}

  private initializerMap(): void {
    let lat: number = this.latitude;
    let lng: number = this.longitude;
    this.loaderMap
      .load()
      .then(() => {
        let directionsRenderer = new google.maps.DirectionsRenderer();
        this.mapObject = new google.maps.Map(
          document.getElementById('map')! as HTMLElement,
          {
            center: { lat, lng },
            mapTypeControl: false,
            zoom: this.zoom,
          }
        );
        directionsRenderer.setMap(this.mapObject);
        let boundOrigin: google.maps.LatLngBounds;
        boundOrigin = new google.maps.LatLngBounds();
        boundOrigin.extend(new google.maps.LatLng(lat, lng));
        this.geoCoder = new google.maps.Geocoder();
        this.createMarker(boundOrigin.getCenter(), this.mapObject, this.dragabble );
      })
      .catch((e) => {
        console.error('Map error');
      });
  }

  private createMarker(latlng: google.maps.LatLng, map: google.maps.Map, isDraggable: boolean) {
    const marker = new google.maps.Marker({
      position: latlng,
      map: map,
      draggable: isDraggable,
    });
    google.maps.event.addListener(marker, 'dragend', this.getAddress);
  }

  private getAddress = (event: any) => {
    let postcode = '';
    const autocompleteAddress: any = {
      address: '',
      country: '',
      zipcode: '',
      state: '',
      city: '',
      extNumber: '',
      neighborhood: '',
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    };
    this.geoCoder.geocode(
      { location: { lat: event.latLng.lat(), lng: event.latLng.lng() } },
      (results: any, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            for (const component of results[0]
              .address_components as google.maps.GeocoderAddressComponent[]) {
              const componentType = component.types[0];
              switch (componentType) {
                case 'street_number': {
                  autocompleteAddress.extNumber = `${component.long_name}`;
                  break;
                }
                case 'neighborhood': {
                  autocompleteAddress.neighborhood = `${component.long_name}, `;
                  break;
                }
                case 'sublocality_level_1': {
                  autocompleteAddress.neighborhood += `${component.long_name}`;
                  break;
                }
                case 'route': {
                  autocompleteAddress.address = component.short_name;
                  break;
                }
                case 'postal_code': {
                  postcode = `${component.long_name}${postcode}`;
                  autocompleteAddress.zipcode = postcode.replace(/[- ]/g, '');
                  break;
                }
                case 'postal_code_suffix': {
                  postcode = `${postcode}-${component.long_name}`;
                  break;
                }
                case 'locality':
                  autocompleteAddress.city = component.long_name;
                  break;
                case 'administrative_area_level_1': {
                  autocompleteAddress.state = component.long_name;
                  break;
                }
                case 'country':
                  autocompleteAddress.country = component.long_name;
                  break;
              }
            }
            this.setAddress.emit(autocompleteAddress);
          } else {
            window.alert('No results found');
          }
        }
      }
    );
  };
}
