import { Component, OnInit } from '@angular/core';
import { LoadingService } from './core/services/ui/loading/loading.service';
@Component({
  selector: 'dollink-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public globalLoading = false;
  title = 'Dollink-frontend';
  
  constructor() {}

  ngOnInit(): void {
  }
}
