export enum REQUEST_STATUS {
  NEW = 'NEW',
  NEGO = 'NEGO',
  IN_ASSIGNATION = 'INAS',
  ASSIGNED = 'ASSI',
  WAITING_FOR_SERVICE = 'WFSE',
  
  IN_TRANSIT_TO_PICKUP = 'INPI',
  IN_PICKUP_PROCESS = 'PICK',
  IN_TRANSIT_TO_DROPOFF = 'INDR',
  IN_DROPOFF_PROCESS = 'DROP',
  
  FINISHED = 'FINI',
  CANCELLED = 'CANC',
}

export enum PROJECT_STATUS {
  INCOMPLETE = 'INCO',
  NEW = 'NEW',
  NEGO = 'NEGO',
  ASSIGNED = 'ASSI',
  WAITING_FOR_SERVICE = 'WFSE',
  
  IN_TRANSIT_TO_PICKUP = 'INPI',
  IN_PICKUP_PROCESS = 'PICK',
  IN_TRANSIT_TO_DROPOFF = 'INDR',
  IN_DROPOFF_PROCESS = 'DROP',
  
  FINISHED = 'FINI',
  CANCELLED = 'CANC',
}