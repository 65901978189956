<div class="modal-header no-border custom-padding-project">
  <h2 class="mb-0">Lugar de entrega</h2>
</div>
<div class="modal-body custom-padding-project">
  <form [formGroup]="dropoffLocationForm">
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <dollink-autocomplete-address
          [fieldName]="'place'"
          [formLabel]="'Lugar de entrega'"
          (onAddressChanged)="getAddress($event)"
        ></dollink-autocomplete-address>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">Dirección</label>
        <div class="input-group has-validation">
          <input
            type="text"
            class="form-control"
            id="address"
            formControlName="address"
            [class.is-invalid]="submitted && dropoffLocationForm.controls['address'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['address'].hasError('required')">La dirección es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <label for="inputStreetNumber" class="form-label">Número exterior</label>
        <div class="input-group has-validation">
          <input
            id="inputStreetNumber"
            type="text"
            class="form-control"
            formControlName="streetNumber"
            [class.is-invalid]="submitted && dropoffLocationForm.controls['streetNumber'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['streetNumber'].hasError('required')">El número exterior es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <label for="" class="form-label">Número interior</label>
        <input
          type="text"
          class="form-control"
          id="zipCode"
          formControlName="suiteNumber"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputZipCode" class="form-label">Código Postal</label>
        <div class="input-group has-validation">
          <input
            id="inputZipCode"
            type="text"
            class="form-control"
            id="zipCode"
            formControlName="zipcode"
            [class.is-invalid]="submitted && dropoffLocationForm.controls['zipcode'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['zipcode'].hasError('required')">El código postal es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputCity" class="form-label">Ciudad</label>
        <div class="input-group has-validation">
          <input
            id="inputCity"
            type="text"
            class="form-control"
            formControlName="city"
            [class.is-invalid]="submitted && dropoffLocationForm.controls['city'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['city'].hasError('required')">La ciudad es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="selectState" class="form-label">Estado</label>
        <div class="input-group has-validation">
          <select id="selectState" class="form-select" formControlName="state" [class.is-invalid]="submitted && dropoffLocationForm.controls['state'].errors">
            <option *ngFor="let state of states" value="{{ state.name }}" >
              {{ state.name }}
            </option>
          </select>
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['state'].hasError('required')">El estado es requerido</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">Fecha de entrega</label>
        <div class="input-group has-validation">
          <input
            type="text"
            (click)="pickupDate.toggle()"
            [minDate]="{
              year: dueDateLimitYearOrigin,
              month: dueDateLimitMonthOrigin,
              day: dueDateLimitDayOrigin
            }"
            name="date"
            ngbDatepicker
            #pickupDate="ngbDatepicker"
            class="form-control"
            formControlName="date"
            (change)="onValidDates()"
            [class.is-invalid]="submitted && (dropoffLocationForm.controls['date'].errors || dropoffLocationForm.value.date < this.pickupLocation.date)"
          />
          <span class="input-group-text cursor-pointer" id="validationTooltipUsernamePrepend" (click)="pickupDate.toggle()"><i class="bi bi-calendar3"></i></span>
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['date'].hasError('required')">La fecha de entrega es requerida</p>
            <p *ngIf="this.dropoffLocationForm.value.date < this.pickupLocation.date">La fecha de entrega no puede ser antes de la fecha de recolección</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">
          Hora estimada de entrega <small class="text-muted">Opcional</small>
        </label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            formControlName="time"
            [imask]="timeMask"
          />
          <span class="input-group-text">
            <i class="bi bi-clock"></i>
          </span>
        </div>
        <small class="text-muted">Hora de entrega en formato de 24 hrs</small>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-between flex-nowrap align-items-center no-border custom-padding-project-actions">
  <div class="w-50" *ngIf="showAlertWarningPickupDate">
    <div class="alert alert-warning mb-1" role="alert">
      La fecha de entrega es similar a la fecha de recolección
    </div>
  </div>
  <div class="d-flex justify-content-end" [ngClass]="showAlertWarningPickupDate ? 'w-50' : 'w-100'">
    <button type="button" class="btn btn-cancel btn-outline me-2" (click)="onClose()">Cancelar</button>
    <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="onSave()">
      <span class="spinner-border spinner-border-sm me-2" aria-hidden="true" *ngIf="isSaving"></span>
      <span role="status">{{updateButtonText}}</span>
    </button>
  </div>
</div>