<div class="pickup mb-5">
  <h2 class="mb-3">Punto de recolección</h2>
  <form [formGroup]="pickupLocationForm">
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <dollink-autocomplete-address
          [fieldName]="'place'"
          [formLabel]="'Lugar de recolección'"
          (onAddressChanged)="getPickupAddress($event)"
        ></dollink-autocomplete-address>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">Dirección</label>
        <div class="input-group has-validation">
          <input
            type="text"
            class="form-control"
            id="address"
            formControlName="address"
            [class.is-invalid]="submittedPickupForm && pickupLocationForm.controls['address'].errors"
          />
          <div 
            *ngIf="submittedPickupForm"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['address'].hasError('required')">La dirección es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <label for="inputStreetNumber" class="form-label">Número exterior</label>
        <div class="input-group has-validation">
          <input
            id="inputStreetNumber"
            type="text"
            class="form-control"
            formControlName="streetNumber"
            [class.is-invalid]="submittedPickupForm && pickupLocationForm.controls['streetNumber'].errors"
          />
          <div 
            *ngIf="submittedPickupForm"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['streetNumber'].hasError('required')">El número exterior es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <label for="" class="form-label">Número interior</label>
        <input
          type="text"
          class="form-control"
          id="zipCode"
          formControlName="suiteNumber"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputZipCode" class="form-label">Código Postal</label>
        <div class="input-group has-validation">
          <input
            id="inputZipCode"
            type="text"
            class="form-control"
            id="zipCode"
            formControlName="zipcode"
            [class.is-invalid]="submittedPickupForm && pickupLocationForm.controls['zipcode'].errors"
          />
          <div 
            *ngIf="submittedPickupForm"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['zipcode'].hasError('required')">El código postal es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputCity" class="form-label">Ciudad</label>
        <div class="input-group has-validation">
          <input
            id="inputCity"
            type="text"
            class="form-control"
            formControlName="city"
            [class.is-invalid]="submittedPickupForm && pickupLocationForm.controls['city'].errors"
          />
          <div 
            *ngIf="submittedPickupForm"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['city'].hasError('required')">La ciudad es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="selectState" class="form-label">Estado</label>
        <div class="input-group has-validation">
          <select id="selectState" class="form-select" formControlName="state" [class.is-invalid]="submittedPickupForm && pickupLocationForm.controls['state'].errors">
            <option *ngFor="let state of states" value="{{ state.name }}" >
              {{ state.name }}
            </option>
          </select>
          <div 
            *ngIf="submittedPickupForm"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['state'].hasError('required')">El estado es requerido</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">Fecha de recolección</label>
        <div class="input-group has-validation">
          <input
            type="text"
            (click)="pickupDate.toggle()"
            [minDate]="{
              year: dueDateLimitYearOrigin,
              month: dueDateLimitMonthOrigin,
              day: dueDateLimitDayOrigin
            }"
            name="date"
            ngbDatepicker
            #pickupDate="ngbDatepicker"
            class="form-control"
            formControlName="date"
            [class.is-invalid]="submittedPickupForm && pickupLocationForm.controls['date'].errors"
          />
          <span class="input-group-text cursor-pointer" id="validationTooltipUsernamePrepend" (click)="pickupDate.toggle()"><i class="bi bi-calendar3"></i></span>
          <div 
            *ngIf="submittedPickupForm"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['date'].hasError('required')">La fecha de recolección es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">
          Hora estimada de recolección <small class="text-muted">Opcional</small>
        </label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            formControlName="time"
            [imask]="timeMask"
          />
          <span class="input-group-text">
            <i class="bi bi-clock"></i>
          </span>
        </div>
        <small class="text-muted">Hora de recolección en formato de 24 hrs</small>
      </div>
    </div>
  </form>
</div>
<div class="dropoff" [ngClass]="errors.length === 0 ? 'mb-5' : 'mb-3'" *ngIf="pickupLocationForm.valid">
  <h2 class="mb-3">Punto de entrega</h2>
  <form [formGroup]="dropoffLocationForm">
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <dollink-autocomplete-address
          [fieldName]="'place'"
          [formLabel]="'Lugar de entrega'"
          (onAddressChanged)="getDropoffAddress($event)"
        ></dollink-autocomplete-address>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">Dirección</label>
        <div class="input-group has-validation">
          <input
            type="text"
            class="form-control"
            id="address"
            formControlName="address"
            [class.is-invalid]="submittedDropoffForm && dropoffLocationForm.controls['address'].errors"
          />
          <div 
            *ngIf="submittedDropoffForm"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['address'].hasError('required')">La dirección es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <label for="inputStreetNumber" class="form-label">Número exterior</label>
        <div class="input-group has-validation">
          <input
            id="inputStreetNumber"
            type="text"
            class="form-control"
            formControlName="streetNumber"
            [class.is-invalid]="submittedDropoffForm && dropoffLocationForm.controls['streetNumber'].errors"
          />
          <div 
            *ngIf="submittedDropoffForm"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['streetNumber'].hasError('required')">El número exterior es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <label for="" class="form-label">Número interior</label>
        <input
          type="text"
          class="form-control"
          id="zipCode"
          formControlName="suiteNumber"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputZipCode" class="form-label">Código Postal</label>
        <div class="input-group has-validation">
          <input
            id="inputZipCode"
            type="text"
            class="form-control"
            id="zipCode"
            formControlName="zipcode"
            [class.is-invalid]="submittedDropoffForm && dropoffLocationForm.controls['zipcode'].errors"
          />
          <div 
            *ngIf="submittedDropoffForm"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['zipcode'].hasError('required')">El código postal es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputCity" class="form-label">Ciudad</label>
        <div class="input-group has-validation">
          <input
            id="inputCity"
            type="text"
            class="form-control"
            formControlName="city"
            [class.is-invalid]="submittedDropoffForm && dropoffLocationForm.controls['city'].errors"
          />
          <div 
            *ngIf="submittedDropoffForm"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['city'].hasError('required')">La ciudad es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="selectState" class="form-label">Estado</label>
        <div class="input-group has-validation">
          <select id="selectState" class="form-select" formControlName="state" [class.is-invalid]="submittedDropoffForm && dropoffLocationForm.controls['state'].errors">
            <option *ngFor="let state of states" value="{{ state.name }}" >
              {{ state.name }}
            </option>
          </select>
          <div 
            *ngIf="submittedDropoffForm"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['state'].hasError('required')">El estado es requerido</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">Fecha de entrega</label>
        <div class="input-group has-validation">
          <input
            type="text"
            (click)="dropoffDatePicker.toggle()"
            [minDate]="{
              year: dueDateLimitYearOriginDropoff,
              month: dueDateLimitMonthOriginDropoff,
              day: dueDateLimitDayOriginDropoff
            }"
            name="date"
            ngbDatepicker
            #dropoffDatePicker="ngbDatepicker"
            class="form-control"
            formControlName="date"
            [class.is-invalid]="submittedDropoffForm && dropoffLocationForm.controls['date'].errors"
          />
          <span class="input-group-text cursor-pointer" id="validationTooltipUsernamePrepend" (click)="dropoffDatePicker.toggle()"><i class="bi bi-calendar3"></i></span>
          <div 
            *ngIf="submittedDropoffForm"
            class="invalid-feedback">
            <p *ngIf="dropoffLocationForm.controls['date'].hasError('required')">La fecha de entrega es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">
          Hora estimada de entrega <small class="text-muted">Opcional</small>
        </label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            formControlName="time"
            [imask]="timeMask"
          />
          <span class="input-group-text">
            <i class="bi bi-clock"></i>
          </span>
        </div>
        <small class="text-muted">Hora de entrega en formato de 24 hrs</small>
      </div>
    </div>
  </form>
</div>
<div class="errors" *ngIf="errors.length > 0" [class.mb-5]="errors.length > 0">
  @for (error of errors; track $index) {
    <div class="alert {{error.type}}" role="alert">
      {{error.message}}
    </div>
  }
</div>
<div class="footer-box justify-content-end" >
  <div class="actions">
    <button class="btn btn-danger btn-outline me-2">Cancelar</button>
    <button class="btn btn-primary me-2" (click)="onRedirectToCargos()">Siguiente</button>
  </div>
</div>