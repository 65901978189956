export enum TRUCK_TYPE {
  COMPLETE_UNITS = "Unidad Completa",
  TRAILER = "Trailer",
  TRUCK = "Camion"
}

export enum CARGO_UNIT_TYPES {
  TRUCK = 'TRUCK',
  COMPLETE = 'COMPLETE',
  TRAILER = 'TRAILER'
}