
export interface INavigation {
  menu: INavigationMenu[];
  subMenu: INavigationMenuItems[];
}

export interface INavigationMenu {
  icon: string;
  label: string;
  isVisible: boolean;
  path: string;
  isGroup: boolean;
  collapsed: boolean;
  class?: string;
  items?: INavigationMenuItems[];
  id: string;
}

export interface INavigationMenuItems {
  icon: string;
  label: string;
  isVisible: boolean;
  class?: string;
  path: string;
  id: string;
}

// Requestor Navigation
export const requestorNavigationItems: INavigation = {
  menu: [
    {
      icon: 'dashboard',
      label: 'Inicio',
      path: 'requestor/home',
      isVisible: true,
      isGroup: false,
      collapsed: false,
      id: 'requestor-home',
    },
    {
      icon: '',
      label: 'Proyectos',
      path: '',
      isVisible: true,
      isGroup: true,
      collapsed: false,
      id: 'requestor-projects',
      items: [
        {
          icon: 'add',
          label: 'Nuevo Proyecto',
          isVisible: true,
          path: '',
          class: 'item-link-cta',
          id: 'new-project',
        },
        {
          icon: 'edit_document',
          label: 'Incompletos',
          isVisible: true,
          path: 'requestor/projects/unfinished',
          id: 'project-incomplete',
        },
        {
          icon: 'gavel',
          label: 'En Negociación',
          isVisible: true,
          path: 'requestor/projects/negotiation',
          id: 'project-negotiation',
        },
        {
          icon: 'location_searching',
          label: 'Por Asignar',
          isVisible: true,
          path: 'requestor/projects/assign',
          id: 'project-to-assign',
        },
        {
          icon: 'contract_delete',
          label: 'Cancelados',
          isVisible: true,
          path: 'requestor/projects/canceled',
          id: 'project-canceled',
        }
      ]
    },
    {
      icon: '',
      label: 'Servicios',
      path: '',
      isVisible: true,
      isGroup: true,
      collapsed: false,
      id: 'requestor-services',
      items: [
        {
          icon: 'task',
          label: 'Asignados',
          isVisible: true,
          path: 'requestor/services/assigned',
          id: 'assigned-services',
        },
        {
          icon: 'share_location',
          label: 'En Transito',
          isVisible: true,
          path: 'requestor/services/transit',
          id: 'in-transit-services',
        },
        {
          icon: 'task_alt',
          label: 'Finalizados',
          isVisible: true,
          path: 'requestor/services/completed',
          id: 'completed-services',
        },
      ]
    },
    {
      icon: 'local_shipping',
      label: 'Mis Transportistas',
      path: 'requestor/truckings',
      isVisible: true,
      isGroup: false,
      collapsed: false,
      id: 'requestor-truckings',
    },
  ],
  subMenu: [
    {
      icon: 'group',
      label: 'Usuarios',
      path: 'requestor/users',
      isVisible: true,
      id: 'requestor-users',
    },
    {
      icon: 'settings',
      label: 'Configuración',
      path: 'requestor/account/company',
      isVisible: true,
      id: 'requestor-settings',
    },
  ]
}

// Trucking Navigation
export const truckingNavigationItems: INavigation = {
  menu: [
    {
      icon: 'dashboard',
      label: 'Inicio',
      path: 'trucking/home',
      isVisible: true,
      isGroup: false,
      collapsed: false,
      id: 'trucking-dashboard',
    },
    {
      icon: '',
      label: 'Solicitudes',
      path: '',
      isVisible: true,
      isGroup: true,
      collapsed: false,
      id: 'trucking-requests',
      items: [
        {
          icon: 'add',
          label: 'Nueva Solicitud',
          isVisible: true,
          path: '',
          class: 'item-link-cta',
          id: 'new-trucking-requests',
        },
        {
          icon: 'edit_document',
          label: 'Incompletas',
          isVisible: true,
          path: 'trucking/requests/incompleted',
          id: 'trucking-requests-incompleted',
        },
      ]
    },
    {
      icon: '',
      label: 'Mis Viajes',
      path: '',
      isVisible: true,
      isGroup: true,
      collapsed: false,
      id: 'trucking-services',
      items: [
        {
          icon: 'gavel',
          label: 'En Negociación',
          isVisible: true,
          path: 'trucking/services/negotiation',
          id: 'services-in-negotiation',
        },
        {
          icon: 'task',
          label: 'Asignados',
          isVisible: true,
          path: 'trucking/services/assigned',
          id: 'services-assigned',
        },
        {
          icon: 'share_location',
          label: 'En Transito',
          isVisible: true,
          path: 'trucking/services/transit',
          id: 'services-in-transit',
        },
        {
          icon: 'task_alt',
          label: 'Finalizados',
          isVisible: true,
          path: 'trucking/services/completed',
          id: 'completed-services',
        },
      ]
    },
    {
      icon: 'storefront',
      label: 'Marketplace',
      path: 'trucking/marketplace',
      isVisible: true,
      isGroup: false,
      collapsed: false,
      id: 'trucking-marketplace',
    },
  ],
  subMenu: [
    {
      icon: 'local_shipping',
      label: 'Unidades',
      path: 'trucking/cargo-units',
      isVisible: true,
      id: 'trucking-units',
    },
    {
      icon: 'group',
      label: 'Usuarios',
      path: 'trucking/users',
      isVisible: true,
      id: 'trucking-users',
    },
    {
      icon: 'settings',
      label: 'Configuración',
      path: 'trucking/account/company',
      isVisible: true,
      id: 'trucking-settings',
    },
  ]
}

