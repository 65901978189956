import * as dayjs from "dayjs";

export function plainObjectData(data: any) {
  Object.assign(
    {}, 
    ...function _flatten(o): any { 
      return [].concat(...Object.keys(o)
        .map(k => 
          typeof o[k] === 'object' ?
            _flatten(o[k]) : 
            ({[k]: o[k]})
        )
      );
    }(data)
  )
}

/**
 * Convert formGroup to formData
 */
export function objectToFormData(data: any) {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return formData;
}

/**
 * Conversion to cubic meters
 */
export function getCubicMeters(length: number, width: number, height: number) {
  const lengthWithoutCommas = Number(length.toString().replaceAll(',', ''));
  const widthWithoutCommas = Number(width.toString().replaceAll(',', ''));
  const heighthWithoutCommas = Number(height.toString().replaceAll(',', ''));
  return (lengthWithoutCommas * widthWithoutCommas * heighthWithoutCommas).toFixed(2);
}

/**
 * Clean objects of null values
 */
export function removeBlankPropertiesFromObject(object: any) {
  for (let propName in object) {
    if(object[propName] === null || object[propName] === undefined || object[propName] === "") {
      delete object[propName];
    }
  }
  return object;
}

/**
 * Remove and parse numbers with commas
 */
export function parseStringToNumber(string: string): number {
  return parseFloat(string.replaceAll(',', ''));
}

/**
 * Return age based on birthday
 */
export function convertBirthdayToAge(birthday: string): string{
  const now = dayjs(Date.now());
  return (now.diff(birthday, 'year')).toString();
}