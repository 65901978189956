<div class="document" *ngFor="let item of documents; let i = index;">
  <div class="form-group document-upload">
    <div class="col-12 my-2 d-flex ">
      <div class="upload-button d-flex ">
        <div class="icon">
          <i class="bi bi-file-earmark-text"></i>
        </div>
        <div class="row file-info" >
          <ng-container *ngIf="item.document">
            <label>{{item.name}}.pdf</label>
            <span>{{item.document.createdDate | humanizeDate}}</span>
          </ng-container>
          <ng-container *ngIf="!item.document">
            <label>Adjuntar archivo de {{item.name}}</label>
            <span class="text-danger" *ngIf="item.isRequired">Archivo requerido</span>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="file-error" id="input-error-{{i}}">
      <span>Puedes adjuntar solo un archivo</span>
    </div>
  </div>
  <div class="document-actions" >
    <div>
      <label for="file-input{{i}}"><i class="bi bi-cloud-arrow-up-fill me-2"></i></label>
      <input id="file-input{{i}}" type="file" (change)="handleFileInput($event, item, i)" multiple="multiple" accept="application/pdf">
    </div>
    <div *ngIf="item.document">
      <i class="bi bi-cloud-arrow-down-fill" (click)="downloadDocument(item)"></i>
      <i class="bi bi-trash-fill remove-file" (click)="removeFile(item)"></i>
    </div>
  </div>
</div>