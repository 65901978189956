import { Component, OnInit } from '@angular/core';
import { LegalService } from 'src/app/core/services/legal/legal.service';
import {
  objectToFormData,
  removeBlankPropertiesFromObject,
} from 'src/app/core/helpers/global.helper';
import Swal from 'sweetalert2';
import { catchError, of } from 'rxjs';
import { LoadingService } from 'src/app/core/services/ui/loading/loading.service';

@Component({
  selector: 'dollink-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss'],
})
export class DocumentUploaderComponent implements OnInit {
  
  public documents!: any;

  constructor(
    private legalService: LegalService,
    private loading: LoadingService,
  ) {
    this.getDocuments();
  }

  ngOnInit(): void {}

  public handleFileInput(event: any, item: any, index: number): void {
    let documentId: string = 'input-error-' + index;
    if (event.target.files.length === 1) {
      this.loading.setLoading(true);
      (<HTMLInputElement>document.getElementById(documentId)).style.display =
        'none';
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const id: number = +item.id;
        const data = { document: file, typeId: id };
        const sanitizedObject = removeBlankPropertiesFromObject(data);
        const formData: FormData = objectToFormData(sanitizedObject);
        this.legalService.saveDocument(formData)
          .pipe(
            catchError((error) => {
              return of(undefined);
            })
          )
          .subscribe((response) => {
            if(response) {
              this.getDocuments();
              Swal.fire({
                title: 'Excelente 🥳',
                text: `El documento de ${item.name} se ha guardado de manera éxitosa.`,
                allowEscapeKey: false,
                timer: 3000,
                showConfirmButton: false,
              }).then(() => {
                this.loading.setLoading(false);
              });
            }
          })
      }
    } else {
      (<HTMLInputElement>document.getElementById(documentId)).style.display =
        'inline-block';
    }
  }

  public downloadDocument(item: any): void {
    const fileName = this.extractS3Path(item.document.path);
    const link = document.createElement('a');
    link.href = item.document.path;
    link.target = '_blank';
    link.download = fileName;
    link.click();
  }

  private extractS3Path(url: string): string {
    const pattern = /\/\/.*\/(.*?)\?/;
    const matcher = pattern.exec(url);
    if (matcher) {
      return matcher[1];
    } else {
      return "No se encontró el patrón en la URL";
    }
  }
  
  public removeFile(item: any): void {
    Swal.fire({
      title: `¿Eliminar el documento de ${item.name}?`,
      text: `Esta acción no se puede deshacer`,
      icon: 'warning',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonColor: '#EB5857',
      confirmButtonText: `Eliminar documento`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      customClass: {
        cancelButton: 'btn btn-link',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.legalService.deleteDocument(item.document.id)
          .pipe(
            catchError((error) => {
              return of(undefined);
            })
          )
          .subscribe((response) => {
            if(response) {
              Swal.fire({
                title: ` ${item.name} eliminado!`,
                icon: 'success',
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#509760',
              }).then(() => {
                this.getDocuments();
              });
            }
          })
      }
    });
  }

  private getDocuments(): void {
    this.legalService.getDocuments()
      .pipe(
        catchError((error) => {
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if(response) {
          this.documents = response;
        }
      })
  }
}
