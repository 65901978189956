import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CompanyService } from 'src/app/core/services/company/company.service';

@Component({
  selector: 'dollink-profile-badge',
  templateUrl: './profile-badge.component.html',
  styleUrls: ['./profile-badge.component.scss']
})
export class ProfileBadgeComponent implements OnInit {

  public user: any;
  public userType: string = "";
  public userNameInitials: string = "";
  public companyImage: string | undefined | null;
  public companyName: string | undefined;
  public company: {image: string | undefined | null; name: string | undefined | null; } | undefined;
  public accountURL = "";
  public companyAccountURL = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.getUserRole();
    this.getCompanyInfo();
  }

  public onNavigateTo(path: string): void {
    if(path === 'logout') {
      this.authService.logout();
      this.router.navigate(['/auth/sign-in']);
    } else {
      this.router.navigate([path]);
    }
  }

  private getUserRole(): void {
    this.user = this.authService.getDecodedToken();
    const companyUserType = this.user.companyType;
    this.userNameInitials = this.user.firstName.substring(0, 1) + this.user.lastName.split(' ')[0].substring(0, 1);
    this.userType = companyUserType.toLowerCase() === 'transportistas' ? 'Transportista' : 'Solicitante';
    this.accountURL = companyUserType.toLowerCase() === 'transportistas' ? `/trucking/users/${this.user.uuid}/account` : `/requestor/users/${this.user.uuid}/account`;
    this.companyAccountURL = companyUserType.toLowerCase() === 'transportistas' ? `/trucking/account/company` : `/requestor/account/company`;
  }

  private getCompanyInfo(): void {
    this.companyService.getById().subscribe((company) => {
      this.company = {
        image : company.image,
        name: company.name
      }
    })
  }

}
