import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { INotification } from '../../interfaces/notification.interface';
import { IPaginationResponse } from '../../interfaces/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  
  private readonly api = environment.api;

  constructor(private http: HttpClient) {}

  public getNotifications(): Observable<INotification> {
    return this.http.get<INotification>(`${this.api}/notification`);
  }

  public getAllNotifications(): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}/notification/all`);
  }

  public markAsViewed(
    notificationIdsList: number[]
  ): Observable<{ details: string; message: string; timestamp: string }> {
    return this.http.post<{ details: string; message: string; timestamp: string }>(
      `${this.api}/notification/mark/viewed`,
      { notificationIds: notificationIdsList }
    );
  }

  public markAsRead(notificationId: number): Observable<{ details: string; message: string; timestamp: string }> {
    return this.http.post<{ details: '', message: 'Success', timestamp: '3243' }>(`${this.api}/notification/mark/read/${notificationId}`, {notificationId: notificationId});
  }
}
