import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IUser } from '../../interfaces/user.interface';
import { IPaginationResponse } from '../../interfaces/pagination.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly api = env.api;

  constructor(
    private http: HttpClient,
  ) { }

  // get all users from a company
  getAll(): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}/user/all`);
  }

  // get user by id
  getById(id?: string): Observable<IUser> {
    const url = id ? `/user/${id}` : '/user';
    return this.http.get<IUser>(`${this.api}${url}`);
  }

  create(user: FormData): Observable<IUser> {
    return this.http.post<IUser>(`${this.api}/user/create`, user);
  }

  update(data: FormData, id?: string): Observable<HttpResponse<object>> {
    const url = id ? `/user/${id}` : '/user';
    return this.http.put<HttpResponse<object>>(`${this.api}${url}`, data, {observe: 'response'});
  }

  delete(userId: string): Observable<HttpResponse<object>> {
    return this.http.delete<HttpResponse<object>>(`${this.api}/user/${userId}`, {observe: 'response'});
  }

  verifyUser(token: string): Observable<HttpClient> {
    return this.http.post<HttpClient>(`${this.api}/user/verified?token=${token}`, {});
  }

  changePassword(data: {oldPassword: string, newPassword: string}): Observable<any> {
    return this.http.post<any>(`${this.api}/user/update-password`, data);
  }

  recoverUserPassword(id: string, password: string): Observable<HttpResponse<object>> {
    return this.http.patch<HttpResponse<object>>(`${this.api}/user/${id}/password`, {password: password}, {observe: 'response'});
  }

  recoverPassword(email: string): Observable<HttpClient> {
    const newEmail = email.replace('+', '%2B');
    return this.http.post<HttpClient>(`${this.api}/user/resetPassword?email=${newEmail}`, {});
  }

  updatePassword(password: string, token: string): Observable<HttpClient> {
    return this.http.put<HttpClient>(`${this.api}/user/resetPassword`, {password: password, token: token})
  }

  searchUser(query: string): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}/user/all${query}`);
  }
}
