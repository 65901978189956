import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrucksService } from '../../../core/services/trucks/trucks.service';
import { catchError, of } from 'rxjs';
import { IAvailableCargoUnitsByService } from 'src/app/core/interfaces/cargo-unit.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultipleUnitAssignationModalComponent } from '../../modals/multiple-unit-assignation-modal/multiple-unit-assignation-modal.component';
import { CARGO_UNIT_TYPES } from 'src/app/core/enums/truck.types.enums';

@Component({
  selector: 'dollink-unit-assignation',
  templateUrl: './unit-assignation.component.html',
  styleUrls: ['./unit-assignation.component.scss']
})
export class UnitAssignationComponent implements OnInit {

  @Input() cargoUnitId: string[] = [];
  @Input() requestId = "";
  @Output() units = new EventEmitter<any>(); 


  public userCompanyType: string = '';
  public trucks: IAvailableCargoUnitsByService[] = [];
  public truckInfo: IAvailableCargoUnitsByService | undefined;
  public secondCargoUnit: IAvailableCargoUnitsByService | undefined;

  constructor(
    private unitsService: TrucksService,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getCargoUnits();
  }
  
  public selectUnit(e: any): void {
    const unitId = Number(e.target.value);
    this.truckInfo = this.trucks.find((item) => item.cargoUnit.id === unitId);
    if(this.truckInfo) {
      if(
        this.truckInfo?.cargoUnit.trailerType.type === CARGO_UNIT_TYPES.TRAILER || 
        this.truckInfo?.cargoUnit.trailerType.type === CARGO_UNIT_TYPES.TRUCK
      ) {
        this.showMultipleAssignationModal(this.truckInfo);
      } else {
        this.secondCargoUnit = undefined;
        this.units.emit([this.truckInfo]);
      }
    } else {
      this.secondCargoUnit = undefined;
      this.units.emit([]);
    }
  }

  private showMultipleAssignationModal(truckInfo: IAvailableCargoUnitsByService): void {
    
    const modalRef = this.modal.open(MultipleUnitAssignationModalComponent, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'multiple-cargo-unit-assignation'
    });

    modalRef.componentInstance.currentCargoUnit = truckInfo;
    modalRef.componentInstance.serviceId = this.requestId;

    modalRef.closed.subscribe((response) => {
      if(response.cargoUnit1 && response.cargoUnit2) {
        this.secondCargoUnit = response.cargoUnit2;
        const units = [response.cargoUnit1, response.cargoUnit2];
        this.units.emit(units);
      }
    })
  }

  private getCargoUnits(): void {
    this.unitsService.getAvailableUnitsByRequest(this.requestId)
      .pipe(
        catchError((error: any) => {
          return of(undefined)
        })
      )
      .subscribe((trucks) => {
        if(trucks) {
          this.trucks = trucks;
          if(this.cargoUnitId.length > 0) {
            this.truckInfo = this.trucks.find((item) => item.cargoUnit.uuid === this.cargoUnitId[0]);
          }
          if(this.cargoUnitId.length === 2) {
            this.secondCargoUnit = this.trucks.find((item) => item.cargoUnit.uuid === this.cargoUnitId[1])
          }
        }
      })
  }
}
