import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatService } from '../../../core/services/chat/chat.service';
import { SocketService } from '../../../core/services/socket/socket.service';
import { StorageService } from '../../../core/services/storage/storage.service';
import { IChatConfig, IReceivedMessages } from '../../../core/interfaces/chat.interface';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, catchError, of } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'dollink-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('messageInput') messageInput: ElementRef<HTMLTextAreaElement> | undefined;

  public chatForm!: FormGroup;
  public chats: IReceivedMessages[] = []; 
  public companySenderId: number = 0;
  public chatConfig!: IChatConfig;

  private chatSubscription = new Subscription();
  private chatRequestSubscription = new Subscription();
  private currentChatSubscription = new Subscription();

  @Input() set chatConfiguration(configuration: IChatConfig) {
    this.chatConfig = configuration;
  }
  @Input() readonly = false;

  constructor(
    private fb: FormBuilder,
    private chatService: ChatService,
    private storageService: StorageService,
    private socketService: SocketService,
    private activeCanvas: NgbActiveOffcanvas,
  ) {

    //if this request get new messages
    this.currentChatSubscription = this.socketService.currentMessage.subscribe((body) => {
      this.getMessages();
    })

    //get sent message
    this.chatSubscription = this.socketService.newSentMessage.subscribe((body) => {
      this.getMessages();
    })
  }

  ngOnInit(): void {
    this.createForm();
    this.socketService.connectChannelChat(this.chatConfig.requestUUID);
    if(this.chatConfig){
      this.getMessages();
    }
  }
  
  ngOnDestroy(): void {
    if(this.chatSubscription) this.chatSubscription.unsubscribe();
    if(this.chatRequestSubscription) this.chatRequestSubscription.unsubscribe()
    if(this.currentChatSubscription) this.currentChatSubscription.unsubscribe()
  }
  
  ngAfterViewInit(): void {
    this.messageInput?.nativeElement.focus();
  }

  public onEnterKeyPress(event: any){
    event.preventDefault();
    this.send();
  }

  public send(): void {
    if (this.chatForm.valid) {
      const message = this.chatForm.value.message;
      const data = {
        ...this.chatConfig,
        message: message,
      };
      this.socketService.send(data);
      this.createForm();
    }
  }

  public onCloseCanvas(): void {
    this.activeCanvas.close();
  }

  private scrollToBottomChat(): void {
    const chatList = <HTMLElement>document.getElementById('chat-list');
    const chatListWrapper = <HTMLElement>(
      document.getElementById('offcanvas-body')
    );
    if (chatList && chatList.getElementsByTagName('div').length > 1) {
      chatListWrapper.scrollTop = chatList.scrollHeight;
    }
  }

  private getMessages(): void {
    if(this.chatConfig){
      this.companySenderId = this.storageService.get('dollink_user_company_id');
      this.chatRequestSubscription = this.chatService
        .getMessages(
          this.chatConfig.requestorId,
          this.chatConfig.truckingId,
          this.chatConfig.requestId
        )
        .pipe(
          catchError((error) => {
            Swal.fire({
              title: error.title,
              text: error.description,
              allowEscapeKey: false,
              showConfirmButton: true,
              confirmButtonColor:  "#509760",
            });
            return of(undefined);
          })
        )
        .subscribe((response) => {
          if(response) {
            this.chats = response;
            this.socketService.isMessageViewed.next(true);
            setTimeout(() => {
              this.scrollToBottomChat();
            }, 1);
          }
        })
    }
  }

  private createForm(): void {
    this.chatForm = this.fb.group({
      message: [null, Validators.required],
    });
  }
}
