import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthRoutingModule } from "./auth-routing.module";
import { AuthComponent } from "./auth.component";
import { LoginComponent } from "./pages/login/login.component";
import { RecoverPasswordComponent } from "./pages/recover-password/recover-password.component";
import { RegisterComponent } from "./pages/register/register.component";
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { SelectAccountTypeModalComponent } from './components/select-account-type-modal/select-account-type-modal.component';
import { IMaskModule } from 'angular-imask';

const COMPONENTS = [
  AuthComponent,
  LoginComponent,
  RegisterComponent,
  RecoverPasswordComponent,
  NewPasswordComponent,
];
@NgModule({
  declarations: [ COMPONENTS, SelectAccountTypeModalComponent ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    IMaskModule
  ],
  providers: [
    NgbActiveModal
  ]
})

export class AuthModule {}