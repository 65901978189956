<div class="chat-notifications" ngbDropdown (openChange)="onNotificationsDropdownChange($event)" display="dynamic" placement="bottom-end" container="body" style="right: -1px;">
  <div class="chat-notifications-toggle" ngbDropdownToggle>
    <figure *ngIf="newChats > 0;">
      <img src="/assets/svg/notifications-chat-fill.svg" alt="Notifications Fill Icon">
    </figure>
    <figure *ngIf="newChats === 0;">
      <img src="/assets/svg/notifications-chat-empty.svg" alt="Notifications Empty Icon">
    </figure>
    <span *ngIf="newChats > 0" class="chat-notifications-badge" [ngStyle]="{'right': newChats > 9 ? '-20px' : '-13px' }">{{newChats}}</span>
  </div>
  <div #notificationsChatMenu ngbDropdownMenu style="width: 400px !important; box-shadow: 0 2px 8px 0 rgba(0,0,0,0.12); border: none;" class="mt-3">
    <div class="chat-notifications-header d-flex align-items-center justify-content-between">
      <span>
        Mensajes
      </span>
      <span class="material-symbols-outlined">
        chat
      </span>
    </div>
    <div class="chat-notifications-menu" *ngIf="newChats > 0; else noNotificationsTemplate" >
      <ul class="chat-notifications-list">
        <li *ngFor="let chat of receivedMessages" (click)="onRedirectToChat(chat)">
          {{chat.userSender.firstName}} de {{chat.companySender.name}} te envio un mensaje
          <span>{{chat.createdDate | humanizeDate}}</span>
        </li>
      </ul>
    </div>
    <ng-template #noNotificationsTemplate>
      <div class="chat-notifications-empty">
        Estas al día, no tienes mensajes para leer 🎉
      </div>
    </ng-template>
  </div>
</div>