<section class="auth">
  <div class="auth-banner"></div>
  <div class="auth-form">
    <div class="container d-flex justify-content-center">
      <div class="auth-form-wrapper">
        <div class="auth-form-wrapper-title mb-3">
          <h1>
            Recuperar contraseña
          </h1>
        </div>
        <form [formGroup]="recoverPasswordForm" (submit)="recoverPassword($event, recoverPasswordForm)">
          <div class="mb-3">
            <label for="emailInput" class="form-label">Correo</label>
            <div class="input-group has-validation">
              <input [class.is-invalid]="recoverPasswordForm.get('email')!.invalid && recoverPasswordForm.get('email')!.touched" type="email" class="form-control" id="emailInput" formControlName="email">
              <div class="invalid-feedback">
                Porfavor, ingresa un correo valido.
              </div>
            </div>
          </div>
          <div class="mb-5 d-grid">
            <button type="submit" class="btn btn-primary btn-block" >Recuperar contraseña</button>
          </div>
        </form>
        <div class="auth-form-wrapper-footer d-flex flex-column align-items-center">
          <span class="text-center mb-2">
            ¿Ya tienes cuenta? <a [routerLink]="['/auth/sign-in']">Inicia Sesión</a> o <a [routerLink]="['/auth/signup']">Registrate</a>
          </span>
          <span class="text-center text-muted">
            ®2023 DOLLINK. Todos los derechos reservados.
          </span>
        </div>
      </div>
    </div>
  </div>
</section>
