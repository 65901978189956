import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dollink-select-account-type-modal',
  templateUrl: './select-account-type-modal.component.html',
  styleUrls: ['./select-account-type-modal.component.scss']
})
export class SelectAccountTypeModalComponent implements OnInit {

  constructor(
    private modal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public onCloseModal(): void {
    this.modal.close();
    this.router.navigate(['/auth/']);
  }

  public onSelectAccountType(accountType: string): void  {
    this.modal.close(accountType);
  }

}
