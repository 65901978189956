import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isGlobalLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public setLoading(value: boolean): void {
    this.isLoading.next(value);
  }
  
  public getLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public setGlobalLoading(value: boolean): void {
    this.isGlobalLoading.next(value);
  }

  public getGlobalLoading(): Observable<boolean> {
    return this.isGlobalLoading.asObservable();
  }
}
