<!-- Request Type Public or Private -->
<div class="modal-header no-border mb-3">
  <h4 class="modal-title" id="modal-basic-title" *ngIf="!submitted; else loadingTemplateTitle">
    Hey, antes de continuar es importante que conozcas la siguiente información...
  </h4>
  <ng-template #loadingTemplateTitle>
    <h4 class="modal-title">
      Espera un momento, estamos creando tu proyecto...
    </h4>
  </ng-template>
</div>
<div class="modal-body">
  <div class="service-type-assignation" *ngIf="!submitted; else loadingTemplate">
    <div
      class="service-type-assignation-block"
      id="airport"
      (click)="onCreateProject(true)"
    >
      <span class="material-symbols-outlined" style="margin-bottom: 5px;font-size: 2.5rem;">
        travel_explore
      </span>
      <p class="service-type-assignation-block-title">Proyecto Publico</p>
      <p class="service-type-assignation-block-subtitle">
        Al crear tu proyecto como <strong>"Publico"</strong> esta será visible para todos los transportistas
        registrados en la plataforma y así que puedan hacer una oferta por mover tu carga.
      </p>
    </div>
    <div
      class="service-type-assignation-block"
      id="ocean"
      (click)="onCreateProject(false)"
    >
      <span class="material-symbols-outlined" style="margin-bottom: 5px;font-size: 2.5rem;">
        vpn_lock
      </span>
      <p class="service-type-assignation-block-title">Proyecto Privado</p>
      <p class="service-type-assignation-block-subtitle">
        Al crear tu proyecto como <strong>"Privado"</strong> te mostraremos todas aquellas 
        empresas de transporte que cumplan con tus criterios de busqueda y deberás asignar la empresa de tu elección.
      </p>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="loading mb-3" #overlay *ngIf="submitted">
      <div class="loader m-auto"></div>
    </div>
  </ng-template>
</div>