import IMask from 'imask';

// 24 hour format time
export const TIME_MASK = {
  overwrite: true,
  autofix: true,
  mask: 'HH:MM',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      placeholderChar: 'HH',
      from: 0,
      to: 23,
      maxLength: 2
    },
    MM: {
      mask: IMask.MaskedRange,
      placeholderChar: 'MM',
      from: 0,
      to: 59,
      maxLength: 2
    }
  }
}

// number mask 
export const NUMBERS_ONLY_MASK = {
  mask: Number,
  scale: 2,
  thousandsSeparator: ',',
  padFractionalZeros: false,  
  normalizeZeros: true,  
  radix: '.', 
  mapToRadix: ['.'], 
  min: 0,
  max: 100000,
  autofix: false,
}

// price input mask
export const PRICE_MASK = {
  mask: Number,
  scale: 2,
  thousandsSeparator: ',',
  padFractionalZeros: true,
  normalizeZeros: true,
  radix: '.',
  mapToRadix: ['.'],
  min:0,
}

// phone number mask
export const PHONE_MASK = {
  mask: '(00) 0000-0000'
}

// zip code mask
export const ZIP_CODE_MASK = {
  mask: '00000'
}

// nss number mask
export const NSS_NUMBER_MASK = {
  mask: '00000000000'
}

// nss number mask
export const NUMBERS_MASK_WITHOUT_DECIMALS = {
  mask: '000'
}

//year mask
export const FOUR_DIGIT_YEAR_MASK = {
  mask: '0000'
}