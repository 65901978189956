import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { ExceptionProperties } from 'src/app/core/interfaces/exception.interface';
import Swal from 'sweetalert2';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'gfapp-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['../../auth.style.scss']
})
export class RecoverPasswordComponent implements OnInit {

  public recoverPasswordForm!: FormGroup;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  public recoverPassword(e: Event, form: FormGroup): void {
    if(form.valid) {
      this.userService.recoverPassword(form.value.email)
        .pipe(
          catchError((error) => {
            Swal.fire({
              title: error.title,
              text: error.description,
              allowEscapeKey: false,
              showConfirmButton: true,
              confirmButtonColor:  "#509760",
            });
            return of(undefined);
          })
        )
        .subscribe((response) => {
          if(response) {
            this.createForm();
            Swal.fire({
              title: 'Excelente 🥳',
              html: 'Se te ha enviado un correo electrónico con las instrucciones para reestablecer tu contraseña.',
              icon: 'success',
              backdrop: true,
              allowEscapeKey: false,
              confirmButtonText: 'Regresar al login',
              confirmButtonColor: '#509760'
            }).then(() => {
              this.route.navigate(['/auth/sign-in']);
            })
          }
        })
    }
  }

  private createForm(): void {
    this.recoverPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern("^[aA-zZ0-9._%+-]+@[aA-zZ0-9.-]+\\.[aA-zZ]{2,4}$")]]
    })
  }
}