<div class="driver-assignation">
  <div class="driver-info d-flex align-items-center mb-2">
    <figure class="avatar me-3">
      <img *ngIf="driverInfo && driverInfo.driver.photo; else notDriverImage" [src]="driverInfo.driver.photo" alt="Driver {{driverInfo.driver.firstName}} Image">
      <ng-template #notDriverImage>
        <img src="/assets/images/avatars/img-operador-avatar@1x.jpg" alt="Driver {{driverInfo?.driver?.firstName}} Image">
      </ng-template>
    </figure>
    <div class="driver-info-block">
      <span class="text-muted">Nombre de conductor</span>
      <div class="form-group">
        <select class="form-select" (change)="selectDriver($event)">
          <option value="" [defaultSelected]="true">Selecciona un conductor</option>
          <option *ngFor="let item of drivers" value="{{item.driver.id}}" [selected]="driverInfo && item.driver.id === driverInfo.driver.id" >
            {{item.driver.firstName}} {{item.driver.lastName}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="driver-info d-flex justify-content-between">
    <div class="driver-info-block">
      <span class="text-muted">Licencia</span>
      <p>{{driverInfo?.driver?.license || 'N/A'}}</p>
    </div>
    <div class="driver-info-block">
      <span class="text-muted">Edad</span>
      <p *ngIf="driverInfo; else noDriverAge">{{getDriverAge(driverInfo.driver.birthday) || 'N/A'}}  Años</p>
      <ng-template #noDriverAge>
        <p>N/A</p>
      </ng-template>
    </div>
    <div class="driver-info-block">
      <span class="text-muted">Teléfono</span>
      <p>{{driverInfo?.driver?.phone || 'N/A'}}</p>
    </div>
  </div>
</div>