<section class="auth">
  <div class="auth-form">
    <figure>
      <img src="assets/images/logos/svg-logo-dollink.svg" alt="Dollink Logo" width="120">
    </figure>
    <h1 class="mb-3">
      Bienvenidos a 
      <strong>Dollink App.</strong>
    </h1>
    <form [formGroup]="loginForm" (submit)="onLogin()" novalidate dollinkInvalidFormScroll>
      <div class="mb-3">
        <label for="emailInput" class="form-label">Correo</label>
        <div class="input-group has-validation">
          <input [class.is-invalid]="submitted && loginForm.controls['email'].errors" type="email" class="form-control" id="emailInput" formControlName="email">
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="loginForm.controls['email'].hasError('required')">El correo eléctronico es requerido</p>
            <p *ngIf="loginForm.controls['email'].hasError('pattern')">El correo eléctronico tiene un formato incorrecto</p>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="passwordInput" class="form-label">Contraseña</label>
        <div class="input-group has-validation">
          <input type="password" class="form-control" [class.is-invalid]="submitted && loginForm.controls['password'].errors" id="passwordInput" minlength="6" formControlName="password">
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="loginForm.controls['password'].hasError('required')">La contraseña es requerida</p>
          </div>
        </div>
      </div>
      <div *ngIf="showErrorMessage" class="mb-3">
        <div class="alert alert-danger d-flex align-items-center" role="alert">
          <i class="bi bi-exclamation-triangle-fill me-2"></i>
          <div>
            {{errorMessage}}
          </div>
        </div>
      </div>
      <div *ngIf="refreshTokenInvalidError" class="mb-3">
        <div class="alert alert-danger d-flex align-items-center" role="alert">
          <i class="bi bi-exclamation-triangle-fill me-2"></i>
          <div>
            Su sesión expiro, ingresa nuevamente tus credenciales para seguir usando Dollink.
          </div>
        </div>
      </div>
      <div class="mb-5 d-grid">
        <button type="submit" class="btn btn-primary btn-block" [disabled]="isLoading">
          <span *ngIf="!isLoading">Iniciar Sesión</span>
          <span *ngIf="isLoading">Iniciando sesión</span>
        </button>
      </div>
    </form>
    <div class="w-100 d-flex flex-column align-items-center">
      <span class="text-center mb-2">
        <a [routerLink]="['/auth/recover-password']">¿Olvidaste tu contraseña?</a>
      </span>
      <span class="text-center mb-2">
        ¿No tienes cuenta? <a [routerLink]="['/auth/signup']">Registrate</a>
      </span>
      <span class="text-center text-muted">
        ®{{year}} DOLLINK. Todos los derechos reservados.
      </span>
    </div>
  </div>
  <div class="auth-banner">
    <img src="assets/images/dollink_for_requestor.png" alt="">
  </div>
</section>