<section class="auth">
  <div class="auth-banner"></div>
  <div class="auth-form">
    <div class="container d-flex justify-content-center">
      <div class="auth-form-wrapper">
        <div class="auth-form-wrapper-title mb-3">
          <h1>
            Hola, {{name}}!
          </h1>
          <p>
            Ingresa tu nueva contraseña para recuperar tu cuenta.
          </p>
        </div>
        <form [formGroup]="newPasswordForm" (submit)="updatePassword($event, newPasswordForm)">
          <div class="mb-3">
            <label for="emailPassword" class="form-label">Nueva contraseña</label>
            <input type="password" class="form-control" id="emailPassword" formControlName="password">
          </div>
          <div class="mb-3">
            <label for="emailConfirmPassword" class="form-label">Confirmar contraseña</label>
            <input type="password" class="form-control" id="emailConfirmPassword" formControlName="confirmPassword" (keyup)="passwordMatcher()">
          </div>
          <div class="mb-5 d-grid">
            <button type="submit" class="btn btn-primary btn-block" [disabled]="newPasswordForm.invalid && !isSamePassword">Actualizar contraseña</button>
          </div>
        </form>
        <div class="auth-form-wrapper-footer d-flex flex-column align-items-center">
          <span class="text-center mb-2">
            Ya tienes cuenta? <a [routerLink]="['/auth/sign-in']">Iniciar Sesión</a>
          </span>
          <span class="text-center text-muted">
            ®2023 DOLLINK. Todos los derechos reservados.
          </span>
        </div>
      </div>
    </div>
  </div>
</section>