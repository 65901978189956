import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrailerTypes } from '../../interfaces/trailer-types.interface';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrailerTypeService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<TrailerTypes[]> {
    return this.http.get<TrailerTypes[]>(`${env.api}/trailer-type`);
  }
}
