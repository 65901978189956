<div class="dimensions-modal" ngbAutofocus>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Dimensiones</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th class="text-end" scope="col">Centimetros</th>
          <th class="text-end" scope="col">Metros</th>
          <th class="text-end" scope="col">Pulgadas</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Largo</td>
          <td class="text-end">{{dimensions.length}}</td>
          <td class="text-end">{{(dimensions.length / 100).toFixed(2)}}</td>
          <td class="text-end">{{(dimensions.length * 0.39370).toFixed(2)}}</td>
        </tr>
        <tr>
          <td>Ancho</td>
          <td class="text-end">{{dimensions.width}}</td>
          <td class="text-end">{{(dimensions.width / 100).toFixed(2)}}</td>
          <td class="text-end">{{(dimensions.width * 0.39370).toFixed(2)}}</td>
        </tr>
        <tr>
          <td>Alto</td>
          <td class="text-end">{{dimensions.height}}</td>
          <td class="text-end">{{(dimensions.height / 100).toFixed(2)}}</td>
          <td class="text-end">{{(dimensions.height * 0.39370).toFixed(2)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel btn-outline" aria-label="Close" (click)="onClose()">Cerrar</button>
  </div>
</div>