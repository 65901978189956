import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, of } from 'rxjs';
import { HomeService } from '../trucking/home/home.service';
import { StorageService } from '../storage/storage.service';
import { IOnboardingConfiguration, IOnboardingData } from '../../interfaces/onboarding.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnboardingComponent } from 'src/app/shared/modals/onboarding/onboarding.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  private readonly api = environment.api;
  private readonly endpoints = {
    onboarding: '/company/onboarding'
  }

  constructor(
    private modalService: NgbModal,
    private http: HttpClient,
  ) { }

  public showOnboarding(): Observable<IOnboardingData> {
    return this.http.get<IOnboardingData>(`${this.api}${this.endpoints.onboarding}`);
  }


  //Get Trucking Onboarding
  public showTruckingOnboarding(data: IOnboardingData): void {
    const truckingOnboardingInformation: IOnboardingConfiguration = {
      title: `¡Hola ${data.user.firstName}!`,
      subtitle: `Es importante que antes de iniciar completes la siguiente información con el objetivo de brindarte la mejor experiencia.`,
      image: '/assets/images/onboarding/onboarding-settings.png',
      steps: [
        {
          description: 'Datos de tu empresa',
          route: '/requestor/account/company',
          isCompleted: data.isProfileCompleted ? true : false,
        },
        {
          description: 'Documentos de tu empresa',
          route: '/requestor/account/documents',
          isCompleted: data.isDocumentsCompleted ? true : false,
        },
        {
          description: 'Agregar operadores',
          route: '/trucking/users',
          isCompleted: data.drivers && data.drivers > 0 ? true : false
        },
        {
          description: 'Agregar unidades',
          route: '/trucking/cargo-units',
          isCompleted: data.units && data.units > 0 ? true : false,
        },
      ],
    };
    this.openModal(truckingOnboardingInformation);
  }

  public showRequestorOnboarding(data: IOnboardingData): void {
    const requestorOnboardingInformation: IOnboardingConfiguration = {
      title: `¡Hola ${data.user.firstName}!`,
      subtitle: `Es importante que antes de iniciar completes la siguiente información con el objetivo de brindarte la mejor experiencia.`,
      image: '/assets/images/onboarding/onboarding-settings.png',
      steps: [
        {
          description: 'Datos de tu empresa',
          route: '/requestor/account/company',
          isCompleted: data.isProfileCompleted ? true : false,
        },
        {
          description: 'Documentos de tu empresa',
          route: '/requestor/account/documents',
          isCompleted: data.isDocumentsCompleted ? true : false,
        },
      ],
    }
    this.openModal(requestorOnboardingInformation);
  }

  private openModal(modalData: any): void {
    //open modal
    const modalRef = this.modalService.open(OnboardingComponent, {
      size: 'xl',
      centered: true,
      modalDialogClass: 'onboarding',
      keyboard: false,
      backdrop: 'static',
    });
    modalRef.componentInstance.modalData = modalData;
  } 
}
