import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICompany } from '../../interfaces/company.interface';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpClient,
  ) { }

  getById(): Observable<ICompany> {
    return this.http.get<ICompany>(`${env.api}/company`);
  }

  update(data: FormData): Observable<any> {
    return this.http.put(`${env.api}/company`, data);
  }
}
