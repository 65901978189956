<div class="header-breadcrumbs">
  <h3 *ngIf="headerTitle?.title !== ''">{{headerTitle?.title}}</h3>
  <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
    <ol class="breadcrumb">
      @for (breadcrumb of headerTitle?.breadcrumbs; track breadcrumb) {
        <li class="breadcrumb-item" aria-current="page">
          <a *ngIf="!breadcrumb.isActive; else activePageTemplate" [routerLink]="[breadcrumb.route]" [class.active]="breadcrumb.isActive" [innerHTML]="breadcrumb.label"></a>
          <ng-template #activePageTemplate>
            {{breadcrumb.label}}
          </ng-template>
        </li>
      }
    </ol>
  </nav>
  <a class="linkTo" [routerLink]="[headerTitle?.linkTo?.path]" *ngIf="headerTitle?.linkTo" [innerHtml]="headerTitle?.linkTo?.label"></a>
</div>