import { Component, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { environment as env } from 'src/environments/environment';

interface File {
  message: string;
  file: any;
}

@Component({
  selector: 'dollink-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})

export class ImageUploaderComponent {
  
  public image: any;

  @Input() set file(file: string | null) {
    this.image = file;
  }
  @Output() fileEmitted = new EventEmitter<any>();

  public onFileSelect(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileSize: number = file.size / 1024 ** 3;
      if(fileSize >= 3) {
        const fileEmitted: File = {
          message: 'Oops 😕. El archivo que estas intentando subir es muy grande, por favor agrega un archivo menor a 3mb.',
          file: null
        }
        this.fileEmitted.emit(fileEmitted);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => (this.image = reader.result);
        reader.readAsDataURL(file);
        const fileEmitted: File = {
          message: 'Excelente 🥳, el archivo es valido!',
          file: file
        };
        this.fileEmitted.emit(fileEmitted);
      }
    }
  }
}