<div class="offcanvas-header">
  <h4>
    {{cargos.length}} carga<span *ngIf="cargos.length > 1">s</span> agregada<span *ngIf="cargos.length > 1">s</span>
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="offcanvas-body" id="offcanvas-body">
  <ul class="cargo-list">
    <li class="cargo-list-item d-flex align-items-center" *ngFor="let cargo of cargos">
      <div class="cargo-icon me-2">
        <div class="icon-wrapper">
          <span class="material-symbols-outlined"> pallet</span>
        </div>
      </div>
      <div class="d-flex flex-column cargo-data">
        <div class="cargo-data-reference-number w-100">
          <label>No. de referencia</label>
          <p>{{cargo.number}}</p>
        </div>
        <div class="cargo-data-reference-number w-100">
          <label>Dimensiones</label>
          <p>
            {{cargo.length}}cm x {{cargo.width}}cm x {{cargo.height}}cm
            <span class="material-symbols-outlined ms-2" (click)="onOpenDimensionsModal(cargo.length, cargo.width, cargo.height )">
              open_in_new
            </span>
          </p>
        </div>
        <div class="cargo-data-additionals d-flex flex-row">
          <div>
            <label>Peso bruto</label>
            <p *ngIf="cargo.grossWeight">{{cargo.grossWeight}}kg</p>
          </div>
          <div *ngIf="cargo.netWeight">
            <label>Peso neto</label>
            <p>{{cargo.netWeight}}kg</p>
          </div>
        </div>
        <div class="cargo-data-reference-number w-100" *ngIf="cargo.note && cargo.note !== ''">
          <label>Comentarios</label>
          <p>{{cargo.note}}</p>
        </div>
      </div>
      <div class="cargo-icon delete" (click)="onDeleteCargo(cargo)" 
        *ngIf="user === 'REQUESTOR' && (requestStatusAbbr === REQUEST_STATUS.NEW || requestStatusAbbr === REQUEST_STATUS.NEGO)"
      >
        <span class="material-symbols-outlined">delete_forever</span>
      </div>
    </li>
  </ul>
</div>