import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAvailableDrivers } from 'src/app/core/interfaces/driver.interface';
import { convertBirthdayToAge } from '../../../core/helpers/global.helper';
import { DriversService } from '../../../core/services/drivers/drivers.service';
import { catchError, of } from 'rxjs';


@Component({
  selector: 'dollink-driver-assignation',
  templateUrl: './driver-assignation.component.html',
  styleUrls: ['./driver-assignation.component.scss']
})
export class DriverAssignationComponent implements OnInit {

  @Input() requestId = "";
  @Input() driverId = "";

  public drivers: IAvailableDrivers[] = [];
  public userCompanyType: string = '';
  public driverInfo: IAvailableDrivers | undefined;

  @Output() selectedDriver = new EventEmitter<any>();

  constructor(
    private driverService: DriversService,
  ) { }

  ngOnInit(): void {
    this.getDrivers();
  }

  public getDriverAge(birthday: string): string {
    return convertBirthdayToAge(birthday);
  }

  public selectDriver(e: any): void {
    const driverId = e.target.value;
    this.driverInfo = this.drivers.find((item) => item.driver.id === driverId);
    if(this.driverInfo) {
      this.selectedDriver.emit(this.driverInfo!.driver);
    } else {
      this.selectedDriver.emit("");
    }
  }

  private getDrivers(): void {
    this.driverService.getFreeDriversForRequest(this.requestId)
      .pipe(
        catchError((error) => {
          return of(undefined);
        })
      )
      .subscribe((response: any) => {
        // If RequestID and RequestUUID are present means that the driver has an assignation for thar RequestID in the same date to new pickup: Este conductor esta asignado a la solicitud {{number}} que termina la misma fecha de recolección
        // If RequestID and RequestUUID are null, the driver is complete free
        // https://api-dev.dollink.app/swagger-ui/index.html#/Driver/findFreeDrivesForRequest_1
        if(response) {
          this.drivers = response
          if(this.driverId !== "") {
            this.driverInfo = this.drivers.find((item) => item.driver.id === this.driverId);
          }
        }
      })
  }
}
