import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, of } from 'rxjs';
import { IMapAddress } from 'src/app/core/interfaces/map.interface';
import { IStates } from 'src/app/core/interfaces/states.interface';
import { CountriesService } from 'src/app/core/services/countries/countries.service';
import { TIME_MASK } from 'src/app/shared/masks/masks';
import { ProjectsService } from 'src/app/core/services/requestor/projects/projects.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IProjectPickup } from 'src/app/core/interfaces/projects.interface';
import { RequestTypes } from 'src/app/core/enums/request.enum';
import { RequestsService } from 'src/app/core/services/trucking/requests/requests.service';
import * as dayjs from 'dayjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'dollink-request-pickup',
  templateUrl: './request-pickup.component.html',
  styleUrls: ['./request-pickup.component.scss'],
})
export class RequestPickupComponent implements OnInit {

  @Input() projectId = "";
  @Input() pickup: IProjectPickup | undefined = undefined;
  @Input() type: RequestTypes | undefined = undefined;

  public pickupLocationForm!: FormGroup;
  public dueDateLimitYearOrigin = dayjs().year();
  public dueDateLimitMonthOrigin = dayjs().month() + 1;
  public dueDateLimitDayOrigin = dayjs().get('date');
  public states: IStates[] = [];
  public submitted = false;
  public timeMask = TIME_MASK;
  public isSaving = false;
  public updateButtonText = "Guardar";
  public isEditing = false;

  constructor(
    private fb: FormBuilder,
    private statesService: CountriesService,
    private projectService: ProjectsService,
    private modal: NgbActiveModal,
    private requestTruckingService: RequestsService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getAllStates();

    if(this.pickup) {
      this.isEditing = true;
      this.pickupLocationForm.patchValue(this.pickup);
      // update pickup date
      const dropoffDate = dayjs(this.pickup.date);
      this.pickupLocationForm.controls['date'].setValue({year: dropoffDate.get('year'), month: dropoffDate.get('month') + 1, day: dropoffDate.get('date')})
      this.enableForm();
    }
  }

  public getAddress(address: IMapAddress): void {
    if(address) {
      this.pickupLocationForm.patchValue(address);
      this.enableForm();
    }
  }  

  public onClose(): void {
    this.modal.close({isEditing: this.isEditing});
  }

  public onSave(): void {
    this.submitted = true;

    if(this.pickupLocationForm.valid) {
      this.isSaving = true;
      this.updateButtonText = "Guardando";
      const date = this.pickupLocationForm.controls['date'].value;
      const projectPickupDate = dayjs(`${date.year}-${date.month}-${date.day}`).format('YYYY-MM-DD');
      
      const pickupLocationData = {
        ...this.pickupLocationForm.value,
        date: projectPickupDate
      }

      if(this.type === RequestTypes.PROJECT) {
        this.createRequestorProject(pickupLocationData);
      } 

      if(this.type === RequestTypes.SELF_MANAGED) {
        this.createSelfManagedPickupService(pickupLocationData);
      }

    } else {
      this.isSaving = false;
      this.updateButtonText = this.updateButtonText;
      this.pickupLocationForm.markAllAsTouched();
    }
  }

  private createSelfManagedPickupService(pickup: any): void {
    if(!this.isEditing) {
      this.requestTruckingService.createServiceRequestPickup(pickup)
        .pipe(
          catchError((error) => {
            return of(undefined);
          })
        )
        .subscribe((response) => {
          if(response && response.id) {
            this.modal.close(response);
          }
        })
    } else {
      this.requestTruckingService.updateServiceRequestPickup(this.projectId, pickup)
        .pipe(
          catchError(() => {
            return of(undefined);
          })
        )
        .subscribe((response) => {
          if(response) {
            this.modal.close(response);
          }
        })
    }
  }

  private createRequestorProject(pickup: any): void {
    if(!this.isEditing) {
      this.projectService.createProject(pickup)
        .pipe(
          catchError((error) => {
            return of(undefined);
          })
        )
        .subscribe((response) => {
          if(response && response.id) {
            this.modal.close(response);
          }
        })
    } else {
      this.projectService.updatePickupLocation(this.projectId, pickup)
        .pipe(
          catchError(() => {
            return of(undefined);
          })
        )
        .subscribe((response) => {
          if(response) {
            this.modal.close(response);
          }
        })
    }
  }

  private enableForm(): void {
    this.pickupLocationForm.get('address')?.enable();
    this.pickupLocationForm.get('suiteNumber')?.enable();
    this.pickupLocationForm.get('streetNumber')?.enable();
    this.pickupLocationForm.get('zipcode')?.enable();
    this.pickupLocationForm.get('state')?.enable();
    this.pickupLocationForm.get('city')?.enable();
    this.pickupLocationForm.get('country')?.enable();
    this.pickupLocationForm.get('time')?.enable();
    this.pickupLocationForm.get('date')?.enable();
  }

  private createForm(): void { 
    this.pickupLocationForm = this.fb.group({
      place: ['', Validators.required],
      address: [{value: '', disabled: true}, Validators.required],
      streetNumber: [{value: '', disabled: true}, Validators.required],
      suiteNumber: [{value: '', disabled: true}],
      zipcode: [{value: '', disabled: true}, Validators.required],
      state: [{value: '', disabled: true}, Validators.required],
      city: [{value: '', disabled: true}, Validators.required],
      country: [{value: 'México', disabled: true}, Validators.required],
      date: [{value: '', disabled: true}, Validators.required],
      time: [{value: '', disabled: true}],
      latitude: [null, Validators.required],
      longitude: [null, Validators.required],
      isMain: [true, Validators.required],
    })
  }

  private getAllStates(): void{
    this.statesService.getStates()
      .pipe(
        catchError((error) => {
          Swal.fire({
            title: error.title,
            text: error.description,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor:  "#509760",
          });
          return of(undefined);
        })
      )
      .subscribe((states) => {
        if(states) {
          this.states = states;
        }
      })
  }
}
