import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IProject, IProjectCargos, IProjectDropoff, IProjectInNegotiation, IProjectMyCompanies, IProjectPickup, IProjectRecommendations } from '../../../interfaces/projects.interface';
import { IProjectExtras, IRequestCargoList } from '../../../interfaces/request.interface';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IPaginationResponse } from '../../../interfaces/pagination.interface';

@Injectable({
  providedIn: 'root'
})

export class ProjectsService {

  private readonly api = environment.api;

  // project pickup location behavior subject
  private projectLocationPickup = new BehaviorSubject<IProjectPickup | undefined>(undefined);
  currentProjectLocationPickup$ = this.projectLocationPickup.asObservable();

  // project dropoff location behavior subject
  private projectLocationDropoff = new BehaviorSubject<IProjectDropoff | undefined>(undefined);
  currentprojectLocationDropoff$ = this.projectLocationDropoff.asObservable();

  // project dropoff location behavior subject
  private projectRequestCargoDetails = new BehaviorSubject<IRequestCargoList[] | undefined>(undefined);
  currentprojectRequestCargoDetails$ = this.projectRequestCargoDetails.asObservable();

   // project dropoff location behavior subject
   private projectRequestExtras = new BehaviorSubject<IProjectExtras | undefined>(undefined);
   currentprojectRequestExtras$ = this.projectRequestExtras.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  // update request pickup location project info
  public setProjectLocationPickup(pickupLocation: IProjectPickup): void {
    this.projectLocationPickup.next(pickupLocation);
  }

  // update request dropoff location project info
  public setProjectLocationDropoff(dropoffLocation: IProjectDropoff): void {
    this.projectLocationDropoff.next(dropoffLocation);
  }

  // update request cargo details 
  public setProjectCargoDetails(cargoList: IRequestCargoList[]): void {
    this.projectRequestCargoDetails.next(cargoList);
  }

  // update request cargo extras
  public setProjectRequestExtras(requestExtras: IProjectExtras): void {
    this.projectRequestExtras.next(requestExtras);
  }

  // create new project
  public createProject(projectPickup: {pickup: IProjectPickup, cargos: {}}): Observable<{id: number, uuid: string}> {
    return this.http.post<{id: number, uuid: string}>(`${this.api}/requestor/project`, projectPickup);
  }

  public updatePickupLocation(projectId: string, pickupLocation: IProjectPickup): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/${projectId}/pickup`, pickupLocation);
  }

  // create or update dropoff info
  public setDropoffLocationProject(uuid: string, projectDropoff: IProjectDropoff): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/${uuid}/dropoff`, projectDropoff);
  }

  // create or update new carga
  public setProjectCarga(uuid: string, projectCarga: IProjectCargos): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/${uuid}/cargo`, projectCarga);
  }

  // create or update additional info
  public setAdditionalProjectInfo(uuid: string, projectExtras: IProjectExtras): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/${uuid}/additional-info`, projectExtras);
  }

  // delete project carga
  public deleteCargaFromProject(uuid: string, cargaId: number): Observable<HttpResponse<object>> {
    return this.http.delete<HttpResponse<object>>(`${this.api}/requestor/project/${uuid}/cargo/${cargaId}`);
  }

  // set complete project
  public completeProject(projectId: string, isPublic: boolean): Observable<HttpResponse<object>> {
    const projectType = { public: isPublic };
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/${projectId}/start`, projectType);
  }

  /**
   * get incompleted projects
   * @param page 
   * @param size 
   * @returns a list of incompleted projects
   */
  public getIncompletedProjects(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}/requestor/project/incomplete?page=${page}&size=${size}&sort=createdDate,desc`);
  }


  // get inconmpleted project by id
  public getIncompleteProjectById(id: string): Observable<IProject> {
    return this.http.get<IProject>(`${this.api}/requestor/project/incomplete/${id}`);
  }

  /**
   * 
   * @param page 
   * @param size 
   * @returns A list of projects in negotiation
   */
  public getInNegotiationProjects(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}/requestor/project/negotiation?page=${page}&size=${size}&sort=createdDate,desc`);
  }

  public getProjectInNegotiatonById(id: string): Observable<IProjectInNegotiation> {
    return this.http.get<IProjectInNegotiation>(`${this.api}/requestor/project/negotiation/${id}`);
  }

  public getProjectMyCompanies(id: string): Observable<IProjectMyCompanies[]> {
    return this.http.get<IProjectMyCompanies[]>(`${this.api}/requestor/project/negotiation/search/${id}`);
  }

  public getProjectRecommendations(id: string): Observable<IProjectRecommendations[]> {
    return this.http.get<IProjectRecommendations[]>(`${this.api}/requestor/project/recommendation/search/${id}`);
  }

  public startNegotiation(projectId: string, truckingId: string): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/negotiation/${projectId}/start`, {truckingUUID: truckingId}, {observe: 'response'});
  }

  public removeNegotiation(projectId: string, truckingId: string): Observable<HttpResponse<object>> {
    return this.http.delete<HttpResponse<object>>(`${this.api}/requestor/project/negotiation/${projectId}/${truckingId}`, {observe: 'response'});
  }

  public updatePriority(projectId: string, priorityItems: {truckingUUID: string, priority: number}[]): Observable<any> {
    return this.http.put<HttpResponse<any>>(`${this.api}/requestor/project/negotiation/${projectId}`, priorityItems);
  }

  public approveCompanyNegotiation(projectId: string, truckingId: string): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/negotiation/${projectId}/${truckingId}/approve`, {});
  }

  public deleteProject(projectId: string, reason: string): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/cancel/${projectId}`, {comment: reason}, { observe: 'response'});
  }

  public approveProject(projectId: string): Observable<any> {
    return this.http.post<any>(`${this.api}/requestor/project/negotiation/${projectId}/approve`, {});
  }

  public getAssignationProjects(): Observable<HttpResponse<IPaginationResponse>> {
    return this.http.get<IPaginationResponse>(`${this.api}/requestor/project/in-assignation`, {observe: 'response'});
  }

  public assignTruckingCompany(projectId: string, truckingUUID: string): Observable<HttpResponse<object>> {
    return this.http.post<HttpResponse<object>>(`${this.api}/requestor/project/assignation/${projectId}/${truckingUUID}`, {}, {observe: 'response'});
  }

  public getCanceledProjects(): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${this.api}/requestor/project/cancel`);
  }
}
