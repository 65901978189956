import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IPaginationResponse } from '../../interfaces/pagination.interface';
import { objectToFormData } from '../../helpers/global.helper';
import { IPrivateRequest, IRequest } from '../../interfaces/request.interface';
import { searchResponse } from '../mock-data/mock-trucking-response';
import { IProjectPickup } from '../../interfaces/projects.interface';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private http: HttpClient,
  ) { }

  getPrivateRequests(): Observable<any> {
    return this.http.get<any>(`${env.api}/trucking/request/assigned`);
  }

  getTruckingPublicRequests(params: {pickupCity: string, dropoffCity:  string, pickupDate: string}, page: number = 0, size: number = 10000): Observable<any> {
    const searchParams = '&' + new URLSearchParams(params).toString();
    let url: string = `${env.api}/trucking/request/public?page=${page}&size=${size}${searchParams}&sort=createdDate,desc`;
    return this.http.get<any>(url);
  }

  getById(requestId: number): Observable<IRequest> {
    return this.http.get<IRequest>(`${env.api}/trucking/request/${requestId}`);
  }

  startNegotiation(requestId: number): Observable<HttpClient> {
    return this.http.post<HttpClient>(`${env.api}/trucking/request/${requestId}/negotiation`, {requestId: requestId});
  }

  getTruckingCompletedServices(page: number = 0, size: number = 1000): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${env.api}/trucking/request/`)
  }

  assignPriceToPublicNegotiation(requestId: number, data: {price: number}): Observable<any> {
    return this.http.post<any>(`${env.api}/trucking/request/${requestId}/price`, data);
  }

  getAssignedPrice(requestId: number): Observable<any> {
    return this.http.get<any>(`${env.api}/trucking/request/${requestId}/price`);
  }

  acceptNegotiationByTrucking(requestId:  number): Observable<any> {
    return this.http.post<any>(`${env.api}/trucking/request/${requestId}/accept-negotiation`, {});
  }

  //SET STATUS REQUEST BY TRUCKING ADMIN 
  //TO-DO: This services will be user by the app móvil

  setInTransitToPickupStatus(requestId: number): Observable<any> {
    return this.http.post<any>(`${env.api}/trucking/request/${requestId}/transit-pickup`, {});
  }

  setPickUpLocationStatus(requestId: number): Observable<any> {
    return this.http.post<any>(`${env.api}/trucking/request/${requestId}/pickup-location`, {});
  }

  setInTransitToDropOffStatus(requestId: number): Observable<any> {
    return this.http.post<any>(`${env.api}/trucking/request/${requestId}/transit-drop-off`, {});
  }

  setDropOffLocationStatus(requestId: number): Observable<any> {
    return this.http.post<any>(`${env.api}/trucking/request/${requestId}/drop-off-location`, {});
  }

  setFinishedStatus(requestId: number): Observable<any> {
    return this.http.post<any>(`${env.api}/trucking/request/${requestId}/finished`, {});
  }



  //REQUESTOR SERVICES FOR REQUESTS
  create(request: IRequest): Observable<any> {
    return this.http.post<any>(`${env.api}/requestor/request`, request);
  }

  getTruckingsByRequestId(requestId: number): Observable<{favorites: IPrivateRequest[], others: IPrivateRequest[]}> {
    return this.http.get<{favorites: IPrivateRequest[], others: IPrivateRequest[]}>(`${env.api}/requestor/request/search/${requestId}`);
    //return of(searchResponse)
  }

  assignTruckingCompanyAsFavorite(companyId: number, isFavorite: boolean): Observable<{details: string, message: string, timestamp: string}> {
    return this.http.put<{details: string, message: string, timestamp: string}>(`${env.api}/company/favorite/${companyId}/${isFavorite}`, {})
  }

  assignPrivateRequests(truckingCompanyIds: number[], requestId: number): Observable<any> {
    return this.http.post(`${env.api}/requestor/request/${requestId}/negotiation`, {truckingCompanyIds: truckingCompanyIds});
  }

  getRequestorRequestById(requestId: number): Observable<any> {
    return this.http.get<any>(`${env.api}/requestor/request/${requestId}`);
  }

  getRequestorNegotionsByRequestId(requestId: number): Observable<any> {
    return this.http.get<any>(`${env.api}/requestor/request/search/negotiations/${requestId}`);
  }

  getRequestorNegotiations(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${env.api}/requestor/request?status=NEGO&page=${page}&size=${size}&sort=createdDate,desc`);
  }

  getRequestorPrivateNegotiations(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${env.api}/requestor/request?status=ASSI,WFSE&page=${page}&size=${size}&sort=createdDate,desc`);
  }

  getRequestorPublicRequests(page: number = 0, size: number = 100): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${env.api}/requestor/request?isPublic=true&sort=createdDate,desc&status=NEW&page=${page}&size=${size}&sort=createdDate,desc`);
  }

  getRequestorInTransitRequests(page: number = 0, size: number = 10000): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${env.api}/requestor/request?status=INPI,PICK,INDR,DROP&page=${page}&size=${size}&sort=createdDate,desc`);
  }

  getRequestorCompletedRequests(page: number = 0, size: number = 10000): Observable<IPaginationResponse> {
    return this.http.get<IPaginationResponse>(`${env.api}/requestor/request?status=FINI,CANC&page=${page}&size=${size}&sort=createdDate,desc`);
  }

  getNegotiationPriceByRequestAndTrucking(requestId: number, truckingId: number): Observable<any> {
    return this.http.get<any>(`${env.api}/requestor/request/${requestId}/${truckingId}/price`);
  }

  getNegotiationsByRequest(requestId: number): Observable<any> {
    return this.http.get<any>(`${env.api}/requestor/request/search/negotiations/${requestId}`);
  }

  getNegotiationInfoByRequestAndTrucking(requestId: number, truckingId: number): Observable<{acceptedDate: string; terms: string; initialPrice: number, finalPrice: number;}> {
    return this.http.get<{acceptedDate: string; terms: string; initialPrice: number, finalPrice: number;}>(`${env.api}/requestor/request/${requestId}/${truckingId}/negotiation`);
  }

  acceptNegotiationByRequestor(requestId: number, truckingCompanyId: number): Observable<any> {
    return this.http.post<any>(`${env.api}/requestor/request/${requestId}/${truckingCompanyId}/accept-negotiation`, {});
  }

  cancelRequest(requestId: number): Observable<any> {
    return this.http.post<any>(`${env.api}/requestor/request/${requestId}/cancel`, {});
  }

  deleteCargoFromRequest(requestId: number, cargoId: number): Observable<any> {
    return this.http.delete<any>(`${env.api}/requestor/request/${requestId}/${cargoId}`);
  }
}
