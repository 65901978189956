<div class="modal-header no-border custom-padding-project">
  <h2 class="mb-0">Información adicional</h2>
</div>
<div class="modal-body custom-padding-project">
  <form [formGroup]="requestExtrasForm">
    <div class="row mb-3">
      <div class="col-12 col-md-8 mb-2">
        <label for="inputClientName" class="form-label">Nombre de la empresa</label>
        <div class="input-group has-validation">
          <input
            id="inputClientName"
            type="text"
            class="form-control"
            formControlName="clientName"
            [class.is-invalid]="submitted && requestExtrasForm.controls['clientName'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="requestExtrasForm.controls['clientName'].hasError('required')">El nombre del cliente es requerido</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputClientContactName" class="form-label">Nombre de contacto</label>
        <div class="input-group has-validation">
          <input
            id="inputClientContactName"
            type="text"
            class="form-control"
            formControlName="clientContactName"
            [class.is-invalid]="submitted && requestExtrasForm.controls['clientContactName'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="requestExtrasForm.controls['clientContactName'].hasError('required')">El nombre del contacto es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputClientContactPhone" class="form-label">Teléfono de contacto</label>
        <div class="input-group has-validation">
          <input
            id="inputClientContactPhone"
            type="text"
            class="form-control"
            formControlName="clientContactPhone"
            [imask]="phoneMask"
            [class.is-invalid]="submitted && requestExtrasForm.controls['clientContactPhone'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="requestExtrasForm.controls['clientContactPhone'].hasError('required')">El teléfono de contacto es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputClientContactEmail" class="form-label">Correo de contacto</label>
        <div class="input-group has-validation">
          <input
            id="inputClientContactEmail"
            type="text"
            class="form-control"
            formControlName="clientContactEmail"
            [class.is-invalid]="submitted && requestExtrasForm.controls['clientContactEmail'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="requestExtrasForm.controls['clientContactEmail'].hasError('required')">El correo de contacto es requerido</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputPrice" class="form-label">Precio pactado</label>
        <input
          id="inputPrice"
          type="text"
          class="form-control"
          formControlName="price"
          [imask]="priceMask"
        />
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputPaymentsCondition" class="form-label">Condiciones de pago</label>
        <div class="input-group has-validation">
          <select class="form-select" formControlName="paymentConditions">
            <option value="" selected>Selecciona una opción</option>
            <option value="Contra entrega">Contra entrega</option>
            <option value="15 días">15 días</option>
            <option value="30 días">30 días</option>
            <option value="45 días">45 días</option>
            <option value="60 días">60 días</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputAlias" class="form-label">Alias</label>
        <div class="input-group has-validation">
          <input
            id="inputAlias"
            type="text"
            class="form-control"
            formControlName="alias"
          />
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-6 mb-2">
        <label for="inputCargoUnits" class="form-label">Unidades disponibles</label>
        <div class="input-group has-validation">
          <select
            id="inputCargoUnits"
            class="form-select"
            (change)="onSelectCargoUnit($event)"
            formControlName="cargoUnits"
            [class.is-invalid]="submitted && requestExtrasForm.controls['cargoUnits'].errors"
          >
            <option value="" selected>Selecciona un tipo de unidad</option>
            <option
              *ngFor="let item of cargoUnits"
              value="{{ item.cargoUnit.uuid }}"
            >
              {{ item.cargoUnit.brand }} {{item.cargoUnit.model}}
            </option>
          </select>
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="requestExtrasForm.controls['cargoUnits'].hasError('required')">La unidad es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <label for="inputDrivers" class="form-label">Conductores disponibles</label>
        <div class="input-group has-validation">
          <select
            id="inputDrivers"
            class="form-select"
            #driversDropdown
            (change)="onSelectDrivers(driversDropdown.value)"
            formControlName="drivers"
          >
            <option value="" selected>Selecciona un conductor</option>
            <option
              *ngFor="let item of drivers"
              value="{{ item.driver.id }}"
            >
              {{ item.driver.firstName }} {{ item.driver.lastName }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 mb-2">
        <label for="inputComments" class="form-label">Comentarios adicionales</label>
        <div class="input-group has-validation">
          <textarea id="inputComments" class="form-control" formControlName="comments"></textarea>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer no-border custom-padding-project-actions">
  <button type="button" class="btn btn-cancel btn-outline" (click)="onClose()">Cancelar</button>
  <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="onSave()">
    <span class="spinner-border spinner-border-sm me-2" aria-hidden="true" *ngIf="isSaving"></span>
    <span role="status">{{updateButtonText}}</span>
  </button>
</div>