import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProjectDropoff, IProjectPickup } from 'src/app/core/interfaces/projects.interface';
import { IRequestCargoList } from 'src/app/core/interfaces/request.interface';

@Injectable({
  providedIn: 'root'
})
export class TruckingRequestsService {

  private currentStep: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private pickup: BehaviorSubject<IProjectPickup | undefined>  = new BehaviorSubject<IProjectPickup | undefined>(undefined);
  private dropoff: BehaviorSubject<IProjectDropoff | undefined>  = new BehaviorSubject<IProjectDropoff | undefined>(undefined);
  private cargos: BehaviorSubject<IRequestCargoList[]> = new BehaviorSubject<IRequestCargoList[]>([]);

  constructor() { }

  /**
   * 
   * @param step 
  **/
  public setCurrentStep(step: string): void {
    this.currentStep.next(step);
  }

  public getCurrentStep(): Observable<string> {
    return this.currentStep.asObservable();
  }

  /**
   * Set pickup information
   * @param pickup IProjectPickup
  **/
  public setPickup(pickup: IProjectPickup | undefined): void {
    this.pickup.next(pickup);
  }

  public getPickup(): Observable<IProjectPickup | undefined> {
    return this.pickup.asObservable();
  }

  /**
   * Set dropoff information
   * @param pickup IProjectPickup
  **/
   public setDropoff(pickup: IProjectDropoff | undefined): void {
    this.dropoff.next(pickup);
  }

  public getDropoff(): Observable<IProjectDropoff | undefined> {
    return this.dropoff.asObservable();
  }

  /**
   * Set cargos information
   * @param cargos 
  **/
  public setCargos(cargos: IRequestCargoList[]): void {
    this.cargos.next(cargos);
  }

  public getCargos(): Observable<IRequestCargoList[]> {
    return this.cargos.asObservable();
  }


}
