import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env} from 'src/environments/environment';
import { IReceivedMessages } from '../../interfaces/chat.interface';

@Injectable({
  providedIn: 'root'
})

export class ChatService {

  private readonly api = env.api;

  constructor(
    private http: HttpClient
  ) { }

  getMessages(companyRequestorId: number, companyTruckingId: number, requestId: number) : Observable<IReceivedMessages[]> {
    return this.http.get<IReceivedMessages[]>(`${this.api}/chat/messages/${companyRequestorId}/${companyTruckingId}/${requestId}`);
  }

  getNewMessages(companyRequestorId: string, companyTruckingId: string, requestId: string): Observable<any> {
    return this.http.get<any>(`${this.api}/chat/messages/${companyRequestorId}/${companyTruckingId}/${requestId}/count`);
  }

  getChatCounter(): Observable<number> {
    return this.http.get<number>(`${this.api}/chat/messages/received/count`);
  }

  getChatList(): Observable<IReceivedMessages[]> {
    return this.http.get<IReceivedMessages[]>(`${this.api}/chat/messages/received`);
  } 
}