import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from 'src/app/core/services/requests/request.service';
import { DocumentsService } from 'src/app/core/services/documents/documents.service';
import { catchError, of } from 'rxjs';
import Swal from 'sweetalert2';

interface IFile {
  canHandle: boolean;
  file: File;
  fileString: string;
  fileSize: string;
  notification?: string;
  isUploading?: boolean;
  uploadedAt: string
}

@Component({
  selector: 'dollink-request-documents-modal',
  templateUrl: './request-documents-modal.component.html',
  styleUrls: ['./request-documents-modal.component.scss']
})
export class RequestDocumentsModalComponent implements OnInit {

  @Input() request = 0;
  @Input() readonly = false;

  public active = 1;
  public files: IFile[] = [];
  public sharedFiles: string[] = [];
  public acceptedFiles: File[] = [];
  public ownedDocuments: any[] = [];
  public sharedDocuments: any[] = [];
  public isLoadingDocuments = false;

  private submitted = false;
  private readonly MAX_FILE_SIZE = 5;
  private readonly ALLOWED_FILE_TYPES = ['pdf', 'xlsx', 'xls', 'docx',  'doc', 'jpg', 'jpeg', 'png', 'txt'];

  constructor(
    private modalService: NgbModal,
    private requestService: RequestService,
    private documentsService: DocumentsService,
  ) { }

  ngOnInit(): void {
    this.getDocuments();
  }
  
  public onClose(): void {
    this.modalService.dismissAll();
  }

  public onSelectFiles(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    if(files) {
      Array.from(files).forEach((file: File) => {
        this.fileValidations(file).then((fileResponse) => {
          this.files.push(fileResponse);
          if(fileResponse.canHandle) {
            this.acceptedFiles.push(fileResponse.file);
          }
        })
      })
    }
  }

  public onDroppedFile(fileList: FileList): void {
    Array.from(fileList).forEach((file: File) => {
      this.fileValidations(file).then((fileResponse) => {
        this.files.push(fileResponse);
        if(fileResponse.canHandle) {
          this.acceptedFiles.push(fileResponse.file);
        }
      })
    })
  }

  public onSubmitFiles(event: Event): void {
    event.preventDefault();
    this.isLoadingDocuments = true;
    this.documentsService.uploadFiles(this.acceptedFiles, this.request)
    .pipe(
      catchError((error) => {
        console.error(error);
        return of(undefined);
      })
    )
    .subscribe((fileUploadResponse) => {
      if(fileUploadResponse) {
        setTimeout(() => {
          this.getDocuments();
          this.isLoadingDocuments = false;
          this.files = [];
        }, 1000);
      }
    })
  }

  public onDeleteFile(document: any): void {
    this.modalService.dismissAll();
    Swal.fire({
      title: "Eliminar documento",
      html: `Al eliminar el documento ${document.name} se eliminará por completo de la solicitud y la otra parte ya no lo podrá ver ni descargar. ¿Estás seguro de eliminar el documento?`,
      icon: 'warning',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonColor: '#EB5857',
      confirmButtonText: `Eliminar`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      customClass: {
        cancelButton: 'btn btn-link',
      },
    }).then((result) => {
      if(result.isConfirmed) {
        this.documentsService.deleteDocument(document.id)
          .pipe(
            catchError((error: Error) => {
              console.error(error);
              return of(undefined);
            })
          )
        .subscribe((response) => {
          if(response) {
            const modalRef = this.modalService.open(RequestDocumentsModalComponent, {
              size: 'xl',
              centered: true,
              backdrop: 'static'
            })
            modalRef.componentInstance.request = this.request;
          }
        })
      } else {
        Swal.close();
        const modalRef = this.modalService.open(RequestDocumentsModalComponent, {
          size: 'xl',
          centered: true,
          backdrop: 'static'
        })
        modalRef.componentInstance.request = this.request;
      }
    })
  }

  public onDownloadFile(document: any): void {
    this.documentsService.downloadDocument(document);
  }

  private fileValidations(file: File): Promise<IFile> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onload = () => {
        const fileSize = file.size / 1024 / 1024;
        const fileExtension = file.name.split('.').pop() || '';
        const humanizedFileSize = this.getFileSize(file);
        if(fileSize > this.MAX_FILE_SIZE) {
          resolve({canHandle: false, file: file, fileString: reader.result as string, fileSize: humanizedFileSize, notification: 'El archivo es más grande de lo permitido. Por favor sube un archivo menor a 5MB.', isUploading: true, uploadedAt: Date.now().toLocaleString()})
        } else if(!this.ALLOWED_FILE_TYPES.includes(fileExtension)) { 
          resolve({canHandle: false, file: file, fileString: reader.result as string, fileSize: humanizedFileSize, notification: 'El archivo no es un tipo de archivo permitido. Por favor sube un archivo con el tipo de extensión permitida.', isUploading: true, uploadedAt: Date.now().toLocaleString()})
        } else {
          resolve({canHandle: true, file: file, fileString: reader.result as string, fileSize: humanizedFileSize, isUploading: true, uploadedAt: new Date().toISOString()})
        }
      }
    })
  }

  private getFileSize(file: File): string {
    const size = file.size;
    const kb = 1000;
    const fileSizes = ['B', 'KB', 'MB'];
    const i = Math.floor(Math.log(size) / Math.log(kb));
    return `(${parseFloat((size / Math.pow(kb, i)).toFixed(2))} ${fileSizes[i]})`;
  }

  private getDocuments(): void {
    this.documentsService.getDocumentsByRequest(this.request).pipe(
      catchError((error) => {
        console.error(error)
        return of(undefined)
      })
    ).subscribe((files) => {
      if(files) {
        this.ownedDocuments = files.filter((document: any) => !document.isShared);
        this.sharedDocuments = files.filter((document: any) => document.isShared);
      }
    })
  }
}
