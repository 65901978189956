<div class="modal-header no-border custom-padding-project">
  <h2 class="mb-0">Punto de recolección</h2>
</div>
<div class="modal-body custom-padding-project">
  <form [formGroup]="pickupLocationForm">
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <dollink-autocomplete-address
          [fieldName]="'place'"
          [formLabel]="'Lugar de recolección'"
          (onAddressChanged)="getAddress($event)"
        ></dollink-autocomplete-address>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">Dirección</label>
        <div class="input-group has-validation">
          <input
            type="text"
            class="form-control"
            id="address"
            formControlName="address"
            [class.is-invalid]="submitted && pickupLocationForm.controls['address'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['address'].hasError('required')">La dirección es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <label for="inputStreetNumber" class="form-label">Número exterior</label>
        <div class="input-group has-validation">
          <input
            id="inputStreetNumber"
            type="text"
            class="form-control"
            formControlName="streetNumber"
            [class.is-invalid]="submitted && pickupLocationForm.controls['streetNumber'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['streetNumber'].hasError('required')">El número exterior es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2">
        <label for="" class="form-label">Número interior</label>
        <input
          type="text"
          class="form-control"
          id="zipCode"
          formControlName="suiteNumber"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-4 mb-2">
        <label for="inputZipCode" class="form-label">Código Postal</label>
        <div class="input-group has-validation">
          <input
            id="inputZipCode"
            type="text"
            class="form-control"
            id="zipCode"
            formControlName="zipcode"
            [class.is-invalid]="submitted && pickupLocationForm.controls['zipcode'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['zipcode'].hasError('required')">El código postal es requerido</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="inputCity" class="form-label">Ciudad</label>
        <div class="input-group has-validation">
          <input
            id="inputCity"
            type="text"
            class="form-control"
            formControlName="city"
            [class.is-invalid]="submitted && pickupLocationForm.controls['city'].errors"
          />
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['city'].hasError('required')">La ciudad es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-2">
        <label for="selectState" class="form-label">Estado</label>
        <div class="input-group has-validation">
          <select id="selectState" class="form-select" formControlName="state" [class.is-invalid]="submitted && pickupLocationForm.controls['state'].errors">
            <option *ngFor="let state of states" value="{{ state.name }}" >
              {{ state.name }}
            </option>
          </select>
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['state'].hasError('required')">El estado es requerido</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">Fecha de recolección</label>
        <div class="input-group has-validation">
          <input
            type="text"
            (click)="pickupDate.toggle()"
            [minDate]="{
              year: dueDateLimitYearOrigin,
              month: dueDateLimitMonthOrigin,
              day: dueDateLimitDayOrigin
            }"
            name="date"
            ngbDatepicker
            #pickupDate="ngbDatepicker"
            class="form-control"
            formControlName="date"
            [class.is-invalid]="submitted && pickupLocationForm.controls['date'].errors"
          />
          <span class="input-group-text cursor-pointer" id="validationTooltipUsernamePrepend" (click)="pickupDate.toggle()"><i class="bi bi-calendar3"></i></span>
          <div 
            *ngIf="submitted"
            class="invalid-feedback">
            <p *ngIf="pickupLocationForm.controls['date'].hasError('required')">La fecha de recolección es requerida</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-2">
        <label for="" class="form-label">
          Hora estimada de recolección <small class="text-muted">Opcional</small>
        </label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            formControlName="time"
            [imask]="timeMask"
          />
          <span class="input-group-text">
            <i class="bi bi-clock"></i>
          </span>
        </div>
        <small class="text-muted">Hora de recolección en formato de 24 hrs</small>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer no-border custom-padding-project-action">
  <button type="button" class="btn btn-cancel btn-outline" (click)="onClose()">Cancelar</button>
  <button type="button" class="btn btn-primary" [disabled]="isSaving" (click)="onSave()">
    <span class="spinner-border spinner-border-sm me-2" aria-hidden="true" *ngIf="isSaving"></span>
    <span role="status">{{updateButtonText}}</span>
  </button>
</div>