import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then((module) => module.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./features/app-features-wrapper.module').then((module) => module.AppFeaturesWrapperModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
