import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, catchError, of } from 'rxjs';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/core/services/user/user.service';
import { CompanyService } from 'src/app/core/services/company/company.service';

@Component({
  selector: 'gfapp-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../auth.style.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm!: FormGroup;
  public showErrorMessage: boolean = false;
  public errorMessage: string = '';
  public isLoading: boolean = false;
  public subscription!: Subscription;
  public token: string = "";
  public year = new Date().getFullYear();
  public submitted = false;
  public refreshTokenInvalidError = false;
  
  private authSuscription!: Subscription;

  constructor(
    private router: Router,
    private storage: StorageService,
    private fb: FormBuilder,
    private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private companyService: CompanyService,
  ) { 
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
    const isSessionExpired = this.router.getCurrentNavigation()?.extras?.state?.['refreshTokenInvalid'];
    if(isSessionExpired) {
      this.refreshTokenInvalidError = true;
    }
  }

  ngOnInit(): void {
    this.subscription = this.activeRoute.params.subscribe(params => {
      this.token = params['token'];
      if(this.token) {
        Swal.fire({
          text: 'Espera un momento, estamos validando tu cuenta...',
          icon: 'info',
          allowEscapeKey: false,
          showConfirmButton: false,
        })
        this.verifyUser();
      }
    })
  }

  ngOnDestroy(): void {
    if(this.authSuscription) this.authSuscription.unsubscribe()
  }

  public onLogin(): void {
    this.refreshTokenInvalidError = false;
    this.submitted = true;
    if(this.loginForm.valid) {
      this.isLoading = true;
      const { email, password } = this.loginForm.value;
      this.authSuscription = this.authService.login(email, password)
        .pipe(
          catchError((error) => {
            this.showErrorMessage = true;
            this.errorMessage = error.error.message;
            this.isLoading = false;
            return of(undefined)
          })
        )
        .subscribe((response) => {
          if(response && response.body) {
            const token = response.body.accessToken;
            const refreshToken = response.body.refreshToken;
            this.storage.set('dollink_user_refresh_token', refreshToken);
            this.storage.set('dollink_user_role', response.body.roles[0]);
            this.storage.set('dollink_user_token', token);
            this.storage.set('dollink_user_company_id', response.body.companyId);
            const companyUserType = this.authService.getDecodedToken().companyType;
            const url: string = companyUserType.toLowerCase() === 'transportistas' ? '/trucking/home' : '/requestor/home';
            this.companyService.getById().subscribe((company) => {
              this.storage.set('dollink_company_information', company);
              this.router.navigate([url]);
            });
          }
        })
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  private verifyUser(): void {
    this.userService.verifyUser(this.token)
      .pipe(
        catchError((error) => {
          Swal.fire({
            title: error.title,
            text: error.description,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor:  "#509760",
          });
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if(response) {
          Swal.close();
          Swal.fire({
            title: 'Excelente 🥳',
            text: 'Tu cuenta ya esta verificada... A partir de ahora podrás accesar a todos los beneficios que Dollink tiene para ti 🚀',
            allowEscapeKey: false,
            timer: 3000,
            showConfirmButton: false,
            confirmButtonColor:  "#509760",
          })
          this.router.navigate(['../']);
        }
      })
  }
}
