<div class="col-12 mb-2">
	<label for="inputElement" class="form-label">{{formLabel}}</label>
	<input 
		type="text" 
		#inputElement 
		id="inputElement" 
		class="form-control" 
		[formControlName]="fieldName" 
		[placeholder]="''"
	/>
</div>
