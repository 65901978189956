import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dollink-autocomplete-address',
  templateUrl: './autocomplete-address.component.html',
  styleUrls: ['./autocomplete-address.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})

export class AutocompleteAddressComponent implements OnInit, AfterViewInit {
  
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef = {} as ElementRef;

  @Input() fieldName: string = '';
  @Input() formLabel: string = '';
  @Output() onAddressChanged = new EventEmitter<any>();
  
  private googleMapApiKey = environment.googleMapApiKey;
  private loader: Loader;

  constructor() {
    this.loader = new Loader({
      apiKey: this.googleMapApiKey,
      version: 'weekly',
      libraries: ['places'],
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initAutoComplete();
  }

  private initAutoComplete(): void {

    this.loader.load().then(() => {
     
      const autocomplete = new google.maps.places.Autocomplete(
        this.inputElement.nativeElement as HTMLInputElement,
        {
          fields: ['place_id', 'geometry', 'name', 'address_components'],
        }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let postcode = '';
        const autocompleteAddress: any = {
          address: '',
          country: '',
          zipcode: '',
          state: '',
          city: '',
          streetNumber: '',
          neighborhood: '',
          latitude: 0,
          longitude: '',
          place: '',
        };
        autocompleteAddress.place = place.name;
        autocompleteAddress.latitude = place.geometry?.location?.lat();
        autocompleteAddress.longitude = place.geometry?.location?.lng();
        for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number': {
              autocompleteAddress.streetNumber = `${component.long_name}`;
              break;
            }
            case 'neighborhood': {
              autocompleteAddress.neighborhood = `${component.long_name}, `;
              break;
            }
            case 'sublocality_level_1': {
              autocompleteAddress.neighborhood += `${component.long_name}`;
              break;
            }
            case 'route': {
              autocompleteAddress.address = component.short_name;
              break;
            }
            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              autocompleteAddress.zipcode = postcode.replace(/[- ]/g, '');
              break;
            }
            case 'postal_code_suffix': {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }
            case 'locality':
              autocompleteAddress.city = component.long_name;
              break;
            case 'administrative_area_level_1': {
              autocompleteAddress.state = component.long_name;
              break;
            }
            case 'country':
              autocompleteAddress.country = component.long_name;
              break;
          }
        }
        this.onAddressChanged.emit(autocompleteAddress);
      });
    });
  }
}
