import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, catchError, of } from 'rxjs';
import { NotificationActionEnum } from 'src/app/core/enums/notifications-actions.enums';
import { IReceivedMessages } from 'src/app/core/interfaces/chat.interface';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ChatService } from 'src/app/core/services/chat/chat.service';
import { SocketService } from 'src/app/core/services/socket/socket.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'dollink-chat-notifications',
  templateUrl: './chat-notifications.component.html',
  styleUrls: ['./chat-notifications.component.scss']
})
export class ChatNotificationsComponent implements OnInit, OnDestroy {
 
  @ViewChild(NgbDropdown) private notificationsChatMenu: NgbDropdown | undefined;

  public chats: any;
  public newChats = 0;
  public receivedMessages: IReceivedMessages[] = [];
  public userType = "";

  private chatSubscription = new Subscription();
  private messageViewedSubscription = new Subscription();

  constructor(
    private chatService: ChatService,
    private socketService: SocketService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const user = this.authService.getDecodedToken();
    const companyUserType = user.companyType;
    this.userType = companyUserType.toLowerCase() === 'transportistas' ? 'Transportista' : 'Intermediario';
    this.getTotalNewChats();
    this.chatSubscription = this.socketService.currentMessage.subscribe((message) => {
      if(message) {
        this.getTotalNewChats();
      }
    })
    this.messageViewedSubscription = this.socketService.isMessageViewed.subscribe((isViewed)=> {
      if(isViewed) this.getTotalNewChats()
    })
  }

  ngOnDestroy(): void {
    if(this.chatSubscription) this.chatSubscription.unsubscribe();
    if(this.messageViewedSubscription) this.messageViewedSubscription.unsubscribe();
  }

  public onNotificationsDropdownChange(toggle: boolean): void {
    if(toggle) {
      this.getChatList();
     }
  }

  public onRedirectToChat(chat: IReceivedMessages): void {
    this.notificationsChatMenu?.close();
    this.getTotalNewChats();
    let url = "";
    const userType = this.userType;

    if(chat.action === NotificationActionEnum.negotiation) {
      url = userType === 'Transportista' ? `/trucking/services/negotiation/${chat.requestUUID}` : `/requestor/projects/negotiation/${chat.requestUUID}`;
    }

    if(chat.action === NotificationActionEnum.assignation) {
      url = userType === 'Transportista' ? `/trucking/services/assigned/${chat.requestUUID}` : `/requestor/projects/negotiation/${chat.requestUUID}`;
    }

    if(chat.action === NotificationActionEnum.assigned) {
      url = userType === 'Transportista' ? `/trucking/services/assigned/${chat.requestId}` : `/requestor/services/assigned/${chat.requestUUID}`;
    }

    if(chat.action === NotificationActionEnum.transiting) {
      url = userType === 'Transportista' ? `/trucking/services/transit/${chat.requestId}` : `/requestor/services/transit/${chat.requestUUID}`;
    }

    this.router.navigate([url]);

  }

  private getTotalNewChats(): void {
    this.chatService.getChatCounter()
      .pipe(
        catchError((error) => {
          Swal.fire({
            title: error.title,
            icon: 'warning',
            text: error.description,
            confirmButtonText: 'Cerrar',
            confirmButtonColor:  "#509760",
          });
          return of(undefined);
        })
      )
      .subscribe((chatResponse) => {
        this.newChats = chatResponse ? chatResponse : 0;
        if(chatResponse) {
          this.getChatList();
        }
      })
  }

  private getChatList(): void {
    this.chatService.getChatList()
      .pipe(
        catchError((error) => {
          Swal.fire({
            title: error.title,
            icon: 'warning',
            text: error.description,
            confirmButtonText: 'Cerrar',
            confirmButtonColor:  "#509760",
          });
          return of(undefined);
        })
      )
      .subscribe((receivedMessages) => {
        if(receivedMessages) {
          this.receivedMessages = receivedMessages;
        }
      })
  }
}
