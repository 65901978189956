import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ACCOUNT_TYPE } from 'src/app/core/enums/account.types.enum';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PHONE_MASK } from 'src/app/shared/masks/masks';
import { SignUpInfo } from 'src/app/core/interfaces/auth.interface';
import { Subscription, catchError, of } from 'rxjs';
import { ADMIN_USERS } from 'src/app/core/enums/admin.types.enums';
import {
  NgbModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { SelectAccountTypeModalComponent } from '../../components/select-account-type-modal/select-account-type-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'dollink-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../../auth.style.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  
  public phoneMask = PHONE_MASK;
  public showErrorMessage: boolean = false;
  public errorMessage: string = '';
  public isLoading: boolean = false;
  public isAccountSelected: boolean = false;
  public registerForm!: FormGroup;
  public submitted = false;
  public year = new Date().getFullYear();
  public accountType = "";
  public accountTypeDescription = "";
  public registerButtonText = "Registrarme";

  private authSubscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private modalConfig: NgbModalConfig,
    private modal: NgbModal
  ) {
    this.modalConfig.backdrop = 'static';
    this.modalConfig.keyboard = false;
  }

  ngOnInit(): void {
    this.createForm();
    this.modal.open(SelectAccountTypeModalComponent, {
      centered: true,
      keyboard: false,
      size: 'xl',
    }).result.then((accountType) => {
      this.isAccountSelected = true;
      this.registerForm.get('accountType')?.setValue(accountType);
      this.accountType = accountType;
      this.accountTypeDescription = accountType === ACCOUNT_TYPE.GROUND_FREIGHT ? 'Transportista' : 'Intermediario';
    })
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  public onRegister(): void {
    this.submitted = true;
    if(this.registerForm.valid ){
      this.isLoading = true;
      this.registerButtonText = "Registrandome...";
      const role = this.accountType === ACCOUNT_TYPE.GROUND_FREIGHT ? ADMIN_USERS.TRUCKING : ADMIN_USERS.REQUESTOR;
      const companyId = this.accountType === ACCOUNT_TYPE.REQUESTOR ? 1 : 2;
      const data: SignUpInfo = {
        email: this.registerForm.value.email.trim(),
        password: this.registerForm.value.password.trim(),
        firstName: this.registerForm.value.name,
        lastName: this.registerForm.value.lastName,
        role: [role],
        company: {
          name: this.registerForm.value.comercial_name,
          companyTypeId: companyId,
        },
      };

      this.authSubscription = this.authService.register(data)
        .pipe(
          catchError((error) => {
            Swal.fire({
              title: error.title,
              icon: 'warning',
              text: error.description,
              confirmButtonText: 'Cerrar',
              confirmButtonColor:  "#509760",
            })
            return of(undefined);
          })
        )
        .subscribe((registerResponse) => {
          if(registerResponse) {
            this.submitted = false;
            this.isLoading = false;
            this.registerButtonText = "Registrarme";
            Swal.fire({
              title: 'Bienvenido a Dollink 🥳',
              icon: 'success',
              text: 'Te mandamos un enlace a tu correo para verificar tu cuenta, acceder y disfrutar de todos los beneficios que Dollink tiene para tu logística terrestre 🚛',
              confirmButtonText: 'Iniciar Sesión',
              confirmButtonColor: '#509760',
            }).then((result) => {
              if (result) {
                this.router.navigate(['/auth/sign-in']);
              }
            });
          }
        });
    } else {
      this.submitted = false;
      this.isLoading = false;
      this.registerButtonText = "Registrarme";
      this.registerForm.markAllAsTouched();
    }
  }

  createForm(): void {
    this.registerForm = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      accountType: ['', Validators.required],
      comercial_name: ['', Validators.required]
    })
  }
}
