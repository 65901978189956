<div class="notifications-center" ngbAutofocus>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Centro de notificaciones</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCloseModal()"></button>
  </div>
  <div class="modal-body">
    <table class="table table-hover notifications-center-table">
      <thead>
        <tr>
          <th scope="col">Creada</th>
          <th scope="col">Mensaje</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let notification of notifications" (click)="redirectTo(notification)">
          <td scope="row">{{ notification.createdDate | humanizeDate }}</td>
          <td>{{ notification.message }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>