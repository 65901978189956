<nav class="d-flex flex-column flex-shrink-0 navigation">
  <div class="navigation-logo d-flex align-items-center justify-content-start" [ngClass]="userType === 'trucking' ? 'trucking-profile' : 'requestor-profile'">
    <figure>
      <img *ngIf="userType === 'trucking'; else requestorLogoTemplate" src="assets/images/logos/svg-logo-white-dollink+Transportistas.svg" alt="" width="145">
      <ng-template #requestorLogoTemplate>
        <img src="assets/images/logos/svg-logo-white-dollink+Logistic.svg" alt="" width="145">
      </ng-template>
    </figure>
  </div>
  <div class="navigation-list p-3">
    <ul class="nav nav-pills flex-column mb-auto navigation-list">
      @for (item of nav?.menu; track $index) {
        <li>
          <a class="item-link" id="{{item.id}}" *ngIf="!item.isGroup" [routerLink]="[item.path]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
            <span class="material-symbols-outlined me-2" style="vertical-align: middle;">{{item.icon}}</span>
            {{item.label}}
          </a>
          <div *ngIf="item.isGroup" class="item-link-is-group">
            <div class="d-flex align-items-center justify-content-between">
              <p>
                {{item.label}}
              </p>
            </div>
            <ul>
              @for (subItem of item.items; track $index) {
                <li>
                  <ng-container *ngIf="subItem.id === 'new-project' || subItem.id === 'new-trucking-requests'; else noCtaActions">
                    <a class="item-link {{subItem.class}}" *ngIf="subItem.id === 'new-trucking-requests'; else newRequestorProjectItem" (click)="onCreateRequest($event, 'SELF_MANAGED')">
                      <span class="material-symbols-outlined me-2" style="vertical-align: middle;">{{subItem.icon}}</span>
                      {{subItem.label}}
                    </a>

                    <ng-template #newRequestorProjectItem>
                      <a class="item-link {{subItem.class}}" (click)="onCreateRequest($event, 'PROJECT')">
                        <span class="material-symbols-outlined me-2" style="vertical-align: middle;">{{subItem.icon}}</span>
                        {{subItem.label}}
                      </a>
                    </ng-template>
                  </ng-container>

                  <ng-template #noCtaActions>
                    <a class="item-link group {{subItem.class}}" [routerLink]="[subItem.path]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
                      <span class="material-symbols-outlined me-2" style="vertical-align: middle;">{{subItem.icon}}</span>
                      {{subItem.label}}
                    </a> 
                  </ng-template>
                </li>
              }
            </ul>
          </div>
        </li>
      }
    </ul>
  </div>
  <div class="navigation-submenu" *ngIf="nav?.subMenu">
    <ul class="nav nav-pills flex-column mb-auto">
      @for (submenu of nav?.subMenu; track $index) {
        <li>
          <a class="item-link" [routerLink]="[submenu.path]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
            <span class="material-symbols-outlined me-2" style="vertical-align: middle;">{{submenu.icon}}</span>
            {{submenu.label}}
          </a>
        </li>
      }
    </ul>
    <button class="submenu-signout" (click)="onSignOut()">
      Cerrar sesión
    </button>
  </div>
</nav>