import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IOnboardingConfiguration } from 'src/app/core/interfaces/onboarding.interface';

@Component({
  selector: 'dollink-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  @Input() public modalData: IOnboardingConfiguration = {title: '', subtitle: '', image: '', steps: []};

  constructor(
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {}

  public onBoardingRedirect(route: string): void {
    this.modalService.dismissAll();
    this.router.navigate([route]);
  }

  public onCloseOnboarding(): void {
    this.modalService.dismissAll();
  }

}
