<div class="modal-header" ngbAutofocus>
  <h4 class="modal-title" id="modal-basic-title">
    Registrarme como
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onCloseModal()"
  ></button>
</div>
<div class="modal-body">
  <div class="truck-type">
    <div class="truck-type-block" id="truck" (click)="onSelectAccountType('ground_freight')">
      <figure>
        <img src="assets/images/icons/ico-svg-trailer@1x.svg" alt="" />
      </figure>
      <p class="truck-type-block-title">
        Transportista
      </p>
      <p class="truck-type-block-subtitle">
        Empresas con unidades de transporte terrestre disponibles para puertos, aeropuertos y territorio nacional.
      </p>
    </div>
    <div class="truck-type-block" id="box" (click)="onSelectAccountType('requestor')">
      <figure>
        <img src="assets/svg/ico-svg-intermediario@1x.svg" alt="Requestor Icon" />
      </figure>
      <p class="truck-type-block-title">
        Intermediario</p>
      <p class="truck-type-block-subtitle">
        Estos pueden ser Freight Forwarders, Agentes Aduanales, Brokers o Empresas de Manufactura.
      </p>
    </div>
  </div>
</div>