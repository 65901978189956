import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable} from "rxjs";
import { AuthService } from "../services/auth/auth.service";
@Injectable()
export class RequestTokenInterceptor implements HttpInterceptor {
  
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    const authToken = this.authService.getToken();
    if(authToken){
      req = req.clone({
        setHeaders: {Authorization: `Bearer ${authToken}`}
      })
    }
    return next.handle(req);
  }
}
