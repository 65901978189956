import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { IDriver, IMedicalInfo, IIdentifications, IAddress, IAvailableDrivers } from '../../interfaces/driver.interface';
import { IPaginationResponse } from '../../interfaces/pagination.interface';



@Injectable({
  providedIn: 'root'
})
export class DriversService {

  private readonly api: string = env.api;
  private endpoints = {
    findAvailableDrivers: '/driver/request',
  }

  constructor(
    private http: HttpClient
  ) { }

  getFreeDriversForRequest(id: string): Observable<IAvailableDrivers[]> {
    return this.http.get<IAvailableDrivers[]>(`${this.api}${this.endpoints.findAvailableDrivers}/${id}`);
  }

  getMedicalInfo(id: string): Observable<IMedicalInfo> {
    return this.http.get<IMedicalInfo>(`${this.api}/user/${id}/medical`);
  }

  updateMedicalInfo(id: string, medicalInfo: IMedicalInfo): Observable<HttpResponse<object>> {
    return this.http.put<HttpResponse<object>>(`${this.api}/user/${id}/medical`, medicalInfo, {observe: 'response'});
  }

  getIdentifications(id: string): Observable<IIdentifications> {
    return this.http.get<IIdentifications>(`${this.api}/user/${id}/identifications`);
  }

  updateIdentificationsInfo(id: string, identificationsInfo: FormData): Observable<HttpResponse<object>> {
    return this.http.put<HttpResponse<object>>(`${this.api}/user/${id}/identifications`, identificationsInfo, {observe: 'response'});
  }

  getAddressInfo(id: string): Observable<IAddress> {
    return this.http.get<IAddress>(`${this.api}/user/${id}/address`);
  }

  updateAddressInfo(id: string, addressInfo: IAddress): Observable<HttpResponse<object>> {
    return this.http.put<HttpResponse<object>>(`${this.api}/user/${id}/address`, addressInfo, {observe: 'response'});
  }
  
}
